type NumberBadgeProps = {
  count: number
}

const NumberBadge = ({ count }: NumberBadgeProps): JSX.Element => {
  return (
    <div className="flex h-[35px] w-[35px] items-center justify-center rounded-full bg-accent text-2xl font-bold text-white shadow-notification shadow-accent/30">
      <div className="mt-[-1px]">{count}</div>
    </div>
  )
}

export default NumberBadge
