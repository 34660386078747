import { UserTypes, type Person } from 'store/dashboard/types'
import i18next from 'i18next'

type getUserDataListInterface = {
  cb?: (returnData: Person[]) => void
  rinGroupObject: any
  userDataArray: any[]
}

type replaceArrayObjectInterface = {
  cb?: (returnData: Person[]) => void
  replaceObject: any
  userDataArray: any[]
}

export const getUserType = (type: string): UserTypes => {
  switch (type) {
    case UserTypes.Admin:
      return i18next.t('user.Form.Admin')
    case UserTypes.User:
      return i18next.t('User.Title')
    default:
      return UserTypes.Client
  }
}

const checkIfUserIsLoggedIn = (
  id: string,
  ringingGroups: any[]
): { status: boolean; groupId: string } => {
  const userGroupId = (ringingGroups[0]?.id as boolean)
    ? ringingGroups[0].id
    : 0

  const user = ringingGroups[0]?.endpoints?.find(
    (user: { id: string }) => user.id === id
  )

  const matchingGroup = ringingGroups.find(
    (group: { endpoints: any[] }) =>
      group.endpoints?.some((endpoint) => endpoint.id === id)
  )

  return {
    status: user !== undefined ? !(user?.disable_until as boolean) : false,
    groupId: (matchingGroup?.id as boolean) ? matchingGroup.id : userGroupId
  }
}

export const getUserDataList = ({
  rinGroupObject,
  userDataArray,
  cb = () => []
}: getUserDataListInterface): void => {
  const modifiedRows = Array?.isArray(userDataArray)
    ? userDataArray.map((row: any) => {
        const deviceCellphones =
          row?.phoneNumbers.length > 0
            ? row.phoneNumbers.filter(
                (obj: any) => obj.type === 'device_cellphone'
              )
            : null

        const phoneNumber = ((deviceCellphones?.length > 0) as boolean)
          ? deviceCellphones[0]?.number
          : (row?.phoneNumbers?.[0]?.number as boolean)
            ? row.phoneNumbers[0].number
            : ''

        const didNumbers =
          row?.dids.length > 0
            ? row.dids.filter(
                (obj: any) => obj.type === 'personal_did'
              )
            : null

        const did = ((didNumbers?.length > 0) as boolean)
          ? didNumbers[0]?.number
          : (row?.dids?.[0]?.number as boolean)
            ? row.dids[0].number
            : ''

        const email = (row.emailAddresses?.email as boolean)
          ? row?.emailAddresses?.email
          : ''
        const personalNumber = (row?.personalNumber as boolean)
          ? row?.personalNumber
          : ''
        const userType = getUserType(row.userType)

        const { status, groupId } = checkIfUserIsLoggedIn(
          row.recordID,
          rinGroupObject
        )

        const profileImg = ''

        return {
          id: row.recordID,
          profileImg,
          userType,
          familyName: row.familyName,
          givenName: row.givenName,
          phoneNumber: phoneNumber.replace(/^\+46/i, '0'),
          did: did.replace(/^\+46/i, '0'),
          email,
          personalNumber,
          groupId,
          status
        }
      })
    : []

  cb(modifiedRows)
}

export const replaceArrayObject = ({
  replaceObject,
  userDataArray,
  cb = () => []
}: replaceArrayObjectInterface): void => {
  const modifiedRows = Array.isArray(userDataArray)
    ? userDataArray.map((row: any) => {
        if (row.id === replaceObject.id) {
          return {
            id: row.id,
            profileImg: '',
            userType: replaceObject.userType,
            familyName: replaceObject.familyName,
            givenName: replaceObject.givenName,
            phoneNumber: replaceObject.phoneNumber,
            did: replaceObject.did,
            email: (replaceObject?.email as boolean)
              ? replaceObject.email
              : (replaceObject?.emailAddress as boolean)
                ? replaceObject.emailAddress
                : '',
            personalNumber: replaceObject.personalNumber,
            groupId: row.groupId,
            status: replaceObject.status
          }
        } else if (row.id === replaceObject.recordID) {
          const deviceCellphones =
            replaceObject?.phoneNumbers?.length > 0
              ? replaceObject.phoneNumbers.filter(
                  (obj: any) => obj.type === 'device_cellphone'
                )
              : null

          const phoneNumber = ((deviceCellphones?.length > 0) as boolean)
            ? deviceCellphones[0]?.number
            : (replaceObject?.phoneNumbers?.[0]?.number as boolean)
              ? replaceObject.phoneNumbers[0].number
              : ''
            
          const didNumbers =
          replaceObject?.dids?.length > 0
            ? replaceObject.dids.filter(
                (obj: any) => obj.type === 'personal_did'
              )
            : null
  
          const did = ((didNumbers?.length > 0) as boolean)
            ? didNumbers[0]?.number
            : (replaceObject?.dids?.[0]?.number as boolean)
              ? replaceObject.dids[0].number
              : ''

          const email = (replaceObject.emailAddresses?.email as boolean)
            ? replaceObject?.emailAddresses?.email
            : ''
          const personalNumber = (replaceObject?.personalNumber as boolean)
            ? replaceObject?.personalNumber
            : ''
          const userType = getUserType(replaceObject.userType)

          const profileImg = ''

          return {
            id: replaceObject.recordID,
            profileImg,
            userType,
            familyName: replaceObject.familyName,
            givenName: replaceObject.givenName,
            phoneNumber,
            did,
            email,
            personalNumber,
            groupId: row.groupId,
            status: row.status
          }
        } else {
          return row
        }
      })
    : []

  cb(modifiedRows)
}

export default { getUserDataList, replaceArrayObject, getUserType }
