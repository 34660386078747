export type EmailAddress = {
  label: string
  email: string
}

export type PhoneNumber = {
  number: string
  label: string
  type: string
}

export type did = {
  number: string
  label: string
  type: string
}

export type UserData = {
  recordID: string
  company: string
  emailAddresses: EmailAddress
  familyName: string
  givenName: string
  userType: string
  personalNumber: string
  callerId: null | string
  presenceStatus: null | string
  presenceText: null | string
  presenceFrom: null | string
  presenceTo: null | string
  presenceFulltext: null | string
  phoneNumbers: PhoneNumber[]
  didNumbers: did[]
}

export type OpeningHour = {
  name: string
  type: string
  wdays: string[]
  time_window_start: number
  time_window_stop: number
}

export type OpeningHoursData = {
  openinghours: OpeningHour[]
  holidays: string[]
}

export type Stats = {
  missed: number
  answered: number
  outbound: number
}

export type Endpoint = {
  delay: number
  endpoint_type: string
  id: string
  timeout: number
  disable_until: string
}

export type RingingGroup = {
  name: string
  id: string
  checked: boolean
  endpoints: Endpoint[]
  strategy: string
  repeats: number
}

export type HourGroup = {
  name: string
  id: string
  checked: boolean
  endpoints: Endpoint[]
  strategy: string
}

export type FormTypes = {
  givenName: string
  familyName: string
  phoneNumber: string
  did: string
  emailAddress: string
  personalNumber: string
  userType: string
  status: boolean
  id: string
}

export type ProfileFormTypes = {
  loginUserName: string
  organizationNumber: string
  address: string
  city: string
  phoneNumber: string
  did: string
  emailAddress: string
  userType: IUserProfileObject | null
}

export type ISuperAdminDetails = {
  familyName: string
  givenName: string
  userType: string
  personalNumber?: string
}

export type IContactFormType = {
  comment?: string
  type?: string
  subject?: string
  contactData?: ProfileFormTypes
  superAdminData?: ISuperAdminDetails | null
}

export type IProfileData = IContactFormType | ISuperAdminDetails | null | any

export type HandleValueChangeTypes = {
  e: React.ChangeEvent<HTMLInputElement>
}

export type ReactOnSelectValueTypes = {
  value: any
  name: string
}

export type FormHeaderTypes = {
  title: string
}

export enum UserTypes {
  Admin = 'admin',
  Client = 'client',
  User = 'user'
}

//  change any to proper type when its known
export type IUserProfileObject = {
  recordID: string;
  company: string;
  emailAddresses: {
    label: string;
    email: string;
  };
  familyName: string;
  givenName: string;
  userType: string;
  callerId: string;
  number?: string;
  userGroup: null | any;
  extension: string;
  personalNumber: string;
  presenceStatus: null | any;
  presenceText: null | any;
  presenceFrom: null | any;
  presenceTo: null | any;
  presenceFulltext: string;
  phoneNumbers: Array<{
    number: string;
    label: string;
    type: string;
    device_id?: string;
  }>;
  dids: any[];
  fullName: string;
}

export type Person = {
  id: number
  userType: UserTypes
  familyName: string
  givenName: string
  phoneNumber: string
  did: string
  email: string
  personalNumber: string
  groupId: string
  status: boolean
  profileImg?: string
}

export type DashboardStateTypes = {
  step: number
  userData: UserData | [] | undefined | any
  userProfileObject: IUserProfileObject | null
  isUpdateLogUserData: boolean
}

export type UpdateTestValuePropTypes = IUserProfileObject & {
  name: string
  value: string | number | boolean | undefined | [] | IUserProfileObject
}

export type ActionType = {
  type: string
  payload?: UpdateTestValuePropTypes
}

export type federatedLoginTypes = {
  sessionId?: string
  QRCode?: string
  autoStartToken?: string
}
