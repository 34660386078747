import React from 'react'

type TypographyProps = {
  children: React.ReactNode
  opacity?: number
  weight?: string
  color?: string
  size?: string
  customClasses?: string
}

export enum TypographySizes {
  'xxxs' = 'xxxs',
  'xxs' = 'xxs',
  'xs' = 'xs',
  'sm' = 'sm',
  'sm-1' = 'sm-1',
  'base' = 'base',
  'md' = 'md',
  'md-1' = 'md-1',
  'lg' = 'lg',
  'xl' = 'xl',
  '2xl' = '2xl',
  '2xl-2' = '2xl-2',
  '2xl-3' = '2xl-3',
  '2xl-4' = '2xl-4',
  '3xl' = '3xl',
  '3xl-1' = '3xl-1',
  '3xl-2' = '3xl-2',
  '4xl' = '4xl',
  '4xl-1' = '4xl-1'
}

export enum TypographyWeights {
  'thin' = 'thin',
  'extralight' = 'extralight',
  'light' = 'light',
  'normal' = 'normal',
  'medium' = 'medium',
  'semibold' = 'semibold',
  'bold' = 'bold',
  'bolder' = 'bolder',
  'extrabold' = 'extrabold',
  'black' = 'black'
}

export enum TypographyColors {
  'primary' = 'primary',
  'white' = 'white'
}

const Typography = ({
  children,
  size = TypographySizes.base,
  weight = TypographyWeights.normal,
  opacity = 1,
  color = TypographyColors.primary,
  customClasses
}: TypographyProps): JSX.Element => {
  const classModified = `text-${size} font-${weight} text-${color}/${
    opacity * 100
  } ${customClasses}`
  return <p className={classModified}>{children}</p>
}

export default Typography
