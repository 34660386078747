import {
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar'
import TellitDot from './TellitDot'

type ITypes = {
  count: number
  title: string
  active: boolean
}

type TellitProgressProps = {
  value: number
  progress?: string
  description: string
  types?: ITypes[]
}

const TellitProgress = ({
  value,
  progress,
  description,
  types
}: TellitProgressProps): JSX.Element => {
  return (
    <>
      <div className="h-[146px] w-[146px]">
        <CircularProgressbarWithChildren
          value={value}
          styles={buildStyles({
            trailColor: '#FF9D9D',
            pathColor: '#5BED98'
          })}
        >
          {progress !== '' && (
            <div className="mt-[-7px] text-3xl font-bold text-white">
              {progress}
            </div>
          )}
          {description !== '' && (
            <div className="mt-[-3px] text-xxs font-bold uppercase tracking-[0.52px] text-white/70">
              {description}
            </div>
          )}
        </CircularProgressbarWithChildren>
      </div>
      <div className="user-type-count mx-[31px] mt-[-10px] flex h-[30px] w-fill items-start justify-between">
        {types?.map((type: ITypes, idx: number) => (
          <div key={`userType_${idx}`} className="flex w-[93px] flex-col">
            <div className="text-center text-4xl font-bold text-white">
              {String(type?.count ?? '')}
            </div>
            <div className="mt-[-6px] flex items-center gap-[5px]">
              <TellitDot active={type?.active} />
              <div className="count-title text-xs font-bold uppercase tracking-[0.52px] text-white/70">
                {type?.title}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default TellitProgress
