const baseUrl =
  'https://b7dso81b2j.execute-api.eu-north-1.amazonaws.com/prod/unity_mobile?'
const tellitTechUrl =
  'https://b7dso81b2j.execute-api.eu-north-1.amazonaws.com/prod/'

type UrlDocType = Record<string, { url: string; type: string }>

const urlDoc: UrlDocType = {
  easyLogin: { url: `${baseUrl}command=easylogin_request`, type: 'get' },
  authToken: { url: `${baseUrl}command=easylogin`, type: 'get' },
  getAuthToken: { url: `${baseUrl}command=get_user_token`, type: 'get' },
  getContacts: { url: `${baseUrl}command=get_contacts`, type: 'get' },
  getOpeningHours: { url: `${baseUrl}command=get_openinghours`, type: 'get' },
  updateOpeningHours: {
    url: `${baseUrl}command=update_openinghours`,
    type: 'post'
  },
  getRingingGroups: { url: `${baseUrl}command=get_ringgroups`, type: 'get' },
  getStats: { url: `${baseUrl}command=get_cdr_stats`, type: 'get' },
  callHistory: { url: `${baseUrl}command=get_cdrs`, type: 'get' },
  updateRingingGroup: {
    url: `${baseUrl}command=toggle_ringgroup`,
    type: 'post'
  },

  // update /delete both use - updateContact
  updateContact: { url: `${baseUrl}command=update_contact`, type: 'post' },
  addContact: { url: `${baseUrl}command=create_contact`, type: 'post' },
  deleteContact: { url: `${baseUrl}command=delete_contact`, type: 'post' },
  // federated login
  federatedLogin: {
    url: `${tellitTechUrl}/grandid_init_login`,
    type: 'get'
  },
  getSession: {
    url: `${tellitTechUrl}/grandid_callback`,
    type: 'post'
  },
  getCallRecording: {
    url: `${baseUrl}command=get_call_recording`,
    type: 'get'
  },
  getSingleUser: { url: `${baseUrl}command=get_user`, type: 'get' },
  getAccount: { url: `${baseUrl}command=get_account`, type: 'get' },
  updateAccount: { url: `${baseUrl}command=update_account`, type: 'post' },
  contactForm: { url: `${baseUrl}command=contact_form`, type: 'post' }
}

export default urlDoc
