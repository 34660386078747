import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { type federatedLoginTypes } from 'store/dashboard/types'

import StepOne from './StepOne'
import StepQR from './StepQR'
import StepLoading from './StepLoading'
import handleApiCall from 'api/handleApiCall'

import bankId from '../../assets/images/bank-id.png'
// import { handleToastMessage } from '../../utilities'
import { TellitCard } from 'components/common'

function Login(): JSX.Element {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [openAccountPicker, setOpenAccountPicker] = useState<boolean>(false)
  const [accounts, setAccounts] = useState<any[]>([])

  const [step, setStep] = useState<number>(0)
  // const [isTokenExpired] = useState<boolean>(
  //   !!(
  //     localStorage.getItem('is_token_expired') !== undefined &&
  //     localStorage.getItem('is_token_expired') === 'true'
  //   )
  // )
  const [startPolling, setStartPolling] = useState<boolean>(false)
  const [federated, setFederated] = useState<federatedLoginTypes>({})
  const [loading, setLoading] = useState<boolean>(false)
  // temp state for testing
  const [emailCode, setEmailCode] = useState<string>('')

  const isLocalhost = window.location.hostname === 'localhost'

  const handleClick = (passedStep: number): void => {
    setStep(passedStep)
  }

  const handleGetAuth = (): void => {
    const userId = localStorage.getItem('user_id')
    const accountId = localStorage.getItem('account_id')

    void handleApiCall({
      urlType: 'authToken',
      urlParams: `&login_code=${emailCode}&account_id=${accountId}&user_id=${userId}`,
      // auth: true,
      cb: (res) => {
        if (res?.data?.status === 'success') {
          localStorage.setItem('auth_token', res?.data?.auth_token)
          setEmailCode('')
          navigate('/dashboard', { replace: true })
        }
        if (res?.data?.status === 'error') {
          console.error('error', res)
        }
      }
    })
  }

  const controller = new AbortController()

  // temp function here later need to make as common func
  const handleGetSession = (): void => {
    const form = new FormData()
    void handleApiCall({
      urlType: 'getSession',
      urlParams: `?grandidsession=${federated?.sessionId}`,
      data: form,
      signal: controller.signal,
      cb: (res, state) => {
        if (state) {
          if (res?.data?.grandidObject as boolean) {
            setFederated({
              ...federated,
              QRCode: res?.data?.grandidObject?.QRCode
            })
          }
          // success
          if (res?.data?.count > 1) {
            setStartPolling(false)
            controller.abort()
            setAccounts(res?.data?.accounts)
            setOpenAccountPicker(true)
            // navigate('/select', { replace: true })
          }

          // success
          if (res?.data?.status === 'success' && res?.data?.count === 1) {
            const account = res?.data?.accounts[0]
            localStorage.setItem('auth_token', account.user_token)
            localStorage.setItem('user_id', account.user_id)
            localStorage.setItem('account_id', account.account_id)
            localStorage.setItem('credentials', account.credentials)
            localStorage.setItem('priv_level', account.priv_level)
            localStorage.setItem('account_name', account.account_name)
            setStartPolling(false)
            controller.abort()
            navigate('/dashboard', { replace: true })
          }
        } else {
          setStartPolling(false)
          controller.abort()
          console.log('error: ', res.message)
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
            setTimeout(() => {
              handleClick(0)
            }, 3200)
          }
        }
      }
    })
  }

  const handleEasyLogin = (): void => {
    void handleApiCall({
      urlType: 'easyLogin',
      // hardcode phone number for now
      // urlParams: `&msg_type=1&login_phone=+46726417257`,
      // urlParams: `&msg_type=1&login_phone=+46120554441`, // admin old shif
      // urlParams: `&msg_type=1&login_phone=+46120556661`, // super admin new shif
      urlParams: `&msg_type=1&login_phone=0769876543`, // test account login code is 123456
      // urlParams: `&msg_type=1&login_phone=+46720556662`, // user new shif
      // urlParams: `&msg_type=1&login_phone=+46120556789`, // admin new shif
      // urlParams: `&msg_type=1&login_phone=+46120554442`, // user
      // urlParams: `&msg_type=1&login_phone=+46104307744`, // amanda
      // urlParams: `&msg_type=1&login_phone=+46120557781`, //  amanda@parkwaylabs.com  User
      // urlParams: `&msg_type=1&login_phone=+46120554279`, // amanda.parkwaylabs@gmail.com Admin
      // urlParams: `&msg_type=1&login_phone=++46120554470`, // admin maneesha
      // urlParams: `&msg_type=1&login_phone=+46120554471`, // user maneesha
      // urlParams: `&msg_type=1&login_phone=+46726417289`, // admin maneesha baman
      // urlParams: `&msg_type=1&login_phone=+46120554628`, // user maneesha frontnoob
      // auth: true,
      cb: (res, state) => {
        if (state) {
          localStorage.setItem('user_id', res?.data?.user_id)
          localStorage.setItem('account_id', res?.data?.account_id)
          console.log(res)
        } else {
          console.error('error', res)
        }
      }
    })
  }

  const handleFederatedLogin = ({
    isSuccessMobileBankId
  }: {
    isSuccessMobileBankId?: (state: boolean, autoStartToken?: string) => void
  }): void => {
    const form = new FormData()

    form.append('gui', 'false')
    form.append('qr', 'true')
    form.append('mobileBankId', 'false')

    void handleApiCall({
      urlType: 'federatedLogin',
      data: form,
      setLoading,
      cb: (res, state) => {
        if (state) {
          setStartPolling(true)
          setFederated(res?.data)
          if (isSuccessMobileBankId !== undefined) {
            isSuccessMobileBankId(true, res?.data?.autoStartToken)
          }
        } else {
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          }
          if (isSuccessMobileBankId !== undefined) {
            isSuccessMobileBankId(false)
          }
        }
      }
    })
  }

  // temp state later we gonna use this as common func
  useEffect(() => {
    if ((startPolling && step === 0) || (startPolling && step === 2)) {
      setLoading(true)
      const pollInterval = 2000 // 3 seconds
      const getSessionInterval = setInterval(() => {
        handleGetSession()
      }, pollInterval)
      return () => {
        clearInterval(getSessionInterval)
      }
    } else {
      setLoading(false)
    }
  }, [startPolling, step])

  // redirect to dashboard if logged in check with valid token
  useEffect(() => {
    const authToken = localStorage.getItem('auth_token')

    if (authToken != null) {
      void handleApiCall({
        urlType: 'getContacts',
        auth: true,
        cb: (res, state) => {
          if (res !== undefined && state) {
            navigate('/dashboard', { replace: true })
          }
        }
      })
    }
  }, [])

  // const clearLocalStorage = (): void => {
  //   localStorage.removeItem('account_id')
  //   localStorage.removeItem('user_id')
  //   localStorage.removeItem('auth_token')
  //   localStorage.removeItem('user_data')
  //   localStorage.removeItem('logged_in_user')
  //   localStorage.removeItem('pagination')
  //   localStorage.removeItem('next_start_key')
  //   localStorage.removeItem('credentials')
  //   localStorage.removeItem('account_name')
  // }

  // useEffect(() => {
  //   if (isTokenExpired && localStorage.getItem('user_id') !== null) {
  //     clearLocalStorage()
  //     handleToastMessage(t('Msg.AuthTokenExpired'))

  //     localStorage.removeItem('is_token_expired')
  //   }
  // }, [isTokenExpired])

  return (
    <div className="login-wrapper flex h-screen flex-col-reverse flex-wrap md:flex-row">
      {!openAccountPicker ? (
        <>
          <div className="login-logo relative hidden h-[45vh] w-full place-items-center bg-loginCream md:grid md:h-screen md:w-1/2 2xl:w-[calc(100%-56.25rem)] 2xl:max-w-[987px]">
            <img
              src={require('../../assets/images/logo.png')}
              width={238}
              className="max-w-[150px] invert md:w-[180px] lg:w-[210px] xl:min-w-[240px] 2xl:mt-[1rem]"
            />
            <a
              href="https://tellit.se"
              target="_blank"
              rel="noreferrer"
              className="absolute bottom-[26px] left-[26px] text-sm text-primary transition-colors duration-300 hover:text-blue-500"
            >
              Till tellit.se
            </a>
          </div>
          <div className="login-form relative flex h-screen w-full items-center justify-center md:w-1/2 md:scale-90 xl:scale-100 2xl:w-[56.25rem]">
            {isLocalhost && (
              <div className="absolute left-3 top-3">
                <button
                  onClick={handleEasyLogin}
                  className="bg-red-400 px-4 py-1 text-white"
                >
                  easy login - 1
                </button>
                <br />
                <br />
                <button
                  onClick={handleGetAuth}
                  className="bg-blue-400 px-4 py-1 text-white"
                >
                  get auth - 2
                </button>
                <input
                  className="border-1 border"
                  type="text"
                  placeholder="email code"
                  value={emailCode}
                  onChange={(e) => {
                    setEmailCode(e.target.value)
                  }}
                />
              </div>
            )}
            <div className="form pb-6 md:pb-0">
              <TellitCard customClass="max-w-[500px] 2xl:w-[500px] p-[22px] !px-[20px] 2xl:min-h-[336.8px] bg-white rounded-large shadow-button shadow-primary/10">
                <div className="h-100 flex w-auto items-start">
                  {step === 0 && (
                    <StepOne
                      handleClick={handleClick}
                      handleFederatedLogin={handleFederatedLogin}
                      setStartPolling={setStartPolling}
                      loading={loading}
                    />
                  )}
                  {step === 1 && (
                    <StepQR
                      handleClick={handleClick}
                      loading={loading}
                      federated={federated}
                      setFederated={setFederated}
                      startPolling={startPolling}
                      setStartPolling={setStartPolling}
                      handleFederatedLogin={handleFederatedLogin}
                    />
                  )}
                  {step === 2 && (
                    <StepLoading
                      handleClick={handleClick}
                      controller={controller}
                      setStartPolling={setStartPolling}
                    />
                  )}
                </div>
                <div className="absolute bottom-[26px] left-[26px] h-12 w-12">
                  <img src={bankId} alt="balkid-qr" />
                </div>
              </TellitCard>
              {step === 0 && (
                <a
                  className="mt-[14px] block cursor-pointer text-center text-lg text-primary underline hover:text-secondary"
                  href="https://orderfree.tellit.se"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Login.Label.SaknarDu')}
                </a>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="fixed inset-0 z-50 flex flex-col items-center justify-center p-4">
            <div className="mb-4 text-center text-lg text-primary">
              {t('Login.Label.SelectAccount')}
            </div>
            <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-lg outline outline-1 outline-gray-200">
              {accounts.map((item) => (
                <div
                  key={item.account_id}
                  onClick={() => {
                    localStorage.setItem('auth_token', item.user_token)
                    localStorage.setItem('user_id', item.user_id)
                    localStorage.setItem('account_id', item.account_id)
                    localStorage.setItem('credentials', item.credentials)
                    localStorage.setItem('priv_level', item.priv_level)
                    localStorage.setItem('account_name', item.account_name)

                    navigate('/dashboard', { replace: true })
                  }}
                  className="flex cursor-pointer items-center justify-center border-b border-gray-300 p-2 last:border-b-0"
                >
                  <strong>{item.account_name}</strong>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Login
