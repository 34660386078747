import TellitLoader from './TellitLoader'

type TellitButtonProps = {
  href?: string
  label?: string
  type?: 'button' | 'submit' | 'reset'
  customClass?: string
  labelClass?: string
  onClick?: () => void
  icon?: JSX.Element
  disabled?: boolean
  loading?: boolean
}

const TellitButton = ({
  href,
  label = '',
  type = 'button',
  customClass = '',
  labelClass = '',
  onClick,
  icon,
  disabled = false,
  loading = false
}: TellitButtonProps): JSX.Element => {
  return (
    <TellitLoader loading={loading}>
      <a
        href={disabled ? undefined : href}
        id="tellit-btn-default"
        className={`group  flex w-full min-w-[8rem]  items-center justify-center gap-4 rounded-2xl px-4 py-2 font-bold tracking-wider text-primary antialiased disabled:!pointer-events-none disabled:border-none disabled:bg-grey/70 disabled:text-black/50 ${
          icon != null ? 'justify-between' : 'justify-center'
        } ${customClass}`}
        onClick={disabled ? () => {} : onClick}
        type={type}
      >
        {icon != null && icon}
        <span className={`text-sm ${labelClass}`}>{label}</span>
      </a>
    </TellitLoader>
  )
}

export default TellitButton
