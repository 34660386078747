import { Navigate, Outlet } from 'react-router'

const HandleAuth = (): JSX.Element => {
  const auth = localStorage.getItem('auth_token') !== null
  if (!auth) {
    return <Navigate to="/login" replace />
  }
  return <Outlet />
}

export default HandleAuth
