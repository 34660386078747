import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TellitTooltip } from 'components/common'
import { MdOutlineInfo } from 'react-icons/md'

type ISwitch = {
  icon?: JSX.Element
  label?: string
  toolTip?: string
}

type ITellitRadioSwitchProps = {
  onChange?: (id: number) => void
  defaultValue?: -1 | 0 | 1
  switches?: ISwitch[]
  disabledId?: number
  disabled?: boolean
}

const TellitRadioSwitch = ({
  onChange,
  defaultValue,
  disabledId,
  switches = [],
  disabled = false
}: ITellitRadioSwitchProps): JSX.Element => {
  const { t } = useTranslation()

  const [selectedId, setSelectedId] = useState<number>(-1)

  const handleSwitchChange = (id: number): void => {
    if (id === disabledId) {
      return
    }
    if (disabled) {
      return
    }
    setSelectedId(id)
    if (onChange !== undefined) {
      onChange(id)
    }
  }

  useEffect(() => {
    if (defaultValue !== undefined) {
      setSelectedId(defaultValue)
    }
  }, [defaultValue])

  return (
    <div className="tellit-radio-switch flex flex-row justify-between overflow-hidden md:flex-col">
      <div
        className={defaultValue === -1 ? 'md:mb-[4px] md:h-[24px]' : 'hidden'}
      />
      <div
        className={`mx-auto w-1/4 text-md font-bold text-primary md:mb-[4px] md:h-auto md:w-[57%] ${
          selectedId === 0
            ? 'translate-y-[18%] transition-all delay-75 duration-300 ease-out md:translate-x-[0%] md:translate-y-0'
            : selectedId === 1
              ? 'translate-y-3/4 transition-all delay-75 duration-300 ease-out md:translate-x-[92%] md:translate-y-0'
              : 'hidden bg-red-400 transition-none md:mb-[4px] md:h-[24px]'
        }`}
      >
        <p className="w-fit font-bold">{t('Generic.Radio.Vald')}</p>
      </div>

      <div className="gap  flex w-3/4 flex-col items-center justify-between gap-8 transition-all duration-300 ease-out md:w-full md:flex-row md:items-stretch">
        {switches?.map((item, id) => (
          <div
            key={`switch_${id}`}
            onClick={() => {
              if (defaultValue !== -1) {
                handleSwitchChange(id)
              }
            }}
            className={`tellit-switch flex w-full ${
              disabled ? 'cursor-default' : 'cursor-pointer'
            } flex-col items-center justify-start gap-[7px] rounded-large p-[26px] transition-all duration-300 ease-out md:min-h-[111px] md:w-1/2  ${
              selectedId === id ? 'bg-primary' : 'bg-inactive hover:bg-grey'
            }`}
          >
            <div
              className={`mb-[2px] text-2xl-4 ${
                selectedId === id
                  ? '[&_svg]:text-white '
                  : '[&_svg]:text-primary '
              }`}
            >
              {item.icon}
            </div>
            <div
              className={`tellit-switch-label flex select-none items-center gap-1 text-center text-md font-semibold ${
                selectedId === id ? 'text-white ' : 'text-primary '
              }`}
            >
              {item.label}{' '}
              {item.toolTip !== undefined && (
                <div className="group">
                  <TellitTooltip
                    id="switch-tooltip"
                    customClass={`max-w-[186px] group-hover:opacity-100 hover tellit-tool-tip shadow-notification ${
                      selectedId === id
                        ? '!shadow-secondary/20'
                        : 'shadow-primary/30'
                    }`}
                    place="top"
                    openOnClick={false}
                  >
                    <span className="text-center text-sm font-normal text-granite">
                      {item.toolTip}
                    </span>
                  </TellitTooltip>
                  <MdOutlineInfo
                    className="-mt-[1.5px] ml-[1.5px] text-lg text-accent"
                    data-tooltip-id="switch-tooltip"
                    id="switch-tooltip"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TellitRadioSwitch
