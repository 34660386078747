import { useTranslation } from 'react-i18next'

import { LuPenLine } from 'react-icons/lu'

import { TellitCard, IconButton, TellitAvatar } from 'components/common'
import { TellitToggle } from 'components/form'

import Typography, {
  TypographySizes,
  TypographyWeights,
  TypographyColors
} from 'components/common/Typography'

type UserDataGridProps = {
  rowData: any
  rowIndex: number
  handleToggleChange: (row: string, index: any) => void
  handleEditTableRow: (id: any) => void
}

function UserDataGrid({
  rowData,
  rowIndex,
  handleToggleChange,
  handleEditTableRow
}: UserDataGridProps): JSX.Element {
  const { t } = useTranslation()

  if (rowData == null) {
    return <span />
  }

  const { givenName, familyName, phoneNumber, did, userType, status, id } =
    rowData

  const canEdit =
    localStorage.getItem('priv_level') === 'admin' ||
    localStorage.getItem('user_id') === rowData.id

  return (
    <TellitCard customClass="shadow-none tellit-user-card 2xl:max-w-[778px]">
      <div className="tellit-user-list grid grid-cols-12 justify-items-center self-center align-middle xl:justify-items-start ">
        <div className="tellit-user-grid-col-1 col-span-full self-center md:col-span-5 md:row-span-2 xl:col-span-4">
          <div className="md:block xl:inline-flex">
            <div className="tellit-user-avatar self-center text-center xl:text-left">
              <TellitAvatar
                src=""
                className="mr-0 h-[50px] w-[50px]"
                name={givenName}
              />
            </div>

            <Typography
              weight={TypographyWeights.bold}
              size={TypographySizes.lg}
              color={TypographyColors.primary}
              customClasses="self-center text-center xl:text-left"
            >
              {`${givenName} ${familyName}`}
            </Typography>
          </div>
        </div>

        <div className="tellit-user-phone-number col-span-12 mt-4 self-center text-left   sm:col-span-6 md:col-span-4  md:mt-0 xl:col-span-2">
          <Typography
            weight={TypographyWeights.normal}
            size={TypographySizes.md}
            color={TypographyColors.primary}
            opacity={0.6}
            customClasses="number"
          >
            {phoneNumber} {did}
          </Typography>
        </div>

        <div className="tellit-user-type col-span-12 mt-4  self-center  sm:col-span-6   md:col-span-3 md:mt-0  xl:col-span-2">
          <Typography
            weight={TypographyWeights.normal}
            size={TypographySizes.md}
            color={TypographyColors.primary}
            opacity={0.6}
          >
            {userType}
          </Typography>
        </div>

        <div className="tellit-user-toggle col-span-12 mt-4  self-center  sm:col-span-6 md:col-span-4 md:mt-0 md:justify-items-end  xl:col-span-3    ">
          <div className="flex">
            <span>
              <Typography
                weight={TypographyWeights.normal}
                size={TypographySizes.md}
                color={TypographyColors.primary}
                opacity={0.6}
                customClasses={`mr-[10px] mt-[2px] ${
                  canEdit ? '' : 'pointer-events-none'
                }`}
              >
                {(status as boolean)
                  ? t('Generic.Button.Toggle')
                  : t('Generic.Button.UnToggle')}
              </Typography>
            </span>

            <div
              style={{
                cursor: canEdit ? 'pointer' : 'default'
              }}
            >
              <TellitToggle
                clickable={canEdit}
                checked={Boolean(status)}
                onChange={() => {
                  if (canEdit) handleToggleChange(rowData, rowIndex)
                }}
                name="userList"
              />
            </div>
          </div>
        </div>

        <div className="tellit-user-edit col-span-12 mt-4 self-center sm:col-span-6 md:col-span-3 md:mt-0 md:justify-items-end xl:col-span-1">
          {canEdit ? (
            <IconButton
              icon={<LuPenLine className="h-5 w-5" />}
              onClick={() => {
                handleEditTableRow(id)
              }}
            />
          ) : (
            <div className="icon-button-placeholder bg flex h-[52px] w-[52px] items-center justify-center rounded-full p-0 text-primary"></div>
          )}
        </div>
      </div>
    </TellitCard>
  )
}

export default UserDataGrid
