import { useState } from 'react'
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { IconCustom, TellitTooltip } from 'components/common'
import { TellitSelect } from 'components/form'

type ITableDate = {
  status: string
  callDate: string
  number: string
  callRef: string
  // later we have to manipulate this
  audio?: any
}

export type IPaginationType = {
  size: number
  type: string
}

type ITableProps = {
  data?: ITableDate[]
  columns: Array<ColumnDef<ITableDate, any>>
  className?: string
  hasPagination?: boolean
  onPaginationChange?: (pagination: IPaginationType) => void
}

export const columnHelper = createColumnHelper<ITableDate>()

export const getCallIcon = (status: string): JSX.Element | null => {
  // inbound
  // answered - 'in' | not-answered - 'missed
  if (status === 'in' || status === 'missed') {
    return (
      <IconCustom
        type="fa"
        name="arrow-right"
        customClass={`!text-xs drop-shadow-actionText transition-all duration-300 ease-out ${
          status === 'in' ? '!text-active' : '!text-danger'
        }`}
      />
    )
  }
  // outbound
  if (status === 'out') {
    return (
      <IconCustom
        type="fa"
        name="arrow-left"
        customClass={`!text-xs !text-danger drop-shadow-actionText transition-all duration-300 ease-out`}
      />
    )
  }

  return null
}

const TellitTable = ({
  data = [],
  className,
  hasPagination = false,
  onPaginationChange,
  columns
}: ITableProps): JSX.Element => {
  const paginationStored = JSON.parse(
    localStorage.getItem('pagination') as string
  )
  const [pageCount, setPageCount] = useState(
    paginationStored !== undefined &&
      paginationStored !== null &&
      paginationStored.pageSize !== undefined &&
      paginationStored.pageSize !== -1
      ? paginationStored.pageSize
      : 20
  )
  const { t } = useTranslation()

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility: { audio: hasPagination }
    },
    getCoreRowModel: getCoreRowModel()
  })

  const paginationControllers = [
    {
      icon: (className: string) => (
        <IconCustom
          type="lu"
          size="small"
          name="chevron-first"
          customClass={className}
        />
      ),
      onclick: () => {
        // table.setPageIndex(0)
        if (onPaginationChange !== undefined) {
          onPaginationChange({ size: pageCount, type: 'start' })
        }
      },
      // disabled: !table.getCanPreviousPage()
      disabled: false
    },
    {
      icon: (className: string) => (
        <IconCustom
          type="lu"
          size="small"
          name="chevron-left"
          customClass={className}
        />
      ),
      onclick: () => {
        // table.previousPage()
        if (onPaginationChange !== undefined) {
          onPaginationChange({ size: pageCount, type: 'previous' })
        }
      }
      // disabled: !table.getCanPreviousPage()
    },
    {
      icon: (className: string) => (
        <IconCustom
          type="lu"
          size="small"
          name="chevron-right"
          customClass={className}
        />
      ),
      onclick: () => {
        // table.nextPage()
        if (onPaginationChange !== undefined) {
          onPaginationChange({ size: pageCount, type: 'next' })
        }
      }
      // disabled: !table.getCanNextPage()
    }
    // {
    //   icon: (className: string) => (
    //     <IconCustom
    //       type="lu"
    //       size="small"
    //       name="chevron-last"
    //       customClass={className}
    //     />
    //   ),
    //   onclick: () => {
    //     // table.setPageIndex(table.getPageCount() - 1)
    //     if (onPaginationChange !== undefined) {
    //       onPaginationChange({ size: pageCount, type: 'end' })
    //     }
    //   },
    //   disabled: !table.getCanNextPage()
    // }
  ]

  if (data.length === 0) {
    return (
      <div className="flex h-24 items-center justify-center">
        <div className="text-lg font-semibold text-primary/60">
          {t('Table.DataNotFound')}
        </div>
      </div>
    )
  }
  return (
    <>
      <table className={`tellit-table !-ml-8 !-mr-8 w-fill ${className}`}>
        <thead className="last:!-pr-8 first:!pl-8">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="border-b-[0.5px] border-primary/10 text-left"
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={`text-xxs font-normal text-primary/50 first:!pl-3 last:!pr-1 sm:text-sm 2xl:first:!pl-8 2xl:last:!pr-8 ${
                    header.id === 'number' && hasPagination
                      ? 'w-auto pb-[4px] 2xl:!w-[280px]'
                      : 'w-[300px]'
                  } ${
                    header.id === 'callDate' && hasPagination
                      ? 'w-auto 2xl:!w-[175px]'
                      : 'w-[225px]'
                  } ${
                    header.id === 'callRef' && hasPagination
                      ? 'w-auto 2xl:!w-[240px]'
                      : 'w-auto'
                  }`}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              className="h-[45px] max-h-[45px] border-b-[0.5px] last:border-none"
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={`text-xxxs ${
                    cell.id === index + '_number'
                      ? 'font-semibold'
                      : 'font-normal'
                  } text-primary first:!pl-3 last:!pr-1 sm:text-xs md:text-sm 2xl:text-md 2xl:first:!pl-8 2xl:last:!pr-8`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <TellitTooltip
        id="table-tooltip"
        customClass={`max-w-[118px] text-center group-hover:opacity-100 hover tellit-tool-tip shadow-notification ${'shadow-primary/30'}`}
        place="top"
        openOnClick={false}
        offset={9}
      >
        <span className="text-center text-sm font-normal text-granite">
          {t('Generic.Table.LaddaNer')}
        </span>
      </TellitTooltip>

      {hasPagination && (
        <div className="min-h-8 pagination mt-[18px] flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
          <div className="flex items-center justify-start gap-2">
            <TellitSelect
              options={[
                {
                  name: '20',
                  value: 20
                },
                {
                  name: '50',
                  value: 50
                },
                {
                  name: '100',
                  value: 100
                }
              ]}
              value={pageCount}
              onChange={({ value }: any) => {
                setPageCount(value)

                localStorage.setItem(
                  'pagination',
                  JSON.stringify({
                    pageSize: value === 'all' ? -1 : Number(value)
                  })
                )

                if (onPaginationChange !== undefined) {
                  onPaginationChange({
                    size: value === 'all' ? -1 : Number(value),
                    type: 'sizeChanged'
                  })
                }

                // table.setPageSize(value === 'all' ? -1 : Number(value))
              }}
              customClass="max-h-[32px] max-w-[80px] table-pagination-select"
            />
            <div className="pb-[0.6rem] text-sm text-primary/50">
              {t('Generic.Table.RaderPer')}
            </div>
          </div>
          <div className="flex items-center justify-start gap-1">
            {/* <div className="mr-2 text-sm text-primary/50">{`1-${
              table.getRowModel().rows.length
            } av ${table.getPageCount()}`}</div> */}
            {paginationControllers.map((controller, idx) => (
              <div
                onClick={controller.onclick}
                key={`pagination_${idx}`}
                className={`group grid aspect-square h-[32px] w-[32px] cursor-pointer place-items-center rounded-smaller bg-white shadow-input shadow-primary/20 transition-all duration-300 ease-out hover:bg-primary ${
                  controller.disabled !== undefined &&
                  controller.disabled &&
                  'pointer-events-none bg-grey opacity-50 hover:bg-grey'
                }`}
              >
                {controller.icon('group-hover:text-secondary text-primary')}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default TellitTable
