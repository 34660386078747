import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { TellitProgress } from 'components/common'
import { ColorCard } from 'components/cards'
import ActionBtn from './ActionBtn'

type IProgress = {
  ringingGroups: any
  loading: boolean
  progressDescription: string
}

const Progress = ({
  ringingGroups,
  loading,
  progressDescription
}: IProgress): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const userTotalCount =
    ringingGroups?.[0]?.endpoints !== undefined
      ? ringingGroups?.[0]?.endpoints?.length
      : 0

  const getLogoutUserCount =
    ringingGroups?.[0]?.endpoints !== undefined
      ? ringingGroups?.[0]?.endpoints?.filter((obj: any) =>
          Object.prototype.hasOwnProperty.call(obj, 'disable_until')
        ).length
      : 0

  const getLoggedInUserCount = userTotalCount - getLogoutUserCount

  const userTypes = [
    {
      count: getLoggedInUserCount,
      active: true,
      title:
        getLoggedInUserCount < 2
          ? t('Dashboard.UserTypes.LoggedIn')
          : t('Dashboard.UserTypes.LoggedInAll')
    },
    {
      count: getLogoutUserCount,
      active: false,
      title:
        getLogoutUserCount < 2
          ? t('Dashboard.UserTypes.LoggedOut')
          : t('Dashboard.UserTypes.LoggedOutAll')
    }
  ]

  return (
    <ColorCard loading={loading}>
      {/* progress */}
      <div className="mb-[16px] ml-[-3px] mt-[8px] text-2xl-2 font-semibold text-white">
        {t('Dashboard.Progress.Header')}
      </div>
      <TellitProgress
        value={(getLoggedInUserCount / userTotalCount) * 100}
        progress={`${getLoggedInUserCount}/${userTotalCount}`}
        // description={t('Dashboard.UserTypes.LoggedIn')}
        description={progressDescription}
        types={userTypes}
      />
      {/* action btn */}
      <ActionBtn
        action={() => {
          navigate('/users', { replace: true })
        }}
        className="mt-[66px] pl-[10px]"
        childClass="group-hover:!text-secondary"
      />
    </ColorCard>
  )
}

export default Progress
