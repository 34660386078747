import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { type Person } from 'store/dashboard/types'
// import { handleToastMessage } from 'utilities'

import handleApiCall from 'api/handleApiCall'

import { MdOutlinePersonAddAlt, MdContactSupport } from 'react-icons/md'

import { TellitLoader, TellitButton } from 'components/common'

import UserForm from './UserForm'
import UserDataGrid from './UserDataGrid'

import { replaceArrayObject, getUserDataList } from 'helpers'

import { setIsUpdateLogUserStore, LoginUserData } from 'store/dashboard/action'
import { getAccount } from 'modules/dashboard'

import {
  removeEditedArrayValue,
  type IErrorObj
} from '../../helpers/validationFunctions'

function UsersList(): JSX.Element {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [dataList, setUserList] = useState<Person[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [rowEditId, setRowEditId] = useState<number>(0)

  const [addNewUserRow, setAddNewUserRow] = useState<any[]>([])
  const [nextAvailableId, setNextAvailableId] = useState<number>(1)

  const [unityAdminArray, setUnityAdminArray] = useState<string[]>([])
  const [unityType, setUnityType] = useState<string>('')
  const [superAdminId, setSuperAdminId] = useState<number | null>(null)
  const [errorArray, setErrorArray] = useState<IErrorObj[]>([])

  const getUsersLength = dataList?.length
  const maxAvailableUserLength = 4
  const maxUserArrayLength = maxAvailableUserLength - getUsersLength

  const loginUserId = JSON.parse(
    localStorage.getItem('logged_in_user') as string
  )?.recordID

  const getContactsData = (loadStatus?: boolean): void => {
    if (loadStatus as boolean) {
      setLoading(false)
    } else {
      setLoading(true)
    }
    void handleApiCall({
      urlType: 'getContacts',
      urlParams: `&no_presences=1`,

      auth: true,
      cb: (res, state) => {
        if (state && res?.data !== undefined && res.data.length > 0) {
          const userDataArray = res.data
          localStorage.setItem('user_data', JSON.stringify(userDataArray))
          const userId = localStorage.getItem('user_id')

          getRingingGroup({
            callback: (returnData: any): void => {
              if (returnData?.data as boolean) {
                const rinGroupObject = returnData.data.data

                getUserDataList({
                  rinGroupObject,
                  userDataArray,
                  cb: (res: Person[]): void => {
                    setUserList(res)
                    setLoading(false)
                  }
                })
              } else {
                setUserList([])
                setLoading(false)
              }
            },
            userId: userId ?? undefined
          })

          if (userDataArray?.length as boolean) {
            const finedLoginUser = userDataArray.find(
              (item: { recordID: string | null }) => item.recordID === userId
            )

            localStorage.setItem(
              'logged_in_user',
              JSON.stringify(finedLoginUser)
            )

            dispatch(LoginUserData(finedLoginUser))
          }
        } else {
          setUserList([])

          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          }

          setLoading(false)
        }
      }
    })
  }

  const getRingingGroup = ({
    callback,
    userId
  }: {
    callback: (returnData: any) => void
    userId: string | undefined
  }): void => {
    void handleApiCall({
      urlType: 'getRingingGroups',
      urlParams: `&user_id=${userId}`,
      auth: true,
      cb: (res) => {
        callback(res)
      }
    })
  }

  const getSingleUserData = (id: string, rowObj: any): void => {
    void handleApiCall({
      urlType: 'getSingleUser',
      auth: true,
      urlParams: `&user_id=${id}`,
      cb: (res, state) => {
        if (state) {
          if (res?.data as boolean) {
            const userDataArray = dataList

            replaceArrayObject({
              replaceObject: res.data,
              userDataArray,
              cb: (res: Person[]): void => {
                setUserList(res)
                setLoading(false)
              }
            })

            if (loginUserId === res.data.recordID) {
              localStorage.setItem('logged_in_user', JSON.stringify(res.data))

              dispatch(setIsUpdateLogUserStore({ value: true }))
            }
          }
        } else {
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          } else {
            console.error('An error occurred during API calls:')
          }
        }
      }
    })
  }

  const updateRingGroupStatus = (
    isChecked: boolean,
    row?: any,
    index?: number
  ): void => {
    const urlParams = `&group_id=${row.groupId}&user_id=${row.id}&status=${
      isChecked ? 'login' : 'logout'
    }`

    void handleApiCall({
      urlType: 'updateRingingGroup',
      auth: true,
      urlParams,
      cb: (res, state) => {
        if (state) {
          if (res?.status === 200) {
            // toast(t('Msg.UpdateSuccess'), { type: 'success' })
            row.status = isChecked
            resetDataList('toggle', row)
          }
        } else {
          setUserList((prevState) => {
            const updatedList = [...prevState]

            if (
              index !== undefined &&
              index >= 0 &&
              index < updatedList.length
            ) {
              updatedList[index] = { ...row, status: !isChecked }
            }

            return updatedList
          })

          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          } else {
            toast(t('Msg.UpdateError'), { type: 'error' })
          }
        }
      }
    })
  }

  const handleToggleChange = (row: any, index?: number): void => {
    const isChecked = !(row?.status as boolean)

    setUserList((prevState) => {
      const updatedList = [...prevState]

      if (index !== undefined && index >= 0 && index < updatedList.length) {
        updatedList[index] = { ...row, status: isChecked }
      }

      return updatedList
    })

    updateRingGroupStatus(isChecked, row, index)
  }

  const handleEditTableRow = (rowId: number): void => {
    const getValidationArray = removeEditedArrayValue(errorArray, 'isEdit_')

    setErrorArray(getValidationArray)

    setRowEditId(rowId)
  }

  const resetDataList = (type: string, data: any): void => {
    if (type === 'user-add') {
      console.log('data', data)
      const updatedDataList = [...dataList, data] // Add `data` to the `dataList`
      setUserList(updatedDataList)
      setRowEditId(0)
      setAddNewUserRow([])
    } else if (type === 'toggle') {
      replaceArrayObject({
        replaceObject: data,
        userDataArray: dataList,
        cb: (res: Person[]): void => {
          setUserList(res)
          setLoading(false)
        }
      })
    } else if (type === 'user-edit') {
      getSingleUserData(data?.id, data)
    } else if (type === 'edit-user-delete') {
      const updatedDataList = dataList.filter((user) => user.id !== data.id)
      setUserList(updatedDataList) // Update the state with the filtered array
      // resetNewUserAddRowArray(data)

      setRowEditId(0)
    } else {
      getSingleUserData(data?.id, data)
      setRowEditId(0)
    }
  }

  const resetNewUserAddRowArray = (data: any): void => {
    if (data as boolean) {
      setAddNewUserRow((prevArray) => prevArray.filter((item) => item !== data))
    }
  }

  const userCallBackHandler = (type: string, data: any): void => {
    switch (type) {
      case 'edit-user-delete':
      case 'user-add':
        console.log(type, data)
        resetNewUserAddRowArray(data)
        resetDataList(type, data)

        break
      case 'user-edit':
        resetDataList(type, data)
        break

      case 'user-delete':
        resetNewUserAddRowArray(data)
        // getContactsData()
        break

      case 'user-toggle':
        handleToggleChange(data.rowData, data.rowIndex)
        break

      default:
        break
    }
  }

  const addButtonProps = {
    customClass: `!justify-start 2xl:max-w-[778px] bg-grey pl-6 enabled:hover:bg-primary enabled:hover:text-secondary !min-h-26 !h-26  border-primary border-opacity-9 border-dashed border-2 enabled:hover:border-primary !py-6 tellit-user-add`,
    labelClass: 'font-bold !text-lg',
    label:
      addNewUserRow.length === maxUserArrayLength && unityType === 'tellitfree'
        ? t('User.Form.UpgradeProfile')
        : unityType === 'convertedfree'
          ? t('User.Form.Contact')
          : t('UserList.Button.LaggTill'),
    icon: (
      <div
        id="tellit-customIcon"
        className={`group-hover:text-primary' flex h-12 w-12 items-center justify-center rounded-full bg-primary text-white group-hover:bg-secondary group-disabled:bg-black/50 group-disabled:text-inactive`}
      >
        {(addNewUserRow.length === maxUserArrayLength &&
          unityType === 'tellitfree') ||
        unityType === 'convertedfree' ? (
          <MdContactSupport className="h-5 w-5" />
        ) : (
          <MdOutlinePersonAddAlt className="h-5 w-5" />
        )}
      </div>
    ),
    onClick: () => {
      if (
        maxUserArrayLength !== 0 &&
        addNewUserRow.length < maxUserArrayLength &&
        unityType !== 'convertedfree'
      ) {
        setRowEditId(0)

        setAddNewUserRow([...addNewUserRow, nextAvailableId])

        setNextAvailableId((prevId) => prevId + 1)
      } else if (
        addNewUserRow.length === maxUserArrayLength &&
        unityType === 'tellitfree'
      ) {
        localStorage.setItem('page_url', window.location.href)
        navigate('/contact', { state: { updateProVersion: true } })
      } else if (unityType === 'convertedfree') {
        localStorage.setItem('page_url', window.location.href)
        navigate('/contact', { state: { helpMenu: true } })
      }
    },
    disabled: loading
  }

  // const useDelayUnmount = (isMounted: boolean, delayTime: number) => {
  //   const [showDiv, setShowDiv] = useState(false)
  //   useEffect(() => {
  //     let timeoutId: any
  //     if (isMounted && !showDiv) {
  //       setShowDiv(true)
  //     } else if (!isMounted && showDiv) {
  //       timeoutId = setTimeout(() => {
  //         setShowDiv(false)
  //       }, delayTime) // delay our unmount
  //     }
  //     return () => {
  //       clearTimeout(timeoutId)
  //     } // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
  //   }, [isMounted, delayTime, showDiv])
  //   return showDiv
  // }

  const removeNewForm = (id: number): void => {
    setAddNewUserRow((prevArray) => prevArray.filter((item) => item !== id))
  }

  const usersEmails = [...dataList].map((item) => item.email)

  useEffect(() => {
    setRowEditId(0)
    getContactsData()
    getAccount((res: any) => {
      const formData = res

      setUnityType(formData?.unity_type)

      if (formData?.unity_admins.length > 0) {
        const unityAdminsArray = formData?.unity_admins
        const unityAdminsArrayModified =
          unityAdminsArray.length > 0
            ? unityAdminsArray.map((number: string) => number.replace(/-/g, ''))
            : []
        setUnityAdminArray(unityAdminsArrayModified)
      }
    })
  }, [])

  useEffect(() => {
    const filteredSuperAdmin = dataList.filter((item: any) => {
      const itemPersonNumCheck: string = item.personalNumber.replace(/-/g, '')

      const dataArrayPersonNumCheck =
        unityAdminArray.length > 0
          ? unityAdminArray.map((number: string) => number.replace(/-/g, ''))
          : ''
      return dataArrayPersonNumCheck.includes(itemPersonNumCheck)
    })

    if (filteredSuperAdmin?.[0]?.id !== undefined) {
      setSuperAdminId(filteredSuperAdmin?.[0].id)
    }
  }, [unityAdminArray, dataList])

  return (
    <>
      <TellitLoader loading={loading} customClass="2xl:max-w-[778px]">
        {dataList.map((row, rowIndex) => (
          <div
            className={`${rowIndex === 0 ? 'mt-0' : 'mt-3'} w-full`}
            key={row.id}
          >
            {rowEditId !== row.id ? (
              <UserDataGrid
                rowData={row}
                rowIndex={rowIndex}
                handleToggleChange={handleToggleChange}
                handleEditTableRow={handleEditTableRow}
                key={row.id}
              />
            ) : (
              <UserForm
                key={row.id}
                rowData={row}
                rowIndex={rowIndex}
                callBack={(type, data) => {
                  userCallBackHandler(type, data)
                }}
                getContactsData={getContactsData}
                usersEmails={usersEmails}
                superAdminId={superAdminId}
                errorArray={errorArray}
                setErrorArray={setErrorArray}
              />
            )}
          </div>
        ))}
      </TellitLoader>

      {getUsersLength <= maxAvailableUserLength && (
        <>
          <div className={loading ? 'pointer-events-none' : ''}>
            {addNewUserRow.map((item, index) => (
              <div key={item} className="mt-3  w-full">
                <UserForm
                  callBack={(type, data) => {
                    userCallBackHandler(type, data)
                  }}
                  getContactsData={getContactsData}
                  newFormId={item}
                  removeNewForm={removeNewForm}
                  rowIndex={item}
                  usersEmails={usersEmails}
                  errorArray={errorArray}
                  setErrorArray={setErrorArray}
                />
              </div>
            ))}
          </div>

          {!loading && (
            <div className="mt-3 transition-all delay-[3000ms] duration-1000 ease-out">
              <TellitButton {...addButtonProps} />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default UsersList
