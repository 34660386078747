import Avatar from 'react-avatar'

type ITellitAvatar = {
  size?: string
  src?: string
  className?: string
  name?: string
}

const TellitAvatar = ({
  size = '50px',
  src,
  className,
  name
}: ITellitAvatar): JSX.Element => {
  const getOnlyFirstTwoNames = (): any => {
    const removeUnwantedChars = name?.replace(/[0-9\s]+/g, ' ')
    const words = removeUnwantedChars?.split(' ')

    const fullName =
      words !== undefined && words?.length > 1
        ? words[0] + ' ' + words[1]
        : words?.[0]
    return fullName
  }
  return (
    <Avatar
      round
      size={size}
      src={src}
      className={`!bg-avatar avatar ${className}`}
      name={getOnlyFirstTwoNames()}
    />
  )
}

export default TellitAvatar
