import { useEffect, useState } from 'react'
import handleApiCall from 'api/handleApiCall'
// import { handleToastMessage } from 'utilities'
import { TellitLoader } from 'components/common'

type RecordingProps = {
  mediaId: string
}

const Recording = ({ mediaId }: RecordingProps): JSX.Element => {
  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(false)
  const [mediaSource, setMediaSource] = useState<any>(undefined)

  const getMedia = (): void => {
    setIsMediaLoading(true)
    void handleApiCall({
      urlType: 'getCallRecording',
      auth: true,
      // testAdminAccount: true,
      urlParams: `&media_id=${mediaId}`,
      cb: (res, state) => {
        if (res?.data !== undefined && state) {
          const decodedData = atob(res.data)
          // Create a typed array from the decoded string
          const arrayBuffer = new Uint8Array(decodedData?.length)
          for (let i = 0; i < decodedData.length; i++) {
            arrayBuffer[i] = decodedData.charCodeAt(i)
          }
          // Create Blob object
          const blob = new Blob([arrayBuffer], { type: 'audio/mp3' })
          // Create URL for the Blob object
          const url = URL.createObjectURL(blob)
          // set url
          setMediaSource(url)
        } else {
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          }
        }
        setIsMediaLoading(false)
      }
    })
  }

  useEffect(() => {
    getMedia()
  }, [mediaId])

  return (
    <>
      <TellitLoader loading={isMediaLoading}>
        <audio controls className="h-[54px] rounded-[25px] bg-white">
          <source src={mediaSource} type="audio/mp3" />
          {/* <source
              src="https://cdn.freesound.org/previews/714/714775_8432823-lq.ogg"
              type="audio/ogg"
            /> */}
        </audio>
      </TellitLoader>
    </>
  )
}

export default Recording
