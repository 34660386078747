import React from 'react'
import TellitLoader from './TellitLoader'
import Typography, {
  TypographySizes,
  TypographyWeights
} from 'components/common/Typography'
import IconCustom from './IconCustom'

export type ITypes = 'large' | 'medium' | 'small'

type ITellitCardProps = {
  children: React.ReactNode
  customClass?: string
  header?: string
  description?: string
  actionBtnName?: undefined | string
  icon?: JSX.Element | null | undefined | boolean
  loading?: boolean
  actionOnClick?: () => void
  type?: ITypes
}

const getHeaderProps = (type: ITypes, actionBtnName?: string): any => {
  switch (type) {
    case 'large':
      return {
        size: TypographySizes['3xl-2'],
        weight: TypographyWeights.bold,
        opacity: 1,
        style: 'mt-[8px] !leading-[2.5rem] !font-bolder',
        headerWrapperStyle: 'mb-[16px]',
        // don't override this style here with dimensions and paddings
        wrapperStyle: 'p-[25px]'
      }
    case 'medium':
      return {
        size: TypographySizes['2xl-2'],
        weight: TypographyWeights.bold,
        opacity: 1,
        style: 'leading-[17px]',
        headerWrapperStyle: actionBtnName !== '' ? 'mb-[37.99px]' : 'mb-[16px]',
        wrapperStyle: 'p-[30px]'
      }
    case 'small':
      return {
        size: TypographySizes.md,
        weight: TypographyWeights.normal,
        opacity: 0.6,
        style: 'leading-[16px]',
        headerWrapperStyle: 'mb-[24px]',
        wrapperStyle: 'p-[29px]'
      }
  }
}

const TellitCard = ({
  children,
  customClass,
  header = '',
  description = '',
  actionBtnName = '',
  icon = null,
  loading = false,
  type = 'large',
  actionOnClick = () => {
    console.log('action btn clicked')
  }
}: ITellitCardProps): JSX.Element => {
  return (
    <div
      className={`w-full rounded-large bg-white tellit-card-${type} ${
        getHeaderProps(type).wrapperStyle
      } ${customClass}`}
    >
      {(header !== '' || icon !== null || actionBtnName !== '') && (
        <div className="header">
          <div
            className={`flex items-center  ${
              getHeaderProps(type, actionBtnName).headerWrapperStyle
            } ${
              header !== '' && actionBtnName !== null
                ? 'justify-between'
                : header !== ''
                  ? 'justify-start'
                  : 'justify-end'
            }`}
          >
            {header !== '' && (
              <Typography
                weight={getHeaderProps(type).weight}
                size={getHeaderProps(type).size}
                opacity={getHeaderProps(type).opacity}
                customClasses={`${getHeaderProps(type).style}`}
              >
                {header}
              </Typography>
            )}

            {(actionBtnName !== null || icon !== null) && (
              <div
                className={`group flex cursor-pointer items-center justify-end gap-[5px] uppercase text-primary`}
                onClick={actionOnClick}
              >
                <Typography
                  weight={TypographyWeights.bold}
                  size={TypographySizes.sm}
                  customClasses="tracking-[0.56px] leading-[18px] mt-[2px] group-hover:!text-secondary"
                >
                  {actionBtnName}
                </Typography>
                {icon !== (null as unknown as boolean) ? (
                  <IconCustom
                    type="fa6"
                    name="arrow-right-long"
                    size="small"
                    customClass="group-hover:!text-secondary"
                  />
                ) : (
                  icon
                )}
              </div>
            )}
          </div>
          {description !== '' && (
            <Typography
              opacity={0.6}
              weight={TypographyWeights.normal}
              size={TypographySizes.sm}
              customClasses="mb-[15px] mt-[-15px]"
            >
              {description}
            </Typography>
          )}
        </div>
      )}

      <TellitLoader loading={loading}>{children}</TellitLoader>
    </div>
  )
}

export default TellitCard
