import { useTranslation } from 'react-i18next'

import { FiClock } from 'react-icons/fi'
import { FaInfinity } from 'react-icons/fa6'

import { TellitButton, TellitCard } from 'components/common'
import {
  // TellitRadio, // hide for free version
  TellitRadioSwitch
} from 'components/form'
import Typography, {
  TypographySizes,
  TypographyWeights,
  TypographyColors
} from 'components/common/Typography'

import { OpeningHoursType } from './'
import { MdContactSupport } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

type LeftSideContentProps = {
  hoursType: string
  setHoursType: (value: string) => void
  updateRingingGroup: () => void
  isSubmitted: boolean
  setIsSubmitted: (value: boolean) => void
  buttonDisabled: boolean
}

const LeftSideContent = ({
  hoursType,
  setHoursType,
  updateRingingGroup,
  isSubmitted,
  setIsSubmitted,
  buttonDisabled
}: LeftSideContentProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const switches = [
    { icon: <FiClock />, label: t('OpeningHours.Label.Vardagar') },
    { icon: <FaInfinity />, label: t('OpeningHours.Label.DygnetRunt') }
  ]

  const addButtonProps = {
    customClass: `!justify-start 2xl:max-w-[778px] bg-grey pl-6 enabled:hover:bg-primary enabled:hover:text-secondary !min-h-26 !h-26  border-primary border-opacity-9 border-dashed border-2 enabled:hover:border-primary !py-6 tellit-user-add`,
    labelClass: 'font-bold !text-lg',
    label: t('OpeningHours.Label.FooterDescription.Premium'),
    icon: (
      <div
        id="tellit-customIcon"
        className={`group-hover:text-primary' flex h-12 w-12 items-center justify-center rounded-full bg-primary text-white group-hover:bg-secondary group-disabled:bg-black/50 group-disabled:text-inactive`}
      >
        <MdContactSupport className="h-5 w-5" />
      </div>
    ),
    onClick: () => {
      const unityType = localStorage.getItem('accountType')
      if (unityType === 'convertedfree') {
        localStorage.setItem('page_url', window.location.href)
        navigate('/contact', { state: { helpMenu: true } })
      } else {
        localStorage.setItem('page_url', window.location.href)
        navigate('/contact', { state: { updateProVersion: true } })
      }
      // if (
      //   maxUserArrayLength !== 0 &&
      //   addNewUserRow.length < maxUserArrayLength &&
      //   unityType !== 'convertedfree'
      // ) {
      //   setRowEditId(0)
      //   setAddNewUserRow([...addNewUserRow, nextAvailableId])
      //   setNextAvailableId((prevId) => prevId + 1)
      // } else if (
      //   addNewUserRow.length === maxUserArrayLength &&
      //   unityType === 'tellitfree'
      // ) {
      //   localStorage.setItem('page_url', window.location.href)
      //   navigate('/contact', { state: { updateProVersion: true } })
      // } else if (unityType === 'convertedfree') {
      //   localStorage.setItem('page_url', window.location.href)
      //   navigate('/contact', { state: { helpMenu: true } })
      // }
    },
    disabled: false
  }

  return (
    <TellitCard
      header={t('OpeningHours.Title')}
      customClass="2xl:max-w-[778px]"
    >
      <Typography
        weight={TypographyWeights.bold}
        size={TypographySizes['2xl-2']}
        customClasses="!font-bolder -mt-[2px]"
      >
        {t('OpeningHours.Label.Description')}
      </Typography>

      <div className="mt-[14px]  border-b-[1px] pb-[36px]">
        <TellitRadioSwitch
          switches={switches}
          defaultValue={
            hoursType === ''
              ? -1
              : hoursType === OpeningHoursType.Partial
                ? 0
                : 1
          }
          onChange={(id) => {
            if (isSubmitted) {
              setIsSubmitted(false)
            }

            setHoursType(
              id === 0 ? OpeningHoursType.Partial : OpeningHoursType.Full
            )
          }}
          disabledId={0}
        />
      </div>

      {/* hide for free version */}
      {/* <div className="border-b-[1px] pb-[18px] pt-[28px]">
        <Typography
        weight={TypographyWeights.semibold}
        size={TypographySizes['2xl-2']}
        color={TypographyColors.primary}
        customClasses={`mb-[11px] lg:${TypographySizes['2xl-3']}`}
      >
        {t('OpeningHours.Label.RadioHeader')}
      </Typography>

        {[1, 2, 3].map((type) => (
          <TellitRadio
            key={`radio-${type}`}
            name="radio-1"
            checked={hoursType === type}
            onchange={() => {
              setHoursType(type)
            }}
            label={t(`OpeningHours.Label.Radio${type}`)}
            radioButtonType='medium'  
          />
        ))}
      </div> */}

      <div className="mb-0 mt-[30px] flex w-full flex-col items-center justify-between justify-items-center gap-3 self-center text-center sm:flex-row">
        { <Typography
          weight={TypographyWeights.normal}
          size={TypographySizes.sm}
          color={TypographyColors.primary}
          opacity={0.6}
          customClasses="ml-[5px] w-auto mb-3 sm:mb-0 sm:text-start"
        >
          {localStorage.getItem('accountType') === 'tellitfree'
            ? t('OpeningHours.Label.FooterDescription.Free')
            : t('')}
        </Typography> }
        {localStorage.getItem('accountType') !== 'tellitfree' && (
          <div className="mt-3 transition-all delay-[3000ms] duration-1000 ease-out">
            <TellitButton {...addButtonProps} />
          </div>
        )}

        {/* <ActionButton
          label={
            isSubmitted
              ? t('Generic.Button.Sparat')
              : t('Generic.Button.Submit')
          }
          onClick={updateRingingGroup}
          isSubmitted={isSubmitted}
          disabled={buttonDisabled}
        /> */}
      </div>
    </TellitCard>
  )
}

export default LeftSideContent
