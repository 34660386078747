import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6'

type IBaseHeaderProps = {
  children?: React.ReactNode
}

const Layout: React.FC<IBaseHeaderProps> = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  return (
    <div>
      <div className="flex">
        <Sidebar isMenuOpen={isMenuOpen} mobile={false} />
        {/* mobile/tab/small desktop only */}
        {isMobileMenuOpen && (
          <div
            className="mobile-nav absolute z-[900] h-screen w-full bg-black/80 2xl:hidden"
            onClick={() => {
              setIsMobileMenuOpen(false)
            }}
          />
        )}
        <div
          className={`${
            isMobileMenuOpen ? '' : 'translate-x-[-100%]'
          } mobile-nav absolute z-[999] h-screen transition-all duration-[700] ease-in-out 2xl:hidden`}
        >
          <Sidebar
            isMenuOpen={true}
            mobile
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
        </div>

        <div className="flex flex-1">
          <div className="w-full-firefox w-fill">
            <div
              onClick={() => {
                setIsMenuOpen(!isMenuOpen)
              }}
              className="menu-expand-btn group absolute z-[999] ml-[-1.2rem] mt-[11px] hidden h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-full bg-white shadow-expandBtn transition-all duration-100 hover:!bg-secondary md:flex"
            >
              {isMenuOpen ? (
                <FaChevronLeft className="!text-secondary group-hover:!text-white" />
              ) : (
                <FaChevronRight className="!text-secondary group-hover:!text-white" />
              )}
            </div>
            <Navbar
              setIsMenuOpen={setIsMobileMenuOpen}
              isMenuOpen={isMobileMenuOpen}
            />
            <div className="h-[calc(100vh-76px)] w-full flex-grow overflow-y-auto !bg-inactive p-3 md:px-4 md:pt-0 lg:px-6 2xl:px-8">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
