import { useRef } from 'react'
import { DateRange, DateRangePicker3 } from '@blueprintjs/datetime2'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { DateFormatter } from 'react-day-picker'
import moment from 'moment'

import useOutsideClick from 'helpers/getOutsideClick'

import 'moment/locale/sv'
import '@blueprintjs/datetime2/lib/css/blueprint-datetime2.css'

type DateRangePickersProps = {
  label?: string | undefined
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  getSelectedDateRange: (dateRange: any) => void
  selectFilterDateRange: () => void
  value: DateRange | undefined
}

type CustomDateRangeShortcut = {
  label: string
  dateRange: [Date, Date]
}

export const getDateRangeForSelectedShortcuts = (
  type: string
): [Date, Date] => {
  const today = new Date()
  let currentDayOfWeek = today.getDay()

  if (currentDayOfWeek === 0) {
    currentDayOfWeek = 7
  }

  const start = new Date(today)
  const end = new Date(today)

  switch (type) {
    case 'thisWeek':
      start.setDate(today.getDate() - currentDayOfWeek + 1)
      end.setDate(today.getDate() + (7 - currentDayOfWeek))
      break

    case 'lastWeek':
      start.setDate(today.getDate() - currentDayOfWeek - 6)
      end.setDate(today.getDate() - currentDayOfWeek)
      break

    case 'thisMonth':
      start.setDate(1)
      end.setMonth(today.getMonth() + 1, 0)
      break

    case 'last9Months':
      start.setMonth(today.getMonth() - 8, 1)
      end.setMonth(today.getMonth(), today.getDate() - 1)
      break

    default:
      break
  }

  start.setHours(0, 0, 0, 0)
  end.setHours(23, 59, 59, 999)

  // if (end > today) {
  //   end.setDate(today.getDate())
  //   end.setHours(23, 59, 59, 999)
  // }
  // remove future selected dates

  return [start, end]
}

export const customCalenderShortcuts = (
  t: TFunction<'translation', undefined>
): CustomDateRangeShortcut[] => [
  {
    label: t('Generic.Calendar.Today'),
    dateRange: [new Date(), new Date()]
  },
  {
    label: t('Generic.Calendar.Yesterday'),
    dateRange: [
      new Date(new Date().setDate(new Date().getDate() - 1)),
      new Date(new Date().setDate(new Date().getDate() - 1))
    ]
  },
  {
    label: t('Generic.Calendar.ThisWeek'),
    dateRange: getDateRangeForSelectedShortcuts('thisWeek')
  },
  {
    label: t('Generic.Calendar.LastWeek'),
    dateRange: getDateRangeForSelectedShortcuts('lastWeek')
  },
  {
    label: t('Generic.Calendar.ThisMonth'),
    dateRange: getDateRangeForSelectedShortcuts('thisMonth')
  },
  {
    label: t('Generic.Calendar.LastMonth'),
    dateRange: [
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      new Date(new Date().getFullYear(), new Date().getMonth(), 0)
    ]
  }
  // {
  //   label: t('Generic.Calendar.Last9Months'),
  //   dateRange: getDateRangeForSelectedShortcuts('last9Months')
  // },
  // {
  //   label: t('Generic.Calendar.ThisYear'),
  //   dateRange: [new Date(new Date().getFullYear(), 0, 1), new Date()]
  // }
]

const DateRangePickers = ({
  isOpen,
  setIsOpen,
  getSelectedDateRange,
  value
}: DateRangePickersProps): JSX.Element | null => {
  const { t } = useTranslation()
  const calenderWrapper = useRef(null)

  const getSelectedShortCutIndex = (): number => {
    if (
      value == null ||
      value.length !== 2 ||
      value.some((date) => date == null)
    ) {
      return -1 // Invalid or incomplete date range
    }

    const calenderDateRanges = customCalenderShortcuts(t)
    const startDateFormat = moment(value[0]).format('MM-DD')
    const endDateFormat = moment(value[1]).format('MM-DD')

    for (let i = 0; i < calenderDateRanges.length; i++) {
      const [startDate, endDate] = calenderDateRanges[i].dateRange

      if (
        moment(startDate).format('MM-DD') === startDateFormat &&
        moment(endDate).format('MM-DD') === endDateFormat
      ) {
        return i
      }
    }

    return -1 // No matching date range found
  }

  const handleDateRangeChange = (selectedRange: DateRange): void => {
    getSelectedDateRange(selectedRange)
  }

  const formatWeekdayName: DateFormatter = (date) => (
    <>
      {moment(new Date(date)).format('ddd').charAt(0).toUpperCase() +
        moment(new Date(date)).format('ddd').slice(1)}
    </>
  )

  useOutsideClick({
    ref: calenderWrapper,
    callback: () => {
      if (setIsOpen !== undefined) {
        setIsOpen(false)
      }
    }
  })

  if (!isOpen) return null

  return (
    <div
      style={{ zIndex: 899 }}
      className="absolute mt-3 translate-y-[42px]"
      ref={calenderWrapper}
    >
      <DateRangePicker3
        locale="sv"
        onChange={handleDateRangeChange}
        timePickerProps={undefined}
        allowSingleDayRange={true}
        shortcuts={customCalenderShortcuts(t)}
        selectedShortcutIndex={getSelectedShortCutIndex()}
        onShortcutChange={() => {
          setIsOpen(false)
        }}
        className="transition-all duration-1000 ease-out"
        value={value}
        dayPickerProps={
          {
            locale: 'sv',
            localeUtils: moment.localeData('sv') as any,
            formatCaption: 'MMM YYYY',
            formatters: {
              formatWeekdayName
            }
          } as any
        }
      />
    </div>
  )
}

export default DateRangePickers
