import React from 'react'
import { ImSpinner6 } from 'react-icons/im'

type PropsTypes = {
  loading: boolean
  children?: React.ReactNode
  loadingText?: string | React.ReactNode
  customClass?: string
}

const TellitLoader = ({
  loading,
  children,
  customClass,
  loadingText
}: PropsTypes): JSX.Element => {
  if (loading) {
    return (
      <div
        className={`w-full flex flex-col items-center justify-center gap-2 ${customClass}`}
      >
        <ImSpinner6 className="animate-spin text-2xl text-primary" />
        <span className={`text-primary mx-auto text-base`}>{loadingText}</span>
      </div>
    )
  }
  return <>{children}</>
}

export default TellitLoader
