import { useTranslation } from 'react-i18next'

import { PageWrapper } from 'shared'

import Left from './Left'

const Contact = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <PageWrapper
      title={t('Contact.Right.Title')}
      description={t('Contact.Right.Description')}
      leftCol={<Left />}
      // rightCol={<></>}
    />
  )
}

export default Contact
