import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { PageWrapper } from 'shared'

import ProfileOverviewCard from './ProfileOverviewCard'
import LeftColumn from './LeftColumn'

function Profile(): JSX.Element {
  const { t } = useTranslation()

  // const navigate = useNavigate()

  const [superAdminData, setSuperAdminData] = useState<any>(null)

  const RightColumn: React.FC = () => {
    return (
      <ProfileOverviewCard
        key="profileProfileCard"
        // btnName={t('Profile.Label.SeUsers')}
        // btnClass="!text-primary font-bold hover:!text-secondary"
        role={t('Profile.Label.SuperAdmin')}
        // onClick={() => {
        //   navigate('/users')
        // }}
        className="2xl:max-w-[778px]"
        formData={superAdminData}
      />
    )
  }

  return (
    <PageWrapper
      title={t('Profile.Title')}
      description={t('Profile.Content')}
      leftCol={<LeftColumn setSuperAdminData={setSuperAdminData} />}
      rightCol={<RightColumn />}
    />
  )
}

export default Profile
