import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import handleApiCall from 'api/handleApiCall'

import { PageWrapper } from 'shared'
import { handleToastMessage } from '../../utilities'
import LeftSideContent from './LeftSideContent'

export enum OpeningHoursType {
  Partial = '8to7',
  Full = '24by7'
}

function OpeningHours(): JSX.Element {
  const { t } = useTranslation()

  const [hoursType, setHoursType] = useState<string>('')
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [disabledUntilSubmit, setDisabledUntilSubmit] = useState<boolean>(false)

  const getOpeningHours = (): void => {
    setDisabledUntilSubmit(true)
    void handleApiCall({
      urlType: 'getOpeningHours',
      auth: true,
      cb: (res, state) => {
        setDisabledUntilSubmit(false)

        if (state) {
          if (res?.data?.data as boolean) {
            if (
              (res?.data?.data?.openinghours?.[0] as boolean) &&
              (res?.data?.data?.openinghours?.[0]?.time_window_start as boolean)
            ) {
              setHoursType(OpeningHoursType.Partial)
            } else {
              setHoursType(OpeningHoursType.Full)
            }
          } else {
            setHoursType(OpeningHoursType.Full)
          }
        } else {
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          } else {
            toast(t('OpeningHours.Msg.GetOpeningHoursError'), {
              type: 'error'
            })
          }
          setHoursType('')
        }
      }
    })
  }

  const updateRingingGroup = (): void => {
    setDisabledUntilSubmit(true)

    const data: { weekdaysstart: string | null; weekdaysend: string | null } = {
      weekdaysstart: 'null',
      weekdaysend: 'null'
    }

    if (hoursType === OpeningHoursType.Partial) {
      data.weekdaysstart = '08:00'
      data.weekdaysend = '17:00'
    }

    void handleApiCall({
      urlType: 'updateOpeningHours',
      auth: true,
      urlParams: `&data=${encodeURIComponent(JSON.stringify(data))}`,
      cb: (res, state) => {
        setDisabledUntilSubmit(false)

        if (state) {
          setIsSubmitted(true)

          if (res?.status === 200) {
            getOpeningHours()
          }
        } else {
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          } else {
            handleToastMessage(t('Msg.UpdateError'))
          }
        }
      }
    })
  }

  useEffect(() => {
    getOpeningHours()
  }, [])

  useEffect(() => {
    return () => {
      setIsSubmitted(false)
    }
  }, [])

  return (
    <PageWrapper
      title={t('OpeningHours.Title')}
      description={t('OpeningHours.Description')}
      leftCol={
        <LeftSideContent
          hoursType={hoursType}
          updateRingingGroup={updateRingingGroup}
          setHoursType={setHoursType}
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
          buttonDisabled={disabledUntilSubmit}
        />
      }
    />
  )
}

export default OpeningHours
