import { LiaSave } from 'react-icons/lia'
import { HiCheck } from 'react-icons/hi'
import { RiDeleteBinLine } from 'react-icons/ri'
import { VscSend } from 'react-icons/vsc'

type ActionButtonProps = {
  label: string | undefined
  type?: 'save' | 'delete' | 'send' | string
  onClick?: any
  disabled?: boolean
  isSubmitted?: boolean
  onMouseLeave?: any
  onMouseEnter?: any
  id?: string
}

const ActionButton = ({
  label,
  type = 'save',
  onClick,
  disabled,
  isSubmitted,
  onMouseLeave,
  onMouseEnter,
  id
}: ActionButtonProps): JSX.Element => {
  const commonButtonClass =
    'flex h-[47px] w-auto min-w-[116px] px-[2px] items-center justify-center transition-all duration-300 ease-in-out rounded-xl tracking-wider antialiased shadow-none disabled:opacity-50'

  const iconClass = '!h-[1.2em] !w-[1.2em]'

  const getButtonConfig = (): {
    buttonClass: string
    labelClass: string
    icon: JSX.Element
  } => {
    if (type === 'send') {
      return {
        buttonClass:
          'bg-activeLight tracking-wider antialiased shadow-none enabled:hover:bg-active disabled:opacity-50',
        labelClass: 'text-primary',
        icon: <VscSend className={iconClass} />
      }
    } else if (type === 'delete') {
      return {
        buttonClass: (isSubmitted as boolean)
          ? 'bg-primary'
          : 'bg-danger bg-opacity-30 enabled:hover:bg-danger enabled:hover:bg-opacity-100',
        labelClass: (isSubmitted as boolean)
          ? '!text-danger'
          : '!text-danger-dark',
        icon: <RiDeleteBinLine className={iconClass} />
      }
    } else {
      return {
        buttonClass: (isSubmitted as boolean)
          ? '!bg-primary'
          : 'bg-activeLight enabled:hover:bg-active',
        labelClass: (isSubmitted as boolean)
          ? '!text-activeLight'
          : 'text-primary',
        icon: (isSubmitted as boolean) ? (
          <HiCheck className={iconClass} />
        ) : (
          <LiaSave className={iconClass} />
        )
      }
    }
  }

  const { buttonClass, labelClass, icon } = getButtonConfig()

  return (
    <button
      className={`${commonButtonClass} ${buttonClass}`}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      disabled={disabled}
      id={id}
    >
      <div className={`flex items-center justify-end gap-2 ${labelClass}`}>
        <span className="text-md-1 leading-[0.8rem]">{label}</span>
        {icon}
      </div>
    </button>
  )
}

export default ActionButton
