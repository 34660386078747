import React from 'react'

type IconButtonProps = {
  icon: JSX.Element
  onClick: () => void
  disabled?: boolean
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  disabled = false
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`flex h-[52px] w-[52px] items-center justify-center rounded-full bg-inactive p-0 text-primary hover:bg-primary hover:text-inactive ${
        disabled ? 'cursor-not-allowed opacity-50' : ''
      }`}
    >
      {icon !== null && <span>{icon}</span>}
    </button>
  )
}

export default IconButton
