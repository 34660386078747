import { type ChangeEvent, useState, type FocusEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  validateZipCode,
  validateMobile,
  validatePersonNumber
} from 'helpers/validationFunctions'

type TellitInputProps = {
  type?: string
  label?: string
  id?: string
  name?: string
  value?: string | number
  customClass?: string
  alignCenter?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  placeholder?: string
  errorMsg?: string
  disabled?: boolean
  wrapperClass?: string
  validationType?: string
  enableTypeValidation?: boolean
  enableErrorMsg?: boolean
  setError?: (value: boolean) => void
  required?: boolean
}

const TellitInput = ({
  type = 'text',
  label,
  id,
  name,
  value,
  customClass,
  onChange = () => {},
  onBlur,
  alignCenter = false,
  placeholder,
  errorMsg = '',
  disabled = false,
  wrapperClass,
  validationType,
  enableErrorMsg = false,
  enableTypeValidation = true,
  setError,
  required
}: TellitInputProps): JSX.Element => {
  const { t } = useTranslation()

  const [builtInErrorMsg, setBuiltInErrorMsg] = useState('')

  const validateInput = (e: React.ChangeEvent<HTMLInputElement>): null => {
    const { value } = e.target

    if (required === true && value.length === 0) {
      setError?.(true)
      setBuiltInErrorMsg(`${label} ${t('Msg.IsRequired')}`)
    } else if (value.length > 255) {
      setBuiltInErrorMsg(t('Msg.MaxLength'))
    } else if (validationType === 'personalNumber') {
      personNumberValidation(value)
    } else if (type === 'email') {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
      if (!emailRegex.test(value)) {
        setError?.(true)
        setBuiltInErrorMsg(t('Msg.EnterValidEmail'))
      } else {
        setError?.(false)
        setBuiltInErrorMsg('')
      }
    } else if (type === 'zipCode') {
      const validateValue = validateZipCode(value)

      if (validateValue === 'zipCode') {
        setError?.(true)
        setBuiltInErrorMsg(t('Msg.EnterZipCode'))
      } else if (validateValue === 'ort') {
        setError?.(true)
        setBuiltInErrorMsg(t('Msg.EnterOrtCode'))
      } else {
        setError?.(false)
        setBuiltInErrorMsg('')
      }
    } else if (validationType === 'mobile') {
      const isMobileValidate: boolean = validateMobile(value)

      if (isMobileValidate) {
        setError?.(true)
        setBuiltInErrorMsg(t('Msg.EnterValidMobile'))
      } else {
        setError?.(false)
        setBuiltInErrorMsg('')
      }
    } else if (validationType === 'newNumber' && required === true) {
      if (value.length === 0) {
        setError?.(true)
        setBuiltInErrorMsg(t('Msg.EnterNewNumber'))
      } else {
        setError?.(false)
        setBuiltInErrorMsg('')
      }
    } else {
      setError?.(false)
      setBuiltInErrorMsg('')
    }

    return null
  }

  const personNumberValidation = (val: string): void => {
    const isValidNumber = validatePersonNumber(val)

    if (isValidNumber) {
      setError?.(true)
      setBuiltInErrorMsg(`${t('Msg.EnterValid')} ${label}`)
    } else {
      setError?.(false)
      setBuiltInErrorMsg('')
    }
  }

  useEffect(() => {
    const fieldValue = value as unknown as boolean

    if (
      (enableErrorMsg as boolean) &&
      name === 'personalNumber' &&
      fieldValue
    ) {
      personNumberValidation(`${value}`)
    }
  }, [enableErrorMsg])

  return (
    <div className={`flex flex-col ${wrapperClass}`}>
      <label
        htmlFor={id}
        className={`relative z-[1]  mb-[-0.57rem] ml-1 w-fit rounded-xl px-1.5  text-xxs font-normal normal-case tracking-[0.72px] text-primary shadow-label ${
          disabled ? 'bg-inactive' : 'bg-white'
        }`}
      >
        {label}
      </label>

      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={(e) => {
          if (enableTypeValidation) {
            validateInput(e)
          }
          onChange(e)
        }}
        onBlur={(e) => {
          if (enableTypeValidation) {
            validateInput(e)
          }
          onBlur !== null && onBlur !== undefined && onBlur(e)
        }}
        placeholder={placeholder}
        className={`input input-sm input-white h-[45px] w-full rounded-lg border border-gray-100 bg-white p-4 !pl-[10px] !pt-[24px] text-md font-normal text-primary shadow-input !outline-none !outline-offset-0 disabled:!bg-inactive disabled:shadow-none ${
          alignCenter ? 'text-center' : 'text-left'
        } ${customClass}`}
        disabled={disabled}
      />

      {(enableErrorMsg ||
        ((required as boolean) && builtInErrorMsg !== '')) && (
        <p className="text-error mt-1 text-center text-[0.7rem]  font-normal text-danger-dark-xl ">
          {errorMsg.length > 0 ? errorMsg : builtInErrorMsg}
        </p>
      )}
    </div>
  )
}

export default TellitInput
