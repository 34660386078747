import { useTranslation } from 'react-i18next'
import TellitLoader from 'components/common/TellitLoader'

type StepOneProps = {
  handleClick: (step: number) => void
  controller: AbortController
  setStartPolling: (startPolling: boolean) => void
}

const StepLoading = ({
  handleClick,
  controller,
  setStartPolling
}: StepOneProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full items-center justify-center">
      <div className="mb-12 ml-12 mr-12 mt-16 text-center">
        <TellitLoader loading={true} />

        <h3 className="mt-6 text-2xl font-bold text-primary">
          {t('Login.Loading.Label.Soker')}
        </h3>

        <div
          onClick={() => {
            handleClick(0)
            controller?.abort()
            setStartPolling(false)
          }}
          className="mt-[40px] cursor-pointer text-center text-lg text-secondary underline hover:text-primary"
        >
          {t('Login.Label.AvbrytInloggning')}
        </div>
      </div>
    </div>
  )
}

export default StepLoading
