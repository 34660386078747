import * as types from './constant'
import { type ActionType } from './types'

export const NavigateForward = (): ActionType => ({
  type: types.NAVIGATE_FORWARD
})
export const NavigateBackward = (): ActionType => ({
  type: types.NAVIGATE_BACKWARD
})

export const UserDataUpdate = (payload: any): ActionType => ({
  type: types.UPDATE_USER_DATA,
  payload
})

export const LoginUserData = (payload: any): ActionType => ({
  type: types.GET_USER_DATA,
  payload
})

export const setIsUpdateLogUserStore = (payload: any): ActionType => ({
  type: types.IS_UPDATE_USER_STORE,
  payload
})
