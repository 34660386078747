import { useTranslation } from 'react-i18next'
import { PageWrapper } from 'shared'

import UsersList from './UsersList'

function Users(): JSX.Element {
  const { t } = useTranslation()

  const LeftColumn: React.FC = () => {
    return <UsersList />
  }

  return (
    <PageWrapper
      title={t('User.Title')}
      description={
        <>
          {t('User.Content')}{' '}
          <span className="underline">{t('User.Content.Link')}</span>
        </>
      }
      leftCol={<LeftColumn />}
      wrapperClass="user-wrapper"
    />
  )
}

export default Users
