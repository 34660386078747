import React from 'react'
import Popup from 'reactjs-popup'

import 'reactjs-popup/dist/index.css'

type PopupProps = {
  content: React.ReactElement
  trigger: React.ReactElement
}

const contentStyle = {
  width: 'auto',
  minWidth: '300px',
  minHeight: '54px',
  //   background: '#0D002B',
  border: 0,
  borderRadius: 30,
  padding: 0,
  display: 'flex'
}
const overlayStyle = { background: 'rgba(0,0,0,0.5)' }
const arrowStyle = { color: '#0D002B', stroke: '#0D002B' }

const PopupCustom = ({
  content,
  trigger //   refFromChild
}: PopupProps): JSX.Element => {
  return (
    <Popup trigger={trigger} {...{ contentStyle, overlayStyle, arrowStyle }}>
      {content}
    </Popup>
  )
}

export default PopupCustom
