import { useEffect, useState } from 'react'
import { TellitLoader, TellitCard, IconCustom } from 'components/common'
import { useTranslation } from 'react-i18next'

import { type Stats } from 'store/dashboard/types'
import handleApiCall from 'api/handleApiCall'

const StatCard = (): JSX.Element => {
  const [stats, setStats] = useState<Stats>({
    missed: 0,
    answered: 0,
    outbound: 0
  })
  const [isStatLoading, setIsStatLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  const getStats = (): void => {
    setIsStatLoading(true)
    void handleApiCall({
      urlType: 'getStats',
      auth: true,
      cb: (res, state) => {
        if (res?.data !== undefined && state) {
          setStats(res?.data.data)
        }
        setIsStatLoading(false)
      }
    })
  }

  const states = [
    {
      title: t('Dashboard.States.Answered'),
      data: stats.answered ?? 0,
      icon: (
        <IconCustom
          type="fa"
          color="active"
          customClass="text-xl font-bold drop-shadow-actionText !text-active transition-all duration-300 ease-out group-hover:text-primary mt-[-8px]"
          name="arrow-right"
          size="small"
        />
      )
    },
    {
      type: 'divider'
    },
    {
      title: t('Dashboard.States.Missed'),
      data: stats.missed ?? 0,
      icon: (
        <IconCustom
          type="fa"
          color="danger"
          customClass="transition-all duration-300 ease-out drop-shadow-actionText  group-hover:text-primary mt-[-8px]"
          name="arrow-right"
          size="small"
        />
      )
    },
    {
      type: 'divider'
    },
    {
      title: t('Dashboard.States.Outgoing'),
      data: stats.outbound ?? 0,
      icon: (
        <IconCustom
          type="fa"
          color="accent"
          customClass="text-xl drop-shadow-actionText transition-all duration-300 ease-out group-hover:text-primary mt-[-8px]"
          name="arrow-left"
          size="small"
        />
      )
    }
  ]

  useEffect(() => {
    getStats()
  }, [])

  return (
    <TellitLoader loading={isStatLoading} customClass="h-[240px] md:h-[180px]">
      <TellitCard 
        header={t('Dashboard.States.Title')}
        type="small"
        customClass="mt-[-2px] !py-[20px] 2xl:!h-[180px] !bg-grey sm:py-[25px] md:py-[40px] lg:px-[30px] sm:!h-[150px] lg:!h-[150px] lg:py-[40px] xl:!px-[20px] 2xl:!p-[25px] p-[20px]">
        <div className="mx-auto flex max-w-[660px] flex-col items-center justify-between gap-[1.6rem] sm:w-full sm:flex-row sm:items-start md:gap-4 pl-[25px] pr-[25px]">
          {states.map((stat, idx) => {
            if (stat?.type === 'divider') {
              return (
                <div
                  className="hidden h-[65px] w-[1px] self-center bg-primary/10 sm:block"
                  key={`divider_${idx}`}
                />
              )
            }
            return (
              <div className={`max-h-[61px]`} key={`stat_${idx}`}>
                <span
                  className="stat-title mb-[10px] min-w-[130px] text-left text-primary/60 sm:mb-[16px] lg:text-sm xl:text-md"
                  id="stat-title"
                >
                  {stat?.title}
                </span>
                <div className="flex items-center justify-center gap-[5px] xl:!mt-[-6px]">
                  <div>{stat?.icon}</div>
                  <span className="text-3xl-1 font-bolder text-primary 2xl:text-4xl-1">
                    {stat?.data}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </TellitCard>
    </TellitLoader>
  )
}

export default StatCard
