import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { type RootState } from 'store'
import { UserDataUpdate, LoginUserData } from 'store/dashboard/action'
import { type RingingGroup, type OpeningHoursData } from 'store/dashboard/types'
import handleApiCall from 'api/handleApiCall'
import { PageWrapper } from 'shared'

import Left from './Left'
import Right from './Right'
// import { handleToastMessage } from 'utilities'

const initialOpeningHours: OpeningHoursData = {
  openinghours: [],
  holidays: []
}

export const getAccount = (cb: any): void => {
  void handleApiCall({
    urlType: 'getAccount',
    auth: true,
    cb: (res, state) => {
      if ((res?.data?.data as boolean) && state) {
        const formData = res?.data?.data
        localStorage.setItem('accountType', formData.unity_type)
        localStorage.setItem('account_name', formData.name)
        return cb(formData)
      } else {
        if (res?.messge !== '' && !state) {
          // handleToastMessage(res.messge)
        }
      }
    }
  })
}

function Dashboard(): JSX.Element {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const userData = useSelector((state: RootState) => state.dashboard.userData)

  const [ringingGroups, setRingingGroups] = React.useState<RingingGroup[]>([])
  const [openingHours, setOpeningHours] = useState<OpeningHoursData | null>(
    initialOpeningHours
  )
  const [isUserDataLoading, setIsUserDataLoading] = useState<boolean>(false)
  const [isRingLoading, setIsRingLoading] = useState<boolean>(false)
  const [isOpeningHoursLoading, setIsOpeningHoursLoading] =
    useState<boolean>(false)
  const [mainNumber, setMainNumber] = useState<string>('')
  // user id
  const userId = localStorage.getItem('user_id')

  const getLoggedUserData = userData?.filter(
    (user: any) => user.recordID === userId
  )

  const getContactsData = (): void => {
    setIsUserDataLoading(true)
    void handleApiCall({
      urlType: 'getContacts',
      urlParams: `&no_presences=1`,

      auth: true,
      cb: (res, state) => {
        if (res?.data !== undefined && state) {
          localStorage.setItem('user_data', JSON.stringify(res?.data))

          dispatch(UserDataUpdate({ value: res?.data }))

          if (res?.data?.length as boolean) {
            const finedLoginUser = res.data.find(
              (item: { recordID: string | null }) => item.recordID === userId
            )

            localStorage.setItem(
              'logged_in_user',
              JSON.stringify(finedLoginUser)
            )

            dispatch(LoginUserData(finedLoginUser))
          }
        } else {
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          }
        }
        setIsUserDataLoading(false)
      }
    })
  }

  const getOpeningHours = (): void => {
    setIsOpeningHoursLoading(true)
    void handleApiCall({
      urlType: 'getOpeningHours',
      auth: true,
      cb: (res, state) => {
        if (state) {
          if (res?.data?.data as boolean) {
            setOpeningHours(res?.data.data)
          } else {
            setOpeningHours(null)
          }
        }
        setIsOpeningHoursLoading(false)
      }
    })
  }

  const getRingingGroups = (): void => {
    setIsRingLoading(true)
    void handleApiCall({
      urlType: 'getRingingGroups',
      auth: true,
      urlParams: `&user_id=${userId}`,
      cb: (res, state) => {
        if (res?.data !== undefined && state) {
          setRingingGroups(res?.data.data)
        }
        setIsRingLoading(false)
      }
    })
  }

  useEffect(() => {
    getContactsData()
    getRingingGroups()
    getAccount((res: any) => {
      const formData = res
      if (formData?.caller_id?.external?.number !== undefined) {
        const userExternalNumber = formData?.caller_id?.external?.number
        // const removePlus = userExternalNumber?.replace('+', '')
        // const firstFour = removePlus?.slice(0, 4)
        // const getRest = removePlus?.slice(4)
        // const addSpaceEveryTwo = getRest?.match(/.{1,2}/g)?.join(' ')
        // const formattedUserExternalNumber = `${firstFour}-${addSpaceEveryTwo}`

        setMainNumber(userExternalNumber.replace(/^\+46/i, '0'))
      }
    })
  }, [])

  // use layout effect to get opening hours - this render before the component is rendered to the screen
  // so we can get the opening hours before the component is rendered if user not authenticated logged out before the page ui is rendered
  // don't use other api calls here
  useLayoutEffect(() => {
    getOpeningHours()
  }, [])

  return (
    <PageWrapper
      title={
        t('Dashboard.Header') +
        (getLoggedUserData[0]?.givenName !== undefined
          ? ` ${getLoggedUserData[0]?.givenName}!`
          : '')
      }
      wrapperClass="!gap-[25px]"
      description={t('Dashboard.Description')}
      titleClassName="text-3xl-1 dash-title"
      isFullWidth={true}
      leftCol={
        <Left
          ringingGroups={ringingGroups}
          openingHours={openingHours}
          mainNumber={mainNumber}
          loaders={[isUserDataLoading, isRingLoading, isOpeningHoursLoading]}
        />
      }
      rightCol={<Right />}
    />
  )
}

export default Dashboard
