import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import moment from 'moment'

import { TellitCard, DateRangePickers, IconCustom } from 'components/common'
import { columnHelper, getCallIcon } from 'components/common/TellitTable'
import { TellitSelect } from 'components/form'
import Recording from './Recording'
import PopupCustom from 'components/common/Popup'
import CdrTable from 'modules/shared/CdrTable'
import { customCalenderShortcuts } from 'components/common/DateRangePickers'
import { handleToastMessage } from 'utilities'

export type FilterInputs = {
  startDate: string | null
  endDate: string | null
  callReference: string | null
  callStatus: string
}

const Left = (): JSX.Element => {
  const { t } = useTranslation()

  const columns = [
    columnHelper.accessor('number', {
      id: 'number',
      cell: (info) => (
        <div className="flex items-center justify-start gap-1 2xl:max-w-[300px] 2xl:gap-[12.5px]">
          {getCallIcon(info.row.original.status)} {info.getValue()}
        </div>
      ),
      header: () => t('Table.Header.Number')
    }),
    columnHelper.accessor('callDate', {
      id: 'callDate',
      cell: (info) => <div>{info.getValue()}</div>,
      header: () => t('Table.Header.CallDate')
    }),
    columnHelper.accessor('callRef', {
      id: 'callRef',
      cell: (info) => <div>{info.renderValue()}</div>,
      header: () => t('Table.Header.CallRef')
    }),
    columnHelper.accessor('audio', {
      id: 'audio',
      cell: (info) => {
        if (info.getValue() === undefined) {
          return <></>
        }

        if (
          info.getValue().authorizingId !== localStorage.getItem('user_id') &&
          localStorage.getItem('priv_level') !== 'admin'
        )
          return <></>

        return info.getValue().media !== undefined ? (
          <div
            id="table-tooltip"
            data-tooltip-id="table-tooltip"
            className="group grid aspect-square h-[32px] w-[32px] place-items-center rounded-full transition-all duration-300 ease-out hover:bg-primary"
          >
            <PopupCustom
              trigger={
                <div>
                  <IconCustom
                    type="fi"
                    name="volume-2"
                    customClass="text-lg group-hover:text-secondary"
                  />
                </div>
              }
              content={<Recording mediaId={info.getValue().media} />}
            />
          </div>
        ) : (
          <></>
        )
      },
      header: () => t('Table.Header.Recording')
    })
  ]

  // const defaultData = [
  //   {
  //     status: 'missed',
  //     callDate: 'Igår 12:45',
  //     number: '+46 76-035 39 53',
  //     callRef: 'Patrik Åkerlund',
  //     audio: 'test1'
  //   },
  // ]

  const [isOpen, setIsOpen] = useState(false)
  const [filterForm, setFilterForm] = useState<FilterInputs>({
    startDate: null,
    endDate: null,
    callReference: null,
    callStatus: ''
  })
  const [selectedContact, setSelectedContact] = useState(
    t('Generic.Radio.Vaji')
  )
  const [callStatus, setCallStatus] = useState('')

  const selectFilterDateRange = (): void => {
    setIsOpen(!isOpen)
  }

  const getSelectedDateRange = (dateRange: any): void => {
    const startDate: number = dateRange[0]
    const endDate: number = dateRange[1]
    const oneMonthInMillis = 30 * 24 * 60 * 60 * 1000 // Assuming a month is 30 days

    if (
      startDate !== undefined &&
      endDate !== undefined &&
      endDate - startDate > oneMonthInMillis
    ) {
      // If the range exceeds more than a month, prevent updating the selected range
      handleToastMessage('Invalid date Selection', 'error')
      setTimeout(() => {
        setFilterForm((prev) => {
          return {
            ...prev,
            startDate: null,
            endDate: null
          }
        })
      }, 1000)
    } else {
      setFilterForm((prev) => {
        return {
          ...prev,
          startDate: dateRange[0],
          endDate: dateRange[1]
        }
      })
    }
  }

  // contact dropdown options
  const getContactOptionsForDropdown = (): any => {
    const contactData = JSON.parse(localStorage.getItem('user_data') as string)
    const contactOptions =
      contactData !== '' && contactData !== null && contactData !== undefined
        ? contactData.map((item: any) => {
            return {
              value: item.recordID,
              name: `${item.givenName} ${item.familyName}`
            }
          })
        : []
    return contactOptions
  }

  const contactData = getContactOptionsForDropdown()

  const withAllContacts = [
    { value: 'allContact', name: t('Call.Filter.DropDown.Alla') },
    // { value: 'ce2a1da13dd655008a33f1d3d89533e5', name: 'Tomas MVNO' }, // Added for admin test
    ...contactData
  ]

  const calenderDateRanges = customCalenderShortcuts(t)

  const getMatchingShortcutLabel = (): string | null => {
    for (const calenderRange of calenderDateRanges) {
      if (
        filterForm.startDate != null &&
        moment(new Date(filterForm.startDate)).format('MM-DD') ===
          moment(calenderRange.dateRange[0]).format('MM-DD') && // check start date
        filterForm.endDate != null &&
        moment(new Date(filterForm.endDate)).format('MM-DD') ===
          moment(calenderRange.dateRange[1]).format('MM-DD') // check end date
      ) {
        return calenderRange.label.toLowerCase()
      }
    }
    return t('Generic.Calendar.ThisWeek').toLowerCase()
  }

  return (
    <div className="flex flex-col gap-[30px]">
      <TellitCard
        header={t('Call.Right.Title')}
        description={t('Call.FilterSection.Description')}
        customClass="filter-wrapper !px-[24px] !pt-[24px] !pb-[25px] 2xl:max-w-[754px] !rounded-smaller"
      >
        <div className="call-filter flex w-full flex-row items-start justify-between gap-[13px]">
          {/* calender start */}
          <div
            onClick={selectFilterDateRange}
            className="calender  item group flex h-[45px] w-[285px]   flex-auto cursor-pointer items-center justify-between gap-2 rounded-small bg-primary pl-[10px]  pr-[12px] 2xl:gap-[8px]"
          >
            <IconCustom
              type="lu"
              name="chevron-left"
              color="white"
              customClass="text-3xl group-hover:opacity-80"
            />
            <div className="header mt-[2px] truncate text-left text-lg text-secondary group-hover:opacity-80">
              {t('Call.Calender.Label.Visar')} {getMatchingShortcutLabel()}
            </div>
            <IconCustom
              type="lu"
              name="chevron-right"
              color="white"
              customClass="text-right text-3xl group-hover:opacity-80"
            />
          </div>

          <DateRangePickers
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            value={[
              filterForm?.startDate != null
                ? new Date(filterForm.startDate)
                : null,
              filterForm?.endDate != null ? new Date(filterForm.endDate) : null
            ]}
            label=""
            getSelectedDateRange={getSelectedDateRange}
            selectFilterDateRange={selectFilterDateRange}
          />
          {/* calender end */}

          <TellitSelect
            placeholder={t('Samtal.Placeholder.VäljSamtalsreferens')}
            onChange={(option: any) => {
              setSelectedContact(option)
              setFilterForm((prev) => {
                return {
                  ...prev,
                  callReference: option.value
                }
              })
            }}
            customClass="!h-[45px] call-select-wrapper"
            customPrefix="call-select-responsive"
            wrapperClass="flex-auto w-[207px] w-auto !h-[45px] grid  select call-select"
            options={withAllContacts}
            value={selectedContact}
          />

          <TellitSelect
            placeholder={t('Samtal.Placeholder.Samtalsstatus')}
            onChange={(option: any) => {
              setCallStatus(option)
              setFilterForm((prev) => {
                return {
                  ...prev,
                  callStatus: option.value
                }
              })
            }}
            customClass="!h-[45px] call-select-wrapper"
            customPrefix="colorize call-select-responsive"
            wrapperClass="flex-auto w-[180px] w-auto !h-[45px] grid select call-select"
            options={[
              { value: '1', name: t('Call.Filter.DropDown.AllaSamtal') },
              {
                value: 'missed',
                name: t('Call.Filter.DropDown.MissadeSamtal')
              },
              { value: '3', name: t('Call.Filter.DropDown.BevaradeSamtal') },
              { value: '4', name: t('Call.Filter.DropDown.UtgåendeSamtal') }
            ]}
            value={callStatus}
          />
        </div>
      </TellitCard>

      <TellitCard
        type="medium"
        header={t('Call.Table.Header')}
        customClass="overflow-y-auto hide-scrollbar 2xl:max-w-[754px] !rounded-smaller call-table-card"
      >
        <CdrTable columns={columns} filter={filterForm} hasPagination />
      </TellitCard>
    </div>
  )
}

export default Left
