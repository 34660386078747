import { useTranslation } from 'react-i18next'

import { LiaQrcodeSolid } from 'react-icons/lia'

// import TabSwitch from 'components/common/TabSwitch'
import TellitButton from 'components/common/TellitButton'
import BankIdLogo from './BankIdLogo'
import { useEffect, useState } from 'react'

type StepOneProps = {
  handleClick: (step: number) => void
  handleFederatedLogin: (cb?: any) => void
  setStartPolling: (startPolling: boolean) => void
  loading?: boolean
}

const StepOne = ({
  handleClick,
  handleFederatedLogin,
  setStartPolling,
  loading
}: StepOneProps): JSX.Element => {
  const { t } = useTranslation()
  const [openBankIDUrl, setOpenBankIDUrl] = useState<string>('')
  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleWindowSizeChange(): void {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  useEffect(() => {
    handleFederatedLogin({
      isSuccessMobileBankId: (res: boolean, autoStartToken?: string) => {
        if (res) {
          setOpenBankIDUrl(
            `bankid:///?autostarttoken=${autoStartToken}&redirect=null`
          )
          setStartPolling(false)
        }
      }
    })
  }, [])

  return (
    <div className="w-full 2xl:!mt-[-5px]">
      {/* header */}
      <h3 className="mb-[2rem] text-center text-3xl-1 font-bold leading-none text-primary 2xl:mb-[29px]">
        {t('Login.Title')}
      </h3>

      <div className="">
        {/* <TabSwitch
          labels={[
            `${t('Login.Tab.BankID')}`,
            `${t('Login.Tab.Anvandarnamn')}`
          ]}
        /> */}
        <div className="mb-3 mt-8 flex w-full flex-col flex-wrap items-center justify-center gap-[22px] self-center">
          <TellitButton
            disabled={loading}
            labelClass="!text-loginCream font-bold tracking-[0.96px] !text-md group-hover:!text-secondary duration-300 ease-out transition-al"
            customClass="bg-primary shadow-loginQrBtn duration-300 ease-out transition-all flex group  hover:!text-secondary flex-row-reverse justify-between items-center px-[27px] rounded-4xl lg:h-[56px]"
            label={t('Login.Label.MobiltBankID')}
            icon={
              <LiaQrcodeSolid className="transition-al !text-4xl-1 font-bold tracking-[0.96px] !text-loginCream duration-300 ease-out group-hover:!text-secondary" />
            }
            onClick={() => {
              handleClick(1)
              handleFederatedLogin({
                isSuccessMobileBankId: (
                  res: boolean,
                  autoStartToken?: string
                ) => {
                  if (res) {
                    /* empty */
                  }
                }
              })
            }}
          />
          <TellitButton
            href={openBankIDUrl}
            disabled={!isMobile || loading}
            customClass={`disabled:bg-grey disabled:hover:bg-grey bg-secondary group flex items-center flex-row-reverse shadow-buttonSecondary justify-between px-[27px] rounded-4xl lg:h-[56px] hover:bg-primary`}
            labelClass="!text-md font-bold tracking-[0.96px] text-primary group-disabled:hover:text-grey group-hover:text-white duration-300 ease-out transition-all"
            label={t('Login.Label.ÖppnaBankID')}
            icon={<BankIdLogo />}
            onClick={() => {
              handleClick(2)
              setStartPolling(true)
            }}
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}

export default StepOne
