import { useTranslation } from 'react-i18next'
import { IconCustom } from 'components/common'

type IActionBtn = {
  action: () => void
  className?: string
  childClass?: string
}

const ActionBtn = ({
  action,
  className,
  childClass = ''
}: IActionBtn): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div
      className={`group flex w-fit cursor-pointer items-center justify-center  ${className}`}
      onClick={action}
    >
      <span
        className={`mt-[2px] text-sm font-semibold uppercase tracking-[0.56px] text-white drop-shadow-actionText transition-all duration-300 ease-out group-hover:text-primary ${childClass}`}
      >
        {t('Dashboard.ActionButton')}
      </span>
      <IconCustom
        type="fa6"
        color="white"
        customClass={`ml-[5px] text-xl drop-shadow-actionText transition-all duration-300 ease-out group-hover:text-primary ${childClass}`}
        name="arrow-right-long"
        size="small"
      />
    </div>
  )
}

export default ActionBtn
