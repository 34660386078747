import { TellitButton } from 'components/common'
import Typography, {
  TypographySizes,
  TypographyWeights
} from 'components/common/Typography'
import { type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type PageWrapperProps = {
  children?: ReactNode
  title?: string
  description?: string | ReactNode
  titleClassName?: string
  leftCol?: ReactNode
  rightCol?: ReactNode
  wrapperClass?: string
  isFullWidth?: boolean
}

//  note - children is not used - children is generic name , if u use then we cant pass it as prop
const PageWrapper = ({
  children,
  title = '',
  description = '',
  titleClassName,
  leftCol,
  rightCol,
  wrapperClass,
  isFullWidth
}: PageWrapperProps): JSX.Element => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  if (leftCol !== undefined || rightCol !== undefined) {
    return (
      <div
        className={`main-page-wrapper grid gap-[37px] lg:grid-cols-2 ${wrapperClass}`}
      >
        <div
          className={`${
            (isFullWidth as boolean) ? 'ultra-dash-width' : 'wrapper-left'
          }`}
        >
          {leftCol}
        </div>
        <div className="wrapper-right">
          {(title !== '' || description !== '') && (
            <div className="min-h-32 w-full">
              <div className="pt-4 2xl:pl-[20px] 2xl:pt-[40px]">
                <Typography
                  size={TypographySizes['2xl-2']}
                  weight={TypographyWeights.semibold}
                  customClasses={`mb-[12px] dash-title leading-none ${titleClassName}`}
                >
                  {title}
                </Typography>
                <Typography
                  size={TypographySizes['2xl']}
                  opacity={0.5}
                  customClasses="dash-description break-word 2xl:pr-[230px]"
                >
                  {description}
                </Typography>
              </div>
            </div>
          )}
          {rightCol}

          {rightCol === undefined &&
            localStorage.getItem('accountType') !== 'convertedfree' && (
              <div className="bottom-[34px] mx-auto mt-6 w-auto max-w-[264px] lg:absolute lg:right-[26px]">
                <TellitButton
                  label="UPPGRADERA"
                  labelClass="!text-md font-bold tracking-[0.96px] text-secondary"
                  customClass="mb-[6px] !bg-primary !rounded-4xl !min-h-[56px] 2xl:min-w-[264px] w-[264px] max-w-[264px] z-[4] shadow-button"
                  onClick={() => {
                    localStorage.setItem('page_url', window.location.href)
                    navigate('/contact', { state: { updateProVersion: true } })
                  }}
                />
                <Typography
                  size={TypographySizes['sm-1']}
                  customClasses="text-center"
                >
                  {t('PageWrapper.Right.Pro.Description')}
                </Typography>
              </div>
            )}
        </div>
      </div>
    )
  }

  return <>{children}</>
}

export default PageWrapper
