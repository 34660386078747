import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Base64 } from 'js-base64'
import { useNavigate } from 'react-router-dom'

import { type federatedLoginTypes } from 'store/dashboard/types'

import TellitLoader from 'components/common/TellitLoader'
import NumberBadge from 'components/common/NumberBadge'
import handleApiCall from 'api/handleApiCall'

// import { handleToastMessage } from '../../utilities'
// import BankIdLogo from './BankIdLogo'
// import { TellitButton } from 'components/common'

type StepQRProps = {
  handleClick: (step: number) => void
  loading: boolean
  federated: federatedLoginTypes
  setFederated: (federated: federatedLoginTypes) => void
  startPolling: boolean
  setStartPolling: (startPolling: boolean) => void
  handleFederatedLogin: (cb?: any) => void
}

const StepQR = ({
  handleClick,
  loading,
  federated,
  setFederated,
  startPolling,
  setStartPolling,
  handleFederatedLogin
}: StepQRProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // const [openBankIDUrl, setOpenBankIDUrl] = useState<string>('')
  const [shouldLoad, setShouldLoad] = useState<boolean>(false)
  const controller = new AbortController()
  const [openAccountPicker, setOpenAccountPicker] = useState<boolean>(false)
  const [accounts, setAccounts] = useState<any[]>([])

  const handleGetSession = (): void => {
    const form = new FormData()
    void handleApiCall({
      urlType: 'getSession',
      urlParams: `?grandidsession=${federated?.sessionId}`,
      data: form,
      signal: controller.signal,
      cb: (res, state) => {
        if (state) {
          if (res?.data?.grandidObject as boolean) {
            if (res?.data?.grandidObject?.message.hintCode !== 'userSign') {
              setFederated({
                ...federated,
                QRCode: res?.data?.grandidObject?.QRCode
              })
            } else {
              setShouldLoad(true)
            }
          }
          // success
          if (res?.data?.count > 1) {
            setStartPolling(false)
            controller.abort()
            setAccounts(res?.data?.accounts)
            setOpenAccountPicker(true)
            // navigate('/select', { replace: true })
          }
          if (res?.data?.status === 'success' && res?.data?.count === 1) {
            const account = res?.data?.accounts[0]
            localStorage.setItem('auth_token', account.user_token)
            localStorage.setItem('user_id', account.user_id)
            localStorage.setItem('account_id', account.account_id)
            localStorage.setItem('credentials', account.credentials)
            localStorage.setItem('priv_level', account.priv_level)
            localStorage.setItem('account_name', account.account_name)

            setStartPolling(false)
            controller.abort()
            navigate('/dashboard', { replace: true })
          }
        } else {
          setStartPolling(false)
          controller.abort()
          console.log('error: ', res.message)
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
            setTimeout(() => {
              handleClick(0)
            }, 2000)
          }
        }
      }
    })
  }

  useEffect(() => {
    if (startPolling) {
      const pollInterval = 2000 // 3 seconds
      const getSessionInterval = setInterval(() => {
        handleGetSession()
      }, pollInterval)
      return () => {
        clearInterval(getSessionInterval)
      }
    }
  }, [startPolling])

  const steps = [
    t('Login.Label.RiktaKameran'),
    t('Login.Label.TryckPå'),
    t('Login.Label.RiktaKameran')
  ]

  return (
    <div className="w-full">
      {!openAccountPicker ? (
        <>
          <div className="mb-[26px] flex w-full flex-col flex-wrap justify-center gap-[29px] pt-[21px]">
            {steps.map((step, idx) => (
              <div className="flex items-center gap-[14px]" key={`step_${idx}`}>
                <NumberBadge count={idx + 1} />
                <span className="text-lg text-primary">{step}</span>
              </div>
            ))}
          </div>
          <div>
            {/* <TellitButton
            href={openBankIDUrl}
            disabled={loading}
            customClass={`disabled:bg-grey disabled:hover:bg-grey bg-secondary group flex items-center flex-row-reverse shadow-buttonSecondary justify-between px-[27px] rounded-4xl lg:h-[56px] hover:bg-primary`}
            labelClass="!text-md font-bold tracking-[0.96px] text-primary group-disabled:hover:text-grey group-hover:text-white duration-300 ease-out transition-all"
            label={t('Login.Label.ÖppnaBankID')}
            icon={<BankIdLogo />}
            onClick={() => {
              handleClick(2)
            }}
            loading={loading}
          /> */}
            <TellitLoader loading={shouldLoad} customClass="min-h-[200px]">
              <div
                className="mx-auto mt-[12px] aspect-square h-[200px] w-[200]"
                dangerouslySetInnerHTML={
                  federated.QRCode != null
                    ? { __html: Base64.decode(federated.QRCode) }
                    : { __html: '' }
                }
              />
            </TellitLoader>
            {shouldLoad && (
              <div className="text-center text-lg text-primary">
                {t('Login.Label.SignInBankId')}
              </div>
            )}
          </div>
          <div
            onClick={() => {
              handleClick(0)
              controller?.abort()
              setStartPolling(false)
            }}
            className="block cursor-pointer text-center text-lg text-secondary underline hover:text-primary"
          >
            {t('Login.Label.AvbrytInloggning')}
          </div>
        </>
      ) : (
        <>
          <div className="fixed inset-0 z-50 flex flex-col items-center justify-center p-4">
            <div className="mb-4 text-center text-lg text-primary">
              {t('Login.Label.SelectAccount')}
            </div>
            <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-lg outline outline-1 outline-gray-200">
              {accounts.map((item) => (
                <div
                  key={item.account_id}
                  onClick={() => {
                    localStorage.setItem('auth_token', item.user_token)
                    localStorage.setItem('user_id', item.user_id)
                    localStorage.setItem('account_id', item.account_id)
                    localStorage.setItem('credentials', item.credentials)
                    localStorage.setItem('priv_level', item.priv_level)
                    localStorage.setItem('account_name', item.account_name)

                    navigate('/dashboard', { replace: true })
                  }}
                  className="flex cursor-pointer items-center justify-center border-b border-gray-300 p-2 last:border-b-0"
                >
                  <strong>{item.account_name}</strong>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default StepQR
