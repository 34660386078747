import { PageWrapper } from 'shared'
import { useTranslation } from 'react-i18next'
import Left from './Left'

const Call = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <PageWrapper
      title={t('Call.Right.Title')}
      description={t('Call.Right.Description')}
      leftCol={<Left />}
    />
  )
}

export default Call
