import React from 'react'
import { BiPhoneIncoming } from 'react-icons/bi'
import { BsSpeedometer } from 'react-icons/bs'
import {
  FaArrowRight,
  FaArrowLeft,
  FaRegClock,
  FaRegEnvelope
} from 'react-icons/fa'
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6'
import { MdArrowForward, MdOutlineInfo } from 'react-icons/md'
import { FiUsers, FiUser, FiPhoneCall, FiVolume2 } from 'react-icons/fi'
import {
  LuChevronFirst,
  LuChevronLeft,
  LuChevronRight,
  LuChevronLast
} from 'react-icons/lu'
import { LiaArrowRightSolid } from 'react-icons/lia'

type IconSizeTypes =
  | 'extraSmall'
  | 'small'
  | 'base'
  | 'medium'
  | 'large'
  | 'extraLarge'
  | string

const IconLibrary = {
  fa: {
    icons: {
      'arrow-right': {
        src: FaArrowRight
      },
      'arrow-left': {
        src: FaArrowLeft
      },
      clock: {
        src: FaRegClock
      },
      envelope: {
        src: FaRegEnvelope
      }
    }
  },
  fa6: {
    icons: {
      'arrow-left-long': {
        src: FaArrowLeftLong
      },
      'arrow-right-long': {
        src: FaArrowRightLong
      }
    }
  },
  lia: {
    icons: {
      'arrow-right-long': {
        src: LiaArrowRightSolid
      }
    }
  },
  bs: {
    icons: {
      'speedo-meter': {
        src: BsSpeedometer
      }
    }
  },
  md: {
    icons: {
      'arrow-forward': {
        src: MdArrowForward
      },
      'info-outline': {
        src: MdOutlineInfo
      }
    }
  },
  fi: {
    icons: {
      users: {
        src: FiUsers
      },

      user: {
        src: FiUser
      },
      'phone-call': {
        src: FiPhoneCall
      },
      'volume-2': {
        src: FiVolume2
      }
    }
  },
  bi: {
    icons: {
      'phone-incoming': {
        src: BiPhoneIncoming
      }
    }
  },
  lu: {
    icons: {
      'chevron-first': {
        src: LuChevronFirst
      },
      'chevron-left': {
        src: LuChevronLeft
      },
      'chevron-right': {
        src: LuChevronRight
      },
      'chevron-last': {
        src: LuChevronLast
      }
    }
  }
}

export type TBIconType = keyof typeof IconLibrary

type IIconProps = {
  type: TBIconType | string
  name?: string
  size?: IconSizeTypes
  color?: string
  customClass?: string
  id?: string
}

const getIconWidth = (size: string): { width: string; height: string } => {
  let widthIcon = { width: '1.5rem', height: '1.5rem' }
  switch (size) {
    case 'extra-small':
      widthIcon = { width: '0.5rem', height: '0.5rem' }
      break
    case 'small':
      widthIcon = { width: '1rem', height: '1rem' }
      break
    case '1.25':
      widthIcon = { width: '1.25rem', height: '1.25rem' }
      break
    case 'medium':
      widthIcon = { width: '2.5rem', height: '2rem' }
      break
    case 'inter-medium':
      widthIcon = { width: '2.5rem', height: '2.5rem' }
      break
    case 'large':
      widthIcon = { width: '3rem', height: '3rem' }
      break
    default:
      widthIcon = { width: '1.5rem', height: '1.5rem' }
  }
  return widthIcon
}

const IconCustom: React.FC<IIconProps> = ({
  type,
  name,
  size = 'base',
  color = 'primary',
  customClass,
  id
}) => {
  const iconComponent = IconLibrary[type as TBIconType].icons
  type IconName = (typeof iconComponent)[keyof typeof iconComponent]

  const SourceComponent = iconComponent[name as IconName] as any

  const classModified = `text-${color} ${customClass}`
  return (
    <SourceComponent.src
      className={classModified}
      id={id}
      style={{
        fontSize: getIconWidth(size).width
      }}
    />
  )
}

export default IconCustom
