import { useTranslation } from 'react-i18next'

import { TellitCard, ActionButton, IconCustom } from 'components/common'
import { TellitRadio, TellitRadioSwitch } from 'components/form'

import Typography, {
  TypographySizes,
  TypographyWeights,
  TypographyColors
} from 'components/common/Typography'

type ILeftProps = {
  updateRingingGroup: () => void
  ringingGroups: any
  setRingingGroups: (value: any) => void
  isSubmitted: boolean
  setIsSubmitted: (value: boolean) => void
  mainNumber?: string
  disabledUntilSubmit?: boolean
}

const Left = ({
  updateRingingGroup,
  ringingGroups,
  setRingingGroups,
  isSubmitted,
  setIsSubmitted,
  mainNumber = '',
  disabledUntilSubmit = false
}: ILeftProps): JSX.Element => {
  const { t } = useTranslation()

  const canEdit = localStorage.getItem('priv_level') === 'admin'

  const getValueForSwitch =
    ringingGroups?.[0]?.strategy === 'single'
      ? 1
      : ringingGroups?.[0]?.strategy === 'simultaneous'
        ? 0
        : -1

  const handleStrategyChange = (id: number): void => {
    const ringGroupDuplicate = [...ringingGroups]

    if (ringGroupDuplicate[0]?.strategy !== undefined) {
      if (isSubmitted) {
        setIsSubmitted(false)
      }

      ringGroupDuplicate[0].strategy =
        id === 0 ? 'simultaneous' : id === 1 ? 'single' : ''

      setRingingGroups(ringGroupDuplicate)
    }
  }

  const handleRepeatsChange = (id: number): void => {
    if (isSubmitted) {
      setIsSubmitted(false)
    }

    const ringGroupDuplicate = [...ringingGroups]

    ringGroupDuplicate[0].repeats = id

    setRingingGroups(ringGroupDuplicate)
  }

  const switches = [
    {
      icon: <IconCustom type="fi" name="users" />,
      label: t('RingSchema.RadioSwitch.Label.Simultaneous')
    },
    {
      icon: <IconCustom type="fi" name="user" />,
      label: t('RingSchema.RadioSwitch.Label.Single'),
      toolTip: t('RingSchema.RadioSwitch.Tooltip.Single')
    }
  ]

  const radioGroup = [
    {
      name: 'unlimited',
      id: 9999,
      label: t('RingSchema.Label.Radio1'),
      checked: ringingGroups?.[0]?.repeats === 9999
    },
    {
      name: 'once',
      id: 1,
      label: t('RingSchema.Label.Radio2'),
      checked: ringingGroups?.[0]?.repeats === 1
    }
  ]

  return (
    <TellitCard
      header={`${t('RingSchema.Title')} ${
        mainNumber !== '' ? `${t('Label.For')} ${mainNumber}` : ''
      }`}
      customClass="2xl:max-w-[778px] ringschema-media-css"
    >
      <Typography
        weight={TypographyWeights.bolder}
        size={TypographySizes['2xl-2']}
        customClasses="!font-bolder -mt-[2px]"
      >
        {t('RingSchema.Label.RingType')}
      </Typography>

      <div className="mt-[14px] border-b-[1px] pb-[36px]">
        <TellitRadioSwitch
          disabled={!canEdit}
          switches={switches}
          onChange={(id) => {
            handleStrategyChange(id)
          }}
          defaultValue={getValueForSwitch}
        />
      </div>

      <div className=" border-b-[1px] pb-[14px] pt-[22px]">
        <Typography
          weight={TypographyWeights.bold}
          size={TypographySizes['2xl-2']}
          color={TypographyColors.primary}
          customClasses={`mb-[12px]`}
        >
          {t('RingSchema.Label.RadioHeader')}
        </Typography>

        {radioGroup.map((radio, idx) => (
          <TellitRadio
            disabled={!canEdit}
            key={`repeats_${idx}`}
            id={String(radio.id)}
            name={radio.name}
            checked={radio.checked}
            onchange={() => {
              handleRepeatsChange(radio.id)
            }}
            label={radio.label}
          />
        ))}
      </div>

      <div className="mb-0 mt-[30px] flex w-full flex-col items-center justify-between justify-items-center gap-3 self-center text-center sm:flex-row">
        <Typography
          weight={TypographyWeights.normal}
          size={TypographySizes.sm}
          color={TypographyColors.primary}
          opacity={0.6}
          customClasses="ml-[5px] w-auto mb-3 sm:mb-0 sm:text-start"
        >
          {canEdit && localStorage.getItem('accountType') !== 'convertedfree'
            ? t('RingSchema.Label.Description')
            : !canEdit
              ? t('RingSchema.Label.DescriptionReadOnly')
              : ''}
        </Typography>

        <ActionButton
          label={
            isSubmitted
              ? t('Generic.Button.Sparat')
              : t('Generic.Button.Submit')
          }
          onClick={() => {
            updateRingingGroup()
          }}
          isSubmitted={isSubmitted}
          disabled={disabledUntilSubmit || !canEdit}
        />
      </div>
    </TellitCard>
  )
}

export default Left
