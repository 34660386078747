import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from './shared/Layout'
import Login from './modules/login'
import Profile from './modules/profile'
import Dashboard from './modules/dashboard'
import Users from './modules/users/index'
import RingSchema from './modules/ring-schema'
import OpeningHours from './modules/opening-hours'
import HandleAuth from 'shared/HandleAuth'
import Contact from 'modules/contact'
import Call from 'modules/call'
import NotFound from 'components/common/NotFound'
import 'styles/App.css'
import 'styles/Media.css'
import 'react-circular-progressbar/dist/styles.css'
import { useEffect } from 'react'
import { handleToastMessage } from 'utilities'
import { useTranslation } from 'react-i18next'
import handleApiCall from 'api/handleApiCall'

type DecodedToken = {
  iss: string
  identity_sig: string
  account_id: string
  method: string
  exp: number
}

const clearLocalStorage = (): void => {
  localStorage.removeItem('account_id')
  localStorage.removeItem('user_id')
  localStorage.removeItem('auth_token')
  localStorage.removeItem('user_data')
  localStorage.removeItem('logged_in_user')
  localStorage.removeItem('pagination')
  localStorage.removeItem('next_start_key')
  localStorage.removeItem('credentials')
  localStorage.removeItem('account_name')
}

function App(): JSX.Element {
  let activeTimeout = false
  let gettingToken = false

  const { t } = useTranslation()
  function decodeToken(token: string | null): DecodedToken | null {
    if (token === null) return null

    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]))
      return decodedToken
    } catch (error) {
      console.error('Error decoding token:', error)
      return null
    }
  }

  const requestAuthTokenCheck = (): void => {
    if (!activeTimeout) return
    if (gettingToken) return
    // console.log('Requesting token check.')

    const accountName = localStorage.getItem('account_name')
    const credentials = localStorage.getItem('credentials')
    gettingToken = true
    void handleApiCall({
      urlType: 'getAuthToken',
      urlParams: `&account_name=${accountName}&auth=${credentials}`,
      cb: (res, state) => {
        if (state) {
          localStorage.setItem('auth_token', res?.data?.auth_token)
          // console.log('Token refreshed. ', res?.data?.auth_token)
        }
      }
    })
  }

  useEffect(() => {
    let isInitialLoad = true // Track initial load

    const checkTokenExpiration = (): void => {
      const decodedToken = decodeToken(localStorage.getItem('auth_token'))
      if (decodedToken !== null) {
        const hasExpired = decodedToken.exp * 1000 - Date.now() <= 0

        // console.log(
        //   'Token expiration delta:',
        //   decodedToken.exp * 1000 - Date.now()
        // )

        if (hasExpired) {
          if (isInitialLoad) {
            clearLocalStorage()
            isInitialLoad = false
            window.location.replace('/login')
          } else {
            if (activeTimeout) return
            // console.log('Token expired. starting session timeout.')
            activeTimeout = true
            setTimeout(
              () => {
                activeTimeout = false
                gettingToken = false
                const decodedToken = decodeToken(
                  localStorage.getItem('auth_token')
                )
                if (decodedToken !== null) {
                  const hasExpired = decodedToken.exp * 1000 - Date.now() <= 0
                  if (hasExpired) {
                    clearLocalStorage()
                    // console.log('Session expired. Redirecting to login page.')
                    window.location.replace('/login')
                    handleToastMessage(t('Msg.AuthTokenExpired'))
                  } else {
                    // console.log('Session extended.')
                  }
                }
              },
              15 * 60 * 1000
            ) // 15 minutes in milliseconds
          }
        }
      }
      if (isInitialLoad) isInitialLoad = false
    }

    checkTokenExpiration()

    const intervalId = setInterval(checkTokenExpiration, 30000) // Check every 30 seconds

    document.addEventListener('mousemove', requestAuthTokenCheck)
    document.addEventListener('keypress', requestAuthTokenCheck)
    document.addEventListener('click', requestAuthTokenCheck)

    return () => {
      clearInterval(intervalId)
      document.removeEventListener('mousemove', requestAuthTokenCheck)
      document.removeEventListener('keypress', requestAuthTokenCheck)
      document.removeEventListener('click', requestAuthTokenCheck)
    }
  }, [])

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<HandleAuth />}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" Component={Dashboard} />
          <Route path="users" Component={Users} />
          <Route path="profile" Component={Profile} />
          {/* <Route path="/users/edit" Component={Users} /> */}
          <Route path="ringschema" Component={RingSchema} />
          <Route path="opening-hours" Component={OpeningHours} />
          <Route path="call" Component={Call} />
          <Route path="contact" Component={Contact} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="login" replace />} />
    </Routes>
  )
}

export default App
