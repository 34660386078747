import { type ChangeEvent, useState, type FocusEvent } from 'react'
import { useTranslation } from 'react-i18next'

type TellitInputProps = {
  label?: string
  id?: string
  name?: string
  value?: string | number
  customClass?: string
  alignCenter?: boolean
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  errorMsg?: string
  disabled?: boolean
  wrapperClass?: string
  validationType?: string
  enableTypeValidation?: boolean
  enableErrorMsg?: boolean
  setError?: (value: boolean) => void
  required?: boolean
  rows?: number
}

const TellitTextArea = ({
  label,
  id,
  name,
  value,
  customClass,
  onChange = () => {},
  onBlur,
  alignCenter = false,
  placeholder,
  errorMsg = '',
  disabled = false,
  wrapperClass,
  validationType,
  enableErrorMsg,
  enableTypeValidation = true,
  setError,
  required,
  rows = 6
}: TellitInputProps): JSX.Element => {
  const { t } = useTranslation()

  const [builtInErrorMsg, setBuiltInErrorMsg] = useState('')

  errorMsg =
    (enableErrorMsg as boolean) && (required as boolean)
      ? `${label} ${t('Msg.IsRequired')}`
      : ''

  const validateInput = (e: React.ChangeEvent<HTMLInputElement>): null => {
    const { value } = e.target

    if (required === true && value.length === 0) {
      setBuiltInErrorMsg(`${label} ${t('Msg.IsRequired')}}`)
      setError?.(true)
    } else {
      setBuiltInErrorMsg('')
      setError?.(false)
    }

    if (value.length > 255) {
      setBuiltInErrorMsg(t('Msg.MaxLength'))
    } else {
      setBuiltInErrorMsg('')
    }

    if (validationType === 'personalNumber') {
      if (value.length < 10 || value.length > 12) {
        setBuiltInErrorMsg(t('Msg.EnterValid.PersonNumber'))
        setError?.(true)
      } else {
        setBuiltInErrorMsg('')
        setError?.(false)
      }
    }

    if (validationType === 'mobile') {
      // Regular expression for valid Swedish mobile phone numbers
      const swedishMobileRegex = /^(0[1-9][0-9]{8}|(\+46|0046)[1-9][0-9]{8})$/

      if (!swedishMobileRegex.test(value)) {
        setBuiltInErrorMsg(t('Msg.EnterValidMobile'))
        setError?.(true)
      } else {
        setBuiltInErrorMsg('')
        setError?.(false)
      }
    }

    if (validationType === 'newNumber' && required === true) {
      if (value.length === 0) {
        setBuiltInErrorMsg(t('Msg.EnterNewNumber'))
        setError?.(true)
      } else {
        setBuiltInErrorMsg('')
        setError?.(false)
      }
    }

    return null
  }

  return (
    <div className={`flex flex-col ${wrapperClass}`}>
      <label
        htmlFor={id}
        className={`relative z-[1]  mb-[-0.57rem] ml-1 w-fit rounded-xl px-1.5 text-xxs font-normal normal-case tracking-[0.72px] text-primary shadow-label ${
          disabled ? 'bg-inactive' : 'bg-white'
        }`}
      >
        {label}
      </label>

      <textarea
        id={id}
        rows={rows}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={(e: any) => {
          if (enableTypeValidation) {
            validateInput(e)
          }
          onBlur !== null && onBlur !== undefined && onBlur(e)
        }}
        placeholder={placeholder}
        className={`max-h-[148px] w-full !rounded-smaller border border-gray-100 bg-white p-4 text-sm  font-normal text-primary shadow-input !outline-none !outline-offset-0 disabled:!bg-inactive disabled:shadow-none ${
          alignCenter ? 'text-center' : 'text-left'
        } ${customClass}`}
        disabled={disabled}
      />

      {((enableErrorMsg as boolean) ||
        ((required as boolean) && builtInErrorMsg !== '')) && (
        <p className="text-error mt-1 text-center text-[0.7rem]  font-normal text-danger-dark-xl ">
          {errorMsg.length > 0 ? errorMsg : builtInErrorMsg}
        </p>
      )}
    </div>
  )
}

export default TellitTextArea
