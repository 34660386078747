/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getUserDataList } from 'helpers'
import { addTimeFromMidnihgt } from 'utilities/timeConverts'

import {
  TellitAvatar,
  TellitCard,
  TellitDot,
  TellitLoader
} from 'components/common'
import { ColorCard } from 'components/cards'
import { ActionBtn, StatCard, Progress } from 'modules/dashboard/sections'

import {
  type RingingGroup,
  type OpeningHoursData,
  Person
} from 'store/dashboard/types'

type ILeftProps = {
  ringingGroups: RingingGroup[]
  openingHours: OpeningHoursData | null
  loaders?: any
  mainNumber?: string
}

const Left = ({
  ringingGroups,
  openingHours,
  loaders,
  mainNumber = ''
}: ILeftProps): JSX.Element => {
  const navigate = useNavigate()
  const usersList = useSelector((state: RootState) => state.dashboard.userData)
  const [isUserDataLoading, isRingLoading, isOpeningHoursLoading] = loaders

  const [userList, setUserList] = useState<Person[]>([])
  const { t } = useTranslation()

  // const testData = [
  //   { start: 0, end: 0 }, // Dygnet runt,
  //   { start: 0, end: 1 },  // 00:00-00:00,
  //   { start: 0, end: 60 },  // 00:00-00:01,
  //   { start: 28800, end: 61200 },  // 08:00-17:00,
  //   { start: 0, end: 86400 },  // 00:00-00:00,
  //   { start: 01, end: 86400 },  // 00:00-00:00,
  //   { start: 60, end: 86400 },  // 00:01-00:00,
  //   { start: 86400, end: 86400 },  // 00:00-00:00,
  //   { start: 86300, end: 86390 },  // 23:58-23:59,
  //   { start: 34300, end: 76790 },  // 09:31-21:19,
  //   { start: 0, end: 1000000 },  // 00:00-13:46,
  // ]

  // test object
  // const openingHours = {
  //   openinghours: [
  //     {
  //       name: 'MainOpenHours',
  //       type: 'main_weekdays',
  //       wdays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
  //       time_window_start: 0,
  //       time_window_stop: 1000000
  //     }
  //   ]
  // }

  const formattedOpeningHours = `${
    openingHours?.openinghours[0]?.time_window_start !== undefined &&
    addTimeFromMidnihgt(
      openingHours?.openinghours[0]?.time_window_start,
      'seconds'
    )
  }-${
    openingHours?.openinghours[0]?.time_window_stop !== undefined &&
    addTimeFromMidnihgt(
      openingHours?.openinghours[0]?.time_window_stop,
      'seconds'
    )
  }`

  const timeStampValidityCheck = (): string => {
    if (
      (openingHours?.openinghours[0]?.time_window_start === undefined &&
        openingHours?.openinghours[0]?.time_window_stop === undefined) ||
      (openingHours?.openinghours[0]?.time_window_start === null &&
        openingHours?.openinghours[0]?.time_window_stop === null) ||
      (openingHours?.openinghours[0]?.time_window_start === 0 &&
        openingHours?.openinghours[0]?.time_window_stop === 0)
    ) {
      return 'Dygnet runt'
    }
    return formattedOpeningHours
  }

  const sideCards = [
    {
      color: 'purple',
      title: t('OpeningHours.Title'),
      data: timeStampValidityCheck(),
      action: () => {
        navigate('/opening-hours', { replace: true })
      }
      // bgIcon: (
      //   <FaRegClock className="text-[230px] mt-[-195px] ml-[167px] mix-blend-normal text-inactive/50 bg-clip-text" />
      // ),
    },
    {
      color: 'green',
      title: t('RingSchema.Title'),
      data:
        ringingGroups[0]?.strategy === 'single'
          ? t('RingSchema.RadioSwitch.Label.Single')
          : ringingGroups[0]?.strategy === 'simultaneous'
            ? t('RingSchema.RadioSwitch.Label.Simultaneous')
            : '',
      action: () => {
        navigate('/ringschema', { replace: true })
      }
    }
  ]

  useEffect(() => {
    getUserDataList({
      cb: (res: Person[]): void => {
        setUserList(res)
      },
      userDataArray: usersList,
      rinGroupObject: ringingGroups
    })
  }, [usersList])

  return (
    <div className="flex flex-col gap-[25px] pb-[10px] xl:pb-[35px]">
      <div className="dash-left-top-wrapper flex w-full gap-[30px] lg:h-[366px]">
        <Progress
          loading={isRingLoading}
          ringingGroups={ringingGroups}
          progressDescription={mainNumber}
        />
        {/* side cards */}
        <div className="user-card-wrapper relative flex w-full flex-col gap-[25px] lg:w-1/2 2xl:pr-[6px]">
          {sideCards.map((card, idx) => (
            <ColorCard
              type="gradient"
              color={card.color}
              key={`side_card_${idx}`}
              loading={isOpeningHoursLoading}
            >
              <div className="relative z-[99] text-md font-normal text-primary/60">
                {card.title}
              </div>
              <div className="data relative z-[99] mt-[-3px] text-2xl-2 font-bold text-primary">
                {card.data}
              </div>
              <ActionBtn action={card.action} className="mt-[2px]" />
              {/* {card?.bgIcon} */}
            </ColorCard>
          ))}
        </div>
      </div>

      {/* user card */}
      <div className="w-full 2xl:pr-[6px]">
        <TellitLoader loading={isUserDataLoading} customClass="h-[358px]">
          <TellitCard
            header={t('Dashboard.UserTypes.User')}
            actionBtnName={t('Dashboard.UserTable.ActionBtn')}
            icon
            type="small"
            actionOnClick={() => {
              navigate('/users', { replace: true })
            }}
            customClass="user-table-card"
          >
            <div className="user-list-card flex w-full flex-col justify-start gap-[18px]">
              {userList.map((user: Person, idx: number) => (
                <div
                  className="flex items-center justify-start"
                  key={`users_${idx}`}
                >
                  <TellitAvatar
                    src={user.profileImg}
                    className="mr-[16px] min-w-[50px]"
                    name={`${user.givenName} ${user.familyName}`}
                  />
                  <div className="user-name truncate pr-2 text-lg font-bold text-primary 2xl:w-[214px]">
                    {`${user.givenName} ${user.familyName}`}
                  </div>
                  <div className="number !bg-white text-md font-normal text-primary/60 2xl:w-[164px]">
                    {user.phoneNumber} {user.did}
                  </div>
                  <div className="status flex items-center gap-[5.3px] 2xl:w-[155px]">
                    <TellitDot
                      active={user.status}
                      customClass="max-w-[9px] max-h-[9px]"
                    />
                    <div className="text-md font-normal text-primary/60">
                      {user.status
                        ? t('Dashboard.UserTypes.LoggedIn')
                        : t('Dashboard.UserTypes.LoggedOut')}
                    </div>
                  </div>
                  <div className="text-md font-normal text-primary/60">
                    {user.userType}
                  </div>
                </div>
              ))}
            </div>
          </TellitCard>
        </TellitLoader>
      </div>
      {/* states card */}
      <div className="w-full 2xl:pr-[6px]">
        <StatCard />
      </div>
    </div>
  )
}

export default Left
