import { useEffect } from 'react'

type IUseOutsideClickProps = {
  ref: any
  callback: () => void
}

const useOutsideClick = ({ ref, callback }: IUseOutsideClickProps): void => {
  useEffect(() => {
    const handleClickOutside = (event: { target: any }): void => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (Boolean(ref.current) && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

export default useOutsideClick
