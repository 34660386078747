type ITellitProps = {
  active?: boolean
  customClass?: string
}

const TellitDot = ({
  active = true,
  customClass
}: ITellitProps): JSX.Element => {
  const colorClass = !active ? 'bg-danger' : 'bg-active'
  return (
    <div
      className={`aspect-square h-[10px] w-[10px] rounded-full ${colorClass} ${customClass}`}
    />
  )
}

export default TellitDot
