import Select, { type ActionMeta, type SingleValue } from 'react-select'
import { useTranslation } from 'react-i18next'

export type IOptions = {
  value: string
  label: string
}

type ITellitSelectProps = {
  id?: string
  name?: string
  customClass?: string
  onChange: (
    newValue: SingleValue<IOptions>,
    actionMeta: ActionMeta<IOptions>
  ) => void
  disabled?: boolean
  options: Array<{ name: string; value: string | number }>
  value?: string | number | object
  enableErrorMsg?: boolean
  wrapperClass?: string
  label?: string
  required?: boolean
  placeholder?: string
  customPrefix?: string
  enableTypeValidation?: boolean
  setError?: (value: boolean) => void
  labelEnable?: boolean
}

const TellitSelect = ({
  id,
  name,
  customClass,
  onChange,
  disabled,
  value,
  options,
  enableErrorMsg = false,
  wrapperClass = '',
  label,
  required,
  placeholder,
  customPrefix = '',
  labelEnable = true
}: ITellitSelectProps): JSX.Element => {
  const { t } = useTranslation()

  const modOptions: any = options?.map((option) => ({
    value: option.value,
    label: option.name
  }))

  const getValue = (): any => {
    return modOptions.find(
      (option: { value: string }) => option.value === value
    )
  }

  return (
    <div className={`flex flex-col ${wrapperClass}`}>
      {labelEnable && (
        <label
          htmlFor={id}
          className={`relative z-[1] mb-[-0.57rem] ml-1 block w-fit rounded-xl px-1.5 text-xs font-normal capitalize tracking-[0.72px] text-primary shadow-label ${
            (disabled as boolean) ? 'bg-inactive' : 'bg-white'
          }`}
        >
          {label}
        </label>
      )}

      <Select
        required={required}
        id={id}
        name={name}
        value={getValue()}
        onChange={onChange}
        options={modOptions}
        className={`${customClass} h-[45px]`}
        classNamePrefix={`${customPrefix} tellit-select`}
        isDisabled={disabled}
        components={{
          IndicatorSeparator: () => null
        }}
        isSearchable={false}
        isMulti={false}
        menuPortalTarget={document.body}
        menuPosition="absolute"
        placeholder={placeholder}
        menuPlacement="auto"
      />

      {enableErrorMsg && (
        <p className="text-error mt-1 text-center text-[0.7rem]  font-normal text-danger-dark-xl ">
          {`${label} ${t('Msg.IsRequired')}`}
        </p>
      )}
    </div>
  )
}

export default TellitSelect
