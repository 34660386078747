import CallHistoryTable from './sections/CallHistoryTable'

const Right = (): JSX.Element => {
  return (
    <div className="pt-20 sm:pt-8 xl:pt-12 2xl:pt-[58px]">
      <CallHistoryTable />
    </div>
  )
}

export default Right
