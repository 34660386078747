import TellitCard from './TellitCard'
import { useTranslation } from 'react-i18next'

const NotFound = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className="max-w-[40rem]">
      <TellitCard customClass="bg-white mb-4">
        <div className="flex flex-row">
          <div className="max flex-col text-left">
            <div className="flex items-center gap-2">
              <h2 className="font-bold">{t('PageNotFound.Title')}</h2>
            </div>
            <p> {t('PageNotFound.Description')} </p>
          </div>
        </div>
      </TellitCard>
    </div>
  )
}

export default NotFound
