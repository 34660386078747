import React from 'react';

type PropsTypes = {
  label?: string;
  checked: boolean;
  customClassName?: string;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  id?: string;
};

const TellitCheckbox = ({
  label,
  checked,
  customClassName,
  handleOnChange,
  name,
  id,
}: PropsTypes): JSX.Element => {
  return (
    <div className="flex gap-3 items-center">
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        className={`checkbox-custom rounded-full checked:bg-neutral border-1 border-neutral ${customClassName}`}
        onChange={(e) => {
          handleOnChange(e);
        }}
      />
      <label className="text-[0.8rem] text-primary font-semibold">
        {label}
      </label>
    </div>
  );
};

export default TellitCheckbox;
