import { type DashboardStateTypes, type ActionType } from './types'
import * as types from './constant'

const initialState: DashboardStateTypes = {
  step: 1,
  userData: [],
  userProfileObject: null,
  isUpdateLogUserData: false
}

export default (
  state = initialState,
  action: ActionType
): typeof initialState => {
  switch (action.type) {
    case types.NAVIGATE_FORWARD:
      return {
        ...state,
        step: state.step + 1
      }
    case types.NAVIGATE_BACKWARD:
      return {
        ...state,
        step: state.step - 1
      }

    case types.UPDATE_USER_DATA:
      return {
        ...state,
        userData: Array.isArray(action.payload?.value)
          ? action?.payload?.value
          : []
      }

    case types.GET_USER_DATA:
      return {
        ...state,
        userProfileObject: action.payload ?? null
      }

    case types.IS_UPDATE_USER_STORE:
      return {
        ...state,
        isUpdateLogUserData: action.payload?.value as boolean
      }

    default:
      return state
  }
}
