import { toast } from 'react-toastify'

export enum ToastTypes {
  Error = 'error',
  Success = 'success'
}

export function handleToastMessage(message: string, errorType?: string): void {
  toast(message, {
    type:
      errorType !== undefined && errorType === 'success'
        ? ToastTypes.Success
        : ToastTypes.Error
  })
}
