import React from 'react'

export enum TellitToggleSizes {
  'xs' = 'xs',
  'sm' = 'sm',
  'base' = 'base',
  'md' = 'md',
  'lg' = 'lg',
  'xl' = 'xl',
  '2xl' = '2xl',
  '3xl' = '3xl',
  '4xl' = '4xl'
}

type TellitToggleProps = {
  name: string
  id?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  size?: string
  clickable?: boolean
}

const TellitToggle = ({
  name,
  id,
  onChange,
  checked,
  size = TellitToggleSizes.base,
  clickable = true
}: TellitToggleProps): JSX.Element => {
  return (
    <div className="flex items-center">
      <label
        className={`flex ${
          clickable ? 'cursor-pointer' : 'cursor-default'
        } items-center`}
      >
        <div className="relative">
          <input
            name={name}
            type="checkbox"
            id={id}
            className="hidden"
            checked={checked}
            onChange={onChange}
          />
          <div
            className={`toggle-switch rounded-full ${
              checked
                ? '!bg-active'
                : size === TellitToggleSizes.lg
                  ? '!bg-danger-dark-xl'
                  : '!bg-danger'
            } ${
              size === TellitToggleSizes.lg
                ? 'h-[32px] w-[52px]'
                : 'h-[27px] w-[44px]'
            }`}
          ></div>
          <div
            className={`toggle-handle absolute  transform rounded-full bg-white shadow-md ${
              checked ? 'translate-x-3/4' : 'translate-x-px'
            } ${
              size === TellitToggleSizes.lg
                ? 'left-[1px] right-[1px] top-[2px] h-[28px] w-[28px]'
                : 'left-[1.5px] right-[1.5px] top-[2px] h-[23px] w-[23px]'
            }`}
          ></div>
        </div>
      </label>
    </div>
  )
}

export default TellitToggle
