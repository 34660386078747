import bankId from '../../assets/images/bank-id.png'

const BankIdLogo = (): JSX.Element => {
  return (
    <img
      className="mr-[10px] h-[32px] w-[32px] group-hover:grayscale group-hover:invert"
      src={bankId}
      alt="bankId logo"
    />
  )
}

export default BankIdLogo
