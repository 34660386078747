import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import handleApiCall from 'api/handleApiCall'

import { type RingingGroup } from 'store/dashboard/types'

import { handleToastMessage } from 'utilities'
import { PageWrapper } from 'shared'

import { getAccount } from 'modules/dashboard'

import Left from './Left'

function RingSchema(): JSX.Element {
  const { t } = useTranslation()

  const userId = localStorage.getItem('user_id')

  const [ringingGroups, setRingingGroups] = useState<RingingGroup[]>([])
  const [mainNumber, setMainNumber] = useState<string>('')
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [disabledUntilSubmit, setDisabledUntilSubmit] = useState<boolean>(false)

  const getRingingGroup = (): void => {
    setDisabledUntilSubmit(true)

    void handleApiCall({
      urlType: 'getRingingGroups',
      urlParams: `&user_id=${userId}`,
      auth: true,
      cb: (res, state) => {
        setDisabledUntilSubmit(false)

        if (state && res?.data?.data !== undefined) {
          setRingingGroups(res.data.data)
        } else {
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          }
        }
      }
    })
  }

  const updateRingingGroup = (): void => {
    setDisabledUntilSubmit(true)

    const data = {
      checked: ringingGroups?.[0]?.checked || false,
      endpoints: ringingGroups?.[0]?.endpoints,
      strategy: ringingGroups[0]?.strategy,
      repeats: ringingGroups[0]?.repeats
    }

    void handleApiCall({
      urlType: 'updateRingingGroup',
      auth: true,
      urlParams: `&group_id=${ringingGroups?.[0]
        ?.id}&user_id=${localStorage.getItem(
        'user_id'
      )}&status=${ringingGroups[0]?.strategy}&repeats=${ringingGroups[0]
        ?.repeats}&data=${encodeURIComponent(JSON.stringify(data))}`,

      cb: (res, state) => {
        setDisabledUntilSubmit(false)

        if (state) {
          setIsSubmitted(true)

          if (res?.status === 200) {
            getRingingGroup()
          }
        } else {
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          } else {
            handleToastMessage(t('Msg.UpdateError'))
          }
        }
      }
    })
  }

  useEffect(() => {
    getRingingGroup()

    getAccount((res: any) => {
      const formData = res
      if (formData?.caller_id?.external?.number !== undefined) {
        const userExternalNumber = formData?.caller_id?.external?.number
        setMainNumber(userExternalNumber.replace(/^\+46/i, '0'))
      }
    })
  }, [])

  return (
    <PageWrapper
      title={t('RingSchema.Title')}
      description={
        <>
          {t('RingSchema.Description.Start')}{' '}
          <span className="underline">{t('RigSchema.Description.Link')}</span>{' '}
          {t('RingSchema.Description.End')}
        </>
      }
      leftCol={
        <Left
          setIsSubmitted={setIsSubmitted}
          isSubmitted={isSubmitted}
          setRingingGroups={setRingingGroups}
          ringingGroups={ringingGroups}
          updateRingingGroup={updateRingingGroup}
          mainNumber={mainNumber}
          disabledUntilSubmit={disabledUntilSubmit}
        />
      }
    />
  )
}

export default RingSchema
