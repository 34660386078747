type TellitRadioTypes = {
  name: string
  id?: string
  onchange: () => void
  checked: boolean
  label?: string
  radioButtonType?: string
  customClass?: string
  customLabelClass?: string
  disabled?: boolean
}

const TellitRadio = ({
  name,
  id,
  onchange,
  checked,
  label,
  customClass,
  radioButtonType = 'default',
  disabled = false
}: TellitRadioTypes): JSX.Element => {
  return (
    <div className="tellit-radio mb-3 mr-4 flex items-center">
      <input
        id={id}
        type="radio"
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={onchange}
        className={`disabled:opacity-6 aspect-square ${
          disabled ? 'cursor-default' : 'cursor-pointer'
        } appearance-none rounded-full border-[3px] border-black p-[2px] text-inherit transition-all duration-100 ease-out disabled:bg-black/25 ${
          radioButtonType === 'medium' ? 'h-[20px]' : 'h-[25px]'
        } ${customClass}`}
      />
      <label
        htmlFor="radio1"
        className={`ml-[10px] mr-1 flex ${
          disabled ? 'cursor-default' : 'cursor-pointer'
        } items-center font-bold ${
          radioButtonType === 'medium' ? 'mt-[1px] text-md' : ''
        }`}
      >
        {label}
      </label>
    </div>
  )
}

export default TellitRadio
