import axios, { type AxiosResponse } from 'axios'
import i18n from 'i18next'
// import { jwtDecode } from 'jwt-decode'
// import moment from 'moment'

import urlDoc from './urlDocs'
import { handleToastMessage } from 'utilities'

type handleApiCallInterface<T> = {
  variant?: string
  urlType: string
  data?: T
  params?: any
  cb?: (returnData: AxiosResponse<T> | any, State: boolean) => void
  setLoading?: (state: boolean) => void
  urlParams?: string
  auth?: boolean
  signal?: AbortSignal
  testAdminAccount?: boolean
}

type CommonError = {
  message?: string
}

// type DecodedToken = {
//   iss: string
//   identity_sig: string
//   account_id: string
//   method: string
//   exp: number
// }

const convertToCommonError = (response: any): CommonError => {
  if (response.data?.errorObject?.message !== undefined) {
    console.error('Check for error format1:', response.data.errorObject)
    return {
      message: response.data.errorObject?.message
    }
  }

  if (response.data?.grandidObject?.message?.status === 'failed') {
    console.error(
      'Check for error format2:',
      response.data.grandidObject?.message?.status
    )
    // // Or Need to find the correct error mesage from api response
    return {
      message: i18n.t('Msg.GrandIdFailed')
    }
  }

  // if (response.data?.count !== undefined && response.data?.count > 1) {
  //   console.error('Check for error format3:', response.data.count)

  //   return {
  //     message: i18n.t('Msg.CountTwoError')
  //   }
  // }

  if (
    response?.data !== undefined &&
    response.data?.status === 'error' &&
    response.data?.message !== undefined
  ) {
    console.error('Check for error format4:', response.data.message)
    return {
      message: response.data.message
    }
  }
  // Happens when auth token not valid
  if (
    response?.message !== undefined &&
    response?.message !== '' &&
    response?.response?.data?.message !== undefined
  ) {
    console.error('Check for error format5:', response.message)
    return {
      message: response.response?.data?.message
    }
  }

  if (response.message !== undefined) {
    console.error('Check for error format6:', response.message)
    return {
      message: response.message
    }
  }
  return {
    message: ''
  }
}

// clear local storage clear by item to prevent clear other data in local storage
// const clearLocalStorage = (): void => {
//   localStorage.removeItem('account_id')
//   localStorage.removeItem('user_id')
//   localStorage.removeItem('auth_token')
//   localStorage.removeItem('user_data')
//   localStorage.removeItem('logged_in_user')
//   localStorage.removeItem('pagination')
//   localStorage.removeItem('next_start_key')
//   localStorage.removeItem('credentials')
//   localStorage.removeItem('account_name')
// }

const handleApiCall = async <T>({
  urlType,
  data,
  params,
  cb = (returnData: AxiosResponse<T>) => returnData,
  setLoading = (state: boolean) => state,
  urlParams = '',
  auth = false,
  signal,
  testAdminAccount = false
}: handleApiCallInterface<T>): Promise<void> => {
  async function handelCall(): Promise<void> {
    const accountId = localStorage.getItem('account_id')
    const authToken = localStorage.getItem('auth_token')

    const url = `${urlDoc[urlType]?.url}${
      auth ? `&auth_token=${authToken}&account_id=${accountId}` : ''
    }${urlParams}`
    const method = urlDoc[urlType]?.type
    setLoading(true)

    try {
      const response = await axios({
        method,
        url,
        data,
        signal,
        params,
        headers: {
          'Content-Type':
            urlType === 'federatedLogin' || 'getSession'.length > 0
              ? 'application/x-www-form-urlencoded'
              : 'application/json'
        }
      })
      setLoading(false)
      // Check if the response contains an 'errorObject'

      if (response?.data !== undefined) {
        // Check for error format
        const commonError = convertToCommonError(response).message

        if (commonError !== undefined && commonError !== '') {
          // Adding message to response.data
          // eslint-disable-next-line n/no-callback-literal
          cb({ ...response.data, messge: commonError }, false)
          return
        }
      }
      cb(response, true)
    } catch (error: any) {
      setLoading(false)

      const commonError = convertToCommonError(error).message
      if (
        commonError !== undefined &&
        commonError !== '' &&
        error?.response?.status !== 401
      ) {
        handleToastMessage(error?.response?.data?.message)
        // eslint-disable-next-line n/no-callback-literal
        cb(
          error?.response?.data !== undefined
            ? { ...error.response.data, messge: commonError }
            : { message: commonError },
          false
        )
      } else {
        cb(error, false)
      }
      //  Check with 401 response and
      if (error?.response?.status === 401) {
        // let ret = false
        // const accountName = localStorage.getItem('account_name')
        // const userAuth = localStorage.getItem('credentials')
        // const authUrl = `${urlDoc.getAuthToken.url}&account_name=${accountName}&auth=${userAuth}`
        // await axios({
        //   url: authUrl,
        //   method: urlDoc.getAuthToken.type
        // })
        //   .then((res) => {
        //     localStorage.setItem('auth_token', res.data.auth_token)
        //   })
        //   .catch((error) => {
        //     console.log(error)
        //     ret = true
        //   })
        // if (ret) {
        //   clearLocalStorage()
        //   window.location.replace('/')
        // } else {
        //   await handelCall()
        // }
        // // clearLocalStorage()
        // // window.location.replace('/')
      }
    }
  }
  await handelCall()
}

export default handleApiCall
