import { useTranslation } from 'react-i18next'

import { TellitCard } from 'components/common'

import ProfileForm from './ProfileForm'

import { ISuperAdminDetails, IContactFormType } from 'store/dashboard/types'

type ILeftColumnProps = {
  setSuperAdminData: (data: IContactFormType | ISuperAdminDetails) => void
}

const LeftColumn = ({ setSuperAdminData }: ILeftColumnProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <TellitCard
      header={t('Profile.Title')}
      customClass="!rounded-xl !shadow-whiteCard 2xl:max-w-[778px]"
    >
      <ProfileForm setProfileData={setSuperAdminData} />
    </TellitCard>
  )
}

export default LeftColumn
