type TellitRoundedIconProps = {
  customClass?: string
  icon: JSX.Element | null | undefined
}

function TellitRoundedIcon({
  customClass,
  icon = null
}: TellitRoundedIconProps): JSX.Element {
  return (
    <div
      className={`h-[3.5rem] w-[3.5rem] rounded-full bg-purple-1 grid text-2xl place-content-center shadow-purple-1 shadow ${customClass}`}
    >
      {icon}
    </div>
  )
}

export default TellitRoundedIcon
