export type IErrorObj = {
  name: string
  error: boolean
}

export type IUseFormValidate = {
  fieldError: boolean
  name: string
  setErrorArray: React.Dispatch<React.SetStateAction<IErrorObj[]>>
  errorArray: IErrorObj[]
  id: string
}

export const validateZipCode = (value: string): string => {
  const stringWithSpaces = value
  const stringWithoutSpaces = stringWithSpaces.replace(/\s/g, '')

  const regex = /^\d{5}$/

  const numbers = stringWithoutSpaces.match(/\d+/g)

  const isFiveDigitNumber = numbers?.some((num: any) => regex.test(num))

  const returnNumberValue = !(isFiveDigitNumber as boolean)

  const regexString = /[a-zA-Z]/

  const hasStringName = regexString.test(value)

  const returnStringValue = !(hasStringName as boolean)

  if (returnNumberValue) {
    return 'zipCode'
  } else if (returnStringValue) {
    return 'ort'
  } else {
    return ''
  }
}

export const validateMobile = (value: string): boolean => {
  // Regular expression for valid Swedish mobile phone numbers
  const swedishMobileRegex = /^07[0-9]{8}$/

  if (!swedishMobileRegex.test(value)) {
    return true
  } else {
    return false
  }
}

export const useValidateForm = ({
  fieldError,
  name,
  setErrorArray,
  errorArray,
  id
}: IUseFormValidate): boolean => {
  // Function to update error for a specific name
  const updateError = (error: boolean): void => {
    setErrorArray((prev: IErrorObj[]) => {
      // Check if there is an existing error entry for the name
      const existingIndex = prev.findIndex((item) => item.name === name)

      if (existingIndex !== -1) {
        // Update the existing error entry if error status changes
        if (prev[existingIndex].error !== error) {
          return prev.map((item, index) => {
            if (index === existingIndex) {
              return { ...item, error }
            }
            return item
          })
        }

        const filterReturnArray = prev.filter((obj) => obj.error)

        return filterReturnArray // No changes needed
      } else {
        // Add a new error entry for the name if it doesn't exist
        if (error) {
          return [...prev, { name, error }]
        } else {
          return [...prev]
        }
      }
    })
  }

  // Update error based on fieldError
  if (fieldError) {
    updateError(true) // Set error to true for the specific name
  } else {
    updateError(false) // Set error to false for the specific name
  }

  // Check if there is any error in the form
  const isFormValid = checkFormValid(errorArray, id)

  return isFormValid
}

export const checkFormValid = (errorArray: any, id: string): boolean => {
  const filterArray = errorArray.filter((item: any) => item.name.includes(id))

  const isFormValid =
    (filterArray as boolean) && filterArray.length > 0
      ? filterArray.some((item: any) => item.error)
      : false

  return isFormValid
}

export const removeEditedArrayValue = (
  errorArray: any,
  id: string
): IErrorObj[] => {
  const filterArray = (errorArray?.length as boolean)
    ? errorArray.filter((item: any) => !(item.name.includes(id) as boolean))
    : []

  const returnArr = (filterArray?.length as boolean) ? filterArray : []

  return returnArr
}

export const validatePersonNumber = (value: string): boolean => {
  if (value !== '' || value !== undefined) {
    // person number format check. this will maybe temporary.  don't remove
    // const personnummerRegex = /^(19|20)?(\d{6}([-+]?)\d{4})$/
    // const isValidDataFormat = personnummerRegex.test(value)

    if (value?.length < 10 || value?.length > 12) {
      return true
    }
    // person number format check. this will maybe temporary.  don't remove
    // else if (!isValidDataFormat) {
    //   return true
    // }
    else {
      return false
    }
  } else {
    return false
  }
}

export default {
  validateZipCode,
  validateMobile,
  useValidateForm,
  checkFormValid,
  removeEditedArrayValue,
  validatePersonNumber
}
