import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
import { Link, useLocation } from 'react-router-dom'
import { SingleValue } from 'react-select'

import { LoginUserData, UserDataUpdate } from 'store/dashboard/action'

import { ActionButton, TellitCard, Typography } from 'components/common'
import { TellitSelect, TellitTextArea } from 'components/form'
import { TypographySizes } from 'components/common/Typography'

import ProfileForm, { removeHyphen } from 'modules/profile/ProfileForm'
import ProfileOverviewCard from 'modules/profile/ProfileOverviewCard'

import {
  // type ProfileFormTypes,
  type IContactFormType
  // type ISuperAdminDetails
} from 'store/dashboard/types'

import handleApiCall from 'api/handleApiCall'
// import { handleToastMessage } from 'utilities'
import { IOptions } from 'components/form/TellitSelect'

type IContactOptions = {
  name: string
  value: string
}

const contactOptions: IContactOptions[] = [
  { name: i18n.t('Contact.Form.Select.Option1'), value: '1' },
  { name: i18n.t('Contact.Form.Select.Option2'), value: '2' },
  { name: i18n.t('Contact.Form.Select.Option3'), value: '3' }
]

const Left = (): JSX.Element => {
  const location = useLocation()
  const isOpenUpgradeMenu = location?.state?.updateProVersion as boolean
  const isHelpMenu = location?.state?.helpMenu as boolean

  const [contactParentForm, setContactParentForm] = useState<IContactFormType>({
    comment: '',
    type: isOpenUpgradeMenu ? contactOptions[0].value : '',
    subject: isOpenUpgradeMenu ? contactOptions[0].name : '',
    contactData: {
      loginUserName: '',
      organizationNumber: '',
      address: '',
      city: '',
      phoneNumber: '',
      did: '',
      emailAddress: '',
      userType: null
    },
    superAdminData: null
  })

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [showValidationError, setShowValidationError] = useState<boolean>(false)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  // const navigate = useNavigate()

  const userId = localStorage.getItem('user_id')

  useEffect(() => {
    if (isHelpMenu) {
      setContactParentForm({
        ...contactParentForm,
        type: contactOptions[1].value,
        subject: contactOptions[1].name
      })
    }
  }, [isHelpMenu])

  const getContactsData = (): void => {
    void handleApiCall({
      urlType: 'getContacts',
      urlParams: `&no_presences=1`,
      auth: true,
      cb: (res, state) => {
        if (res?.data !== undefined && state) {
          localStorage.setItem('user_data', JSON.stringify(res?.data))

          dispatch(UserDataUpdate({ value: res?.data }))

          if (res?.data?.length as boolean) {
            const finedLoginUser = res.data.find(
              (item: { recordID: string | null }) => item.recordID === userId
            )
            if (finedLoginUser !== undefined) {
              localStorage.setItem(
                'logged_in_user',
                JSON.stringify(finedLoginUser)
              )

              dispatch(LoginUserData(finedLoginUser))
            }
          }
        } else {
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          }
        }
      }
    })
  }

  function isObjectEmpty(obj: any): boolean {
    if (obj.type === '1') return false
    for (const key in obj) {
      if (obj[key] === null) {
        return true
      } else if (obj[key] === undefined) {
        return true
      } else if (
        (obj[key] === '' && key === 'subject') ||
        (obj[key] === '' && key === 'message')
      ) {
        return true
      }
    }
    return false
  }

  // const isSuperAdminDataNotNull = superAdminData !== null
  // const givenName = isSuperAdminDataNotNull ? superAdminData?.givenName : ''
  // const familyName = isSuperAdminDataNotNull ? superAdminData?.familyName : ''

  const userName = contactParentForm.contactData?.userType?.fullName

  const postFormObject = {
    company_name: contactParentForm?.contactData?.loginUserName,
    org_nr: contactParentForm?.contactData?.organizationNumber,
    address: `${contactParentForm?.contactData?.address} ${contactParentForm?.contactData?.city}`,
    email: contactParentForm?.contactData?.emailAddress,
    phone_number: contactParentForm?.contactData?.phoneNumber,
    did: contactParentForm?.contactData?.did,
    user_name: userName,
    subject: contactParentForm.subject,
    message: contactParentForm.comment,
    type: contactParentForm.type,
    url: localStorage.getItem('page_url')
  }

  const isPostFormObjectEmpty = isObjectEmpty(postFormObject)

  const handleSubmit = (): void => {
    setShowValidationError(isPostFormObjectEmpty)

    if (isPostFormObjectEmpty) {
      setIsSubmitted(false)
      setShowValidationError(true)
    } else {
      setShowValidationError(true)

      void handleApiCall({
        urlType: 'contactForm',
        auth: true,
        urlParams: `&data=${encodeURIComponent(
          JSON.stringify(postFormObject)
        )}`,
        cb: (res, state) => {
          setShowValidationError(false)

          if (state) {
            console.log('update success')
            setIsSubmitted(true)
          } else {
            if (res?.messge !== '') {
              // handleToastMessage(res.messge)
            }
          }
        }
      })
    }
  }

  const { superAdminData } = contactParentForm
  const adminPersonalNumber = superAdminData?.personalNumber
  const loggedUserPersonalNumber =
    contactParentForm.contactData?.userType?.personalNumber

  useEffect(() => {
    getContactsData()
  }, [])

  useEffect(() => {
    if (isSubmitted) {
      setIsSubmitted(false)
    }
  }, [contactParentForm])

  return (
    <TellitCard
      header={t('Contact.Left.Title')}
      customClass="!rounded-xl !shadow-whiteCard 2xl:max-w-[778px]"
    >
      <div className="-mt-[8px] mb-[16px]  max-w-[354px]">
        <Typography
          size={TypographySizes.sm}
          opacity={0.6}
          customClasses="leading-xl"
        >
          {t('Contact.Left.Description1')}{' '}
          <Link className="underline" to="/profile">
            {t('Contact.Left.Description2')}
          </Link>
        </Typography>
      </div>

      <div className="border-b-[1px] pb-[24px]">
        <ProfileForm isContact setProfileData={setContactParentForm} />
      </div>

      <ProfileOverviewCard
        // btnName={t('Contact.Profile.Sender')}
        // btnClass="font-normal"
        key="contactProfileCard"
        role={t('Contact.Profile.Role')}
        isContact
        className="!mt-[15px] rounded-smaller border-[1px] !bg-inactive !px-[18px] !py-[16px] md:max-h-[82px]"
        formData={{
          givenName: contactParentForm.contactData?.userType?.givenName,
          familyName: contactParentForm.contactData?.userType?.familyName,
          userType:
            removeHyphen(adminPersonalNumber) ===
            removeHyphen(loggedUserPersonalNumber)
              ? 'super-admin'
              : contactParentForm.contactData?.userType?.userType
        }}
        // onClick={() => {
        //   navigate('/users')
        // }}
      />

      <div>
        <div className="flex flex-col gap-[20px] pt-[40px] ">
          <TellitSelect
            id="autoFocusSelect"
            placeholder={t('Contact.Form.Placeholder.Väljärende')}
            options={contactOptions}
            label={t('Contact.Form.Label.Ärende')}
            labelEnable={false}
            value={contactParentForm.type}
            onChange={(option: SingleValue<IOptions>): void => {
              if (showValidationError) {
                setShowValidationError(false)
              }
              if (
                option !== null &&
                'value' in option &&
                option.value !== null
              ) {
                console.log('option', option)
                setContactParentForm({
                  ...contactParentForm,
                  type: option.value,
                  subject: option.label
                })
              }
            }}
            enableErrorMsg={
              showValidationError && contactParentForm.type === ''
            }
          />

          <TellitTextArea
            label={t('Contact.Form.Description.Label')}
            value={contactParentForm.comment}
            onChange={(e: any): void => {
              if (showValidationError) {
                setShowValidationError(false)
              }

              setContactParentForm({
                ...contactParentForm,
                comment: e.target.value
              })
            }}
            required
            enableErrorMsg={
              showValidationError &&
              contactParentForm.comment === '' &&
              contactParentForm.type !== '1'
            }
          />
        </div>

        <div className="mb-[16px] flex w-full items-start justify-center pt-[18px] md:justify-end ">
          <ActionButton
            type={!isSubmitted ? 'send' : ''}
            label={
              isSubmitted ? t('Generic.Button.Skickat') : t('Contact.ActionBtn')
            }
            onClick={() => {
              handleSubmit()
            }}
            isSubmitted={isSubmitted}
            disabled={!isPostFormObjectEmpty && showValidationError}
          />
        </div>
      </div>
    </TellitCard>
  )
}

export default Left
