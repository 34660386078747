import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { toast } from 'react-toastify'
import { TooltipRefProps } from 'react-tooltip'

import {
  type FormTypes,
  // type FormHeaderTypes, // hide for free version
  type HandleValueChangeTypes,
  ReactOnSelectValueTypes
} from 'store/dashboard/types'

// import { handleToastMessage } from 'utilities'  // remove for the request

import useOutsideClick from 'helpers/getOutsideClick'

import handleApiCall from 'api/handleApiCall'

import {
  TellitCard,
  // ImageUpload, // hide for free version
  ActionButton,
  TellitTooltip
} from 'components/common'
import { TellitInput, TellitSelect } from 'components/form'
import TellitToggle, { TellitToggleSizes } from 'components/form/TellitToggle'

// import { handleToastMessage } from 'utilities'

import {
  useValidateForm,
  checkFormValid,
  validatePersonNumber,
  type IErrorObj
} from '../../helpers/validationFunctions'
import { getUserType } from 'helpers'

// const FormHeader = ({ title }: FormHeaderTypes): JSX.Element => {
//   return (
//     <div className="mb-[8px] mt-[8px] text-3xl-2 font-bold text-primary">
//       {title}
//     </div>
//   )
// } // hide for free version

type UserFormProps = {
  rowData?: any
  callBack?: (type: string, data?: any) => void
  rowIndex?: number
  superAdminId?: number | null
  getContactsData: (loadStatus?: boolean) => void
  newFormId?: number
  removeNewForm?: (id: number) => void
  usersEmails?: string[]
  errorArray: IErrorObj[]
  setErrorArray: React.Dispatch<React.SetStateAction<IErrorObj[]>>
}

function UserForm({
  rowData,
  rowIndex,
  superAdminId,
  callBack,
  getContactsData,
  newFormId,
  removeNewForm,
  usersEmails = [],
  errorArray,
  setErrorArray
}: UserFormProps): JSX.Element {
  const { t } = useTranslation()

  const deletePopupRef = useRef<TooltipRefProps>(null)

  const deleteWrapperRef = useRef(null)

  const loggedUserId = localStorage.getItem('user_id')

  const isAdmin =
    JSON.parse(localStorage.getItem('logged_in_user') as string)?.userType ===
    'admin'

  const isEdit = rowData?.id as boolean

  const deletedTooltipId = isEdit
    ? `delete-tooltip${rowIndex}_${rowData?.id}`
    : `delete-tooltip${rowIndex}_${newFormId}`

  const [form, setForm] = useState<FormTypes>({
    givenName: '',
    familyName: '',
    phoneNumber: '',
    did: '',
    emailAddress: '',
    personalNumber: '',
    userType: !isAdmin ? 'user' : '',
    status: false,
    id: ''
  })

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [isDeleted, setIsDeleted] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(true)
  const [disabledUntilSubmit, setDisabledUntilSubmit] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const handleOnSelect = ({ value, name }: ReactOnSelectValueTypes): void => {
    setIsSubmitted(false)
    const selectName = `${
      isEdit ? `isEdit_${form.id}` : newFormId
    }_${'personalNumber'}`

    const checkPersonNumber = (errorArray?.length > 0 &&
      errorArray.filter(
        (item: IErrorObj) => item.name !== selectName
      )) as boolean

    const checkPersonNumberError = (errorArray?.length > 0 &&
      errorArray.filter(
        (item: IErrorObj) => item.name === selectName
      )) as boolean

    const personNumberValidStatus = validatePersonNumber(form.personalNumber)

    if (
      value.value === 'admin' &&
      (checkPersonNumber || personNumberValidStatus)
    ) {
      handleValidateForm(true, selectName)
    } else if (checkPersonNumberError && value.value === 'admin') {
      setIsError(true)
    } else if (personNumberValidStatus && value.value !== 'admin') {
      handleValidateForm(false, selectName)
    } else {
      handleValidateForm(false, selectName)
    }

    setForm((prevState) => ({
      ...prevState,
      [name]: value.value
    }))

    if (isDeleted) {
      setIsDeleted(false)
    }
  }

  const handleValueChange = ({ e }: HandleValueChangeTypes): void => {
    setIsSubmitted(false)

    if (e.target.name === 'emailAddress' && e.target.value !== '') {
      if (
        checkDuplicateEmail({
          email: e.target.value,
          removableEmail: rowData?.email
        })
      ) {
        handleValidateForm(
          true,
          `${isEdit ? `isEdit_${form.id}` : newFormId}_${e.target.name}`
        )
      }
    }

    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))

    if (isDeleted) {
      setIsDeleted(false)
    }
  }

  const handleToggleChange = (): void => {
    setIsSubmitted(false)

    const isChecked = !form.status

    setForm((prevState) => ({
      ...prevState,
      status: isChecked
    }))

    if (isDeleted) {
      setIsDeleted(false)
    }

    callBack?.('user-toggle', {
      rowData,
      rowIndex: !isEdit && rowIndex !== undefined ? rowIndex - 1 : rowIndex
    })
  }

  const isEmpty = isObjectEmpty()

  function isObjectEmpty(): boolean {
    const userTypeFormValidate =
      // select role as admin
      form.userType === 'admin'
        ? [
            'givenName',
            'familyName',
            'phoneNumber',
            'emailAddress',
            'userType',
            'personalNumber'
          ]
        : // select role as user
          ['givenName', 'familyName', 'phoneNumber', 'emailAddress', 'userType']

    const validationFields = (): string[] => {
      if (isEdit) {
        // admin edit
        if (isAdmin) {
          return userTypeFormValidate
        }
        // user edit
        return ['givenName', 'familyName', 'phoneNumber', 'emailAddress']
      }
      // add form
      return userTypeFormValidate
    }

    const fieldsToCheck = validationFields()
    for (const key of fieldsToCheck) {
      const value = form[key as keyof FormTypes]

      if (value === null || value === undefined || value === '') {
        return true
      }
    }
    return false
  }

  const handleCreateAndUpdateUser = async (): Promise<void> => {
    if (isError || isEmpty) {
      setIsSubmitted(false)

      return
    }

    setDisabledUntilSubmit(true)

    if (isEdit) {
      setLoading(true)

      void handleApiCall({
        urlType: 'updateContact',
        auth: true,
        setLoading,
        urlParams: `&recordID=${rowData?.id}&data=%7B"givenName": "${form.givenName}","familyName": "${form.familyName}","phoneNumber": "${form.phoneNumber}","emailAddress": "${form.emailAddress}","personalNumber":"${form.personalNumber}","privLevel":"${form.userType}"%7D`,
        cb: (res, state) => {
          setDisabledUntilSubmit(false)

          if (state) {
            setIsSubmitted(true)

            callBack?.('user-edit', form)
          } else {
            if (res?.messge !== '') {
              // handleToastMessage(res.messge)
            } else {
              toast(t('Msg.UpdateError'), { type: 'error' })
            }
          }
        }
      })
    } else {
      try {
        setLoading(true)
        console.log('handleCreateAndUpdateUser', form)
        const urlParams = `&data=%7B"givenName": "${form.givenName}","familyName": "${form.familyName}","phoneNumber": "${form.phoneNumber}","emailAddress": "${form.emailAddress}","personalNumber":"${form.personalNumber}","userType":"${form.userType}"%7D`
        void handleApiCall({
          urlType: 'addContact',
          auth: true,
          setLoading,
          urlParams,
          cb: (res, state) => {
            setDisabledUntilSubmit(false)
            if (state) {
              setIsSubmitted(true)
              if (res as boolean) {
                const callbackData = {
                  id: res.data.user_id,
                  profileImg: '',
                  userType: getUserType(form.userType),
                  familyName: form.familyName,
                  givenName: form.givenName,
                  phoneNumber: form.phoneNumber,
                  did: form.did,
                  email: form.emailAddress,
                  personalNumber: form.personalNumber,
                  groupId: res.data.group_id,
                  status: form.status
                }
                callBack?.('user-add', callbackData)
              }
            } else {
              if (res?.messge !== '') {
                // handleToastMessage(res.messge)
              } else {
                toast(t('Msg.UpdateError'), { type: 'error' })
              }
            }
          }
        })
      } catch (error) {
        console.error('An error occurred during API calls:', error)

        toast(t('Msg.CreateError'), { type: 'error' })
      }
    }
  }

  const handleDeleteUser = async (): Promise<void> => {
    setIsDeleted(true)
    setIsSubmitted(false)

    if (rowData?.id as boolean) {
      try {
        // remove row from the table
        void handleApiCall({
          urlType: 'deleteContact',
          auth: true,
          urlParams: `&recordID=${rowData?.id}&delete_user=true`,
          cb: (res, state) => {
            if (state) {
              // getContactsData()

              callBack?.('edit-user-delete', rowData)

              setIsDeleted(false)
            } else {
              if (res?.messge !== '') {
                // handleToastMessage(res.messge)
              }
              setIsDeleted(false)
            }
          }
        })
      } catch (error) {
        console.error('An error occurred during API calls:', error)

        setIsDeleted(false)
      }
    } else {
      setIsDeletePopupOpen(false)

      if (removeNewForm !== undefined) {
        removeNewForm(newFormId as number)
      }

      setIsDeleted(false)
    }
  }

  const canDisableUserRoleAccess = (): boolean => {
    let canDisable = false
    if (superAdminId !== undefined && superAdminId !== null) {
      if (
        superAdminId.toString() === rowData?.id &&
        superAdminId.toString() === loggedUserId
      ) {
        canDisable = false
      } else {
        canDisable =
          (!!((rowData?.id as boolean) && form?.userType !== '') && !isAdmin) ||
          !isAdmin ||
          !!(superAdminId.toString() === rowData?.id)
      }
    } else {
      canDisable =
        (!!((rowData?.id as boolean) && form?.userType !== '') && !isAdmin) ||
        !isAdmin
    }

    return canDisable
  }

  const actionButtons = [
    {
      type: 'delete',
      label: isDeleted ? t('User.Form.Borttaget') : t('User.Form.Trash'),
      disabled:
        newFormId !== undefined
          ? false
          : rowData?.id === loggedUserId || !isAdmin || isLoading,
      isSubmitted: isDeleted,
      onClick: () => {
        setIsDeletePopupOpen(true)
        deletePopupRef.current?.open({
          anchorSelect: `#${deletedTooltipId}`
        })
      },
      id: `${deletedTooltipId}`
    },
    {
      label: isSubmitted
        ? t('Generic.Button.Sparat')
        : t('Generic.Button.Submit'),
      disabled: isEmpty || isError || isLoading || disabledUntilSubmit,
      isSubmitted,
      onClick: handleCreateAndUpdateUser
    }
  ]

  const inputConfigs = [
    {
      label: t('User.Form.Label.GivenName'),
      value: form?.givenName,
      name: 'givenName',
      isShowInput: true
    },
    {
      label: t('User.Form.Label.FamilyName'),
      value: form?.familyName,
      name: 'familyName',
      isShowInput: true
    },
    {
      label: t('User.Form.Label.UserType'),
      value: !isAdmin && !(rowData?.id as boolean) ? 'user' : form?.userType,
      name: 'userType',
      showAsSelect: true,
      disabled: canDisableUserRoleAccess(),
      isShowInput: false
    },
    {
      label: t('User.Form.Label.Did'),
      value: form?.did.replace(/^\+46/i, '0'),
      name: 'did',
      disabled: true,
      isShowInput: true
    },
    {
      label: t('User.Form.Label.PhoneNumber'),
      value: form?.phoneNumber.replace(/^\+46/i, '0'),
      name: 'phoneNumber',
      validationType: 'mobile',
      isShowInput: true
    },
    {
      label: t('User.Form.Label.EmailAddress'),
      value: form?.emailAddress,
      name: 'emailAddress',
      type: 'email',
      disabled:
        rowData?.id === loggedUserId
          ? false
          : (rowData?.id as boolean) &&
            form?.emailAddress?.length > 0 &&
            !isAdmin,
      isShowInput: true
    },
    {
      label: t('User.Form.Label.PersonalNumber'),
      value: form?.personalNumber,
      name: 'personalNumber',
      type: 'personalNumber',
      validationType: 'personalNumber',
      isShowInput:
        superAdminId !== undefined && superAdminId !== null
          ? superAdminId === rowData?.id &&
            superAdminId === Number(loggedUserId)
            ? true
            : superAdminId === rowData?.id &&
                form.userType.toLowerCase() === 'admin'
              ? true
              : isAdmin && form.userType.toLowerCase() === 'admin'
          : isAdmin && form.userType.toLowerCase() === 'admin'
    }
  ]

  const checkDuplicateEmail = ({
    email,
    removableEmail
  }: {
    email: string
    removableEmail?: string
  }): boolean => {
    const userEmailList =
      removableEmail != null
        ? usersEmails.filter((e) => e !== removableEmail)
        : usersEmails

    return userEmailList.includes(email)
  }

  useEffect(() => {
    if (rowData as boolean) {
      setForm((pre) => ({
        ...pre,
        givenName: rowData.givenName,
        familyName: rowData.familyName,
        phoneNumber: rowData.phoneNumber.replace(/^\+46/i, '0'),
        did: rowData.did.replace(/^\+46/i, '0'),
        emailAddress: rowData.email,
        personalNumber: (rowData?.personalNumber as boolean)
          ? rowData.personalNumber.replace(/-/g, '')
          : rowData.personalNumber,
        userType:
          rowData.userType === 'Admin'
            ? 'admin'
            : rowData.userType === 'Användare'
              ? 'user'
              : rowData.userType,
        status: Boolean(rowData.status) || false,
        id: rowData.id
      }))
    }
  }, [rowData])

  const editId = isEdit ? `isEdit_${form.id}_` : `${newFormId}_`

  useEffect(() => {
    if (isEmpty) {
      setIsError(true)
    } else {
      const isFormValid = checkFormValid(errorArray, editId)

      setIsError(isFormValid)
    }
  }, [form])

  useOutsideClick({
    ref: deleteWrapperRef,
    callback: () => {
      setIsDeletePopupOpen(false)
    }
  })

  const handleValidateForm = (val: any, name: string): void => {
    const isFormError = useValidateForm({
      fieldError: val,
      name,
      errorArray,
      setErrorArray,
      id: editId
    })

    setIsError(isFormError)
  }

  return (
    <>
      <TellitCard
        customClass={`shadow-none 2xl:max-w-[778px]`}
        header={
          isEdit
            ? `${form?.givenName} ${form?.familyName}`
            : t('User.Form.Label.NyAnvändare')
        }
      >
        <div className="grid-rows-2 lg:flex lg:gap-[24px]">
          {/* <ImageUpload isEdit={isEdit} /> // hide for free version */}

          <div className="lg:grow">
            {/* <FormHeader
            title={
              isEdit
                ? `${form?.givenName} ${form?.familyName}`
                : t('User.Form.Label.NyAnvändare')
            }
          />  // hide for free version */}

            <div>
              <div className="grid-cols-full grid gap-3 gap-y-[5px] sm:grid-cols-2   lg:grid-cols-3">
                {inputConfigs.map((config, configIndex) => {
                  return (config.showAsSelect as boolean) ? (
                    <TellitSelect
                      key={configIndex}
                      placeholder={t('User.Form.Placeholder.Välj')}
                      label={config.label}
                      value={config.value}
                      name={config.name}
                      onChange={(option: any) => {
                        if (option?.value === '') {
                          handleValidateForm(
                            true,
                            `${isEdit ? `isEdit_${form.id}` : newFormId}_${
                              config.name
                            }`
                          )
                        } else {
                          handleValidateForm(
                            false,
                            `${isEdit ? `isEdit_${form.id}` : newFormId}_${
                              config.name
                            }`
                          )
                        }

                        handleOnSelect({
                          value: option,
                          name: config.name
                        })
                      }}
                      customClass={`${
                        isEdit && config.value !== '' ? '!capitalize' : ''
                      } h-[45px]`}
                      options={[
                        { name: t('Dashboard.UserTypes.User'), value: 'user' },
                        { name: t('Dashboard.UserTypes.Admin'), value: 'admin' }
                      ]}
                      enableErrorMsg={config.value === '' && !isEmpty}
                      disabled={config.disabled}
                    />
                  ) : config.isShowInput ? (
                    <div
                      key={configIndex}
                      // className={
                      //   !isEdit &&
                      //   config.name === 'emailAddress' &&
                      //   form.userType === 'user'
                      //     ? 'sm:col-span-2'
                      //     : ''
                      // }
                    >
                      <TellitInput
                        key={configIndex}
                        label={config.label}
                        value={config.value}
                        name={config.name}
                        disabled={config.disabled}
                        validationType={config.validationType}
                        type={config.type}
                        enableErrorMsg
                        setError={(val) => {
                          handleValidateForm(
                            val,
                            `${isEdit ? `isEdit_${form.id}` : newFormId}_${
                              config.name
                            }`
                          )
                        }}
                        required
                        errorMsg={
                          config.name === 'emailAddress' &&
                          checkDuplicateEmail({
                            email: config.value,
                            removableEmail: rowData?.email
                          })
                            ? t('Msg.DuplicateEmail')
                            : ''
                        }
                        onBlur={() => {
                          if (config.name === 'emailAddress') {
                            if (
                              checkDuplicateEmail({
                                email: config.value,
                                removableEmail: rowData?.email
                              })
                            ) {
                              handleValidateForm(
                                true,
                                `${isEdit ? `isEdit_${form.id}` : newFormId}_${
                                  config.name
                                }`
                              )
                            }
                          }
                        }}
                        onChange={(evt) => {
                          handleValueChange({ e: evt })
                        }}
                      />
                    </div>
                  ) : (
                    <div key={configIndex} />
                  )
                })}
                <div
                  className={`mt-[6px] grid  ${
                    isEdit
                      ? 'lg:col-span-3'
                      : 'justify-items-center sm:col-span-2 sm:justify-items-end lg:col-span-3'
                  }`}
                >
                  <div
                    className={` ${
                      isEdit
                        ? 'grid-cols-full grid gap-3 gap-y-[5px] sm:grid-cols-3 lg:grid-cols-3'
                        : 'mt-[15px] flex gap-[12px]'
                    } `}
                  >
                    {isEdit && (
                      <div className="flex">
                        <TellitToggle
                          checked={form?.status}
                          onChange={(e) => {
                            handleToggleChange()
                          }}
                          name="userForm"
                          size={TellitToggleSizes.lg}
                        />
                        <span
                          onClick={() => {
                            handleToggleChange()
                          }}
                          className="ml-[10px] mt-[4px] cursor-pointer text-md font-normal text-primary"
                        >
                          {(form?.status as boolean)
                            ? t('Generic.Button.Toggle')
                            : t('Generic.Button.UnToggle')}
                        </span>
                      </div>
                    )}
                    {actionButtons.map((button, buttonIndex) => (
                      <ActionButton {...button} key={buttonIndex} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TellitCard>

      <div ref={deleteWrapperRef}>
        <TellitTooltip
          toolTipRef={deletePopupRef}
          id={`${deletedTooltipId}`}
          place="top"
          imperativeModeOnly={isDeletePopupOpen}
          customClass={`!bg-danger z-[99] !opacity-100 !rounded-tiny !max-w-[230px] !py-2 ${
            !isDeletePopupOpen && 'hidden'
          }`}
        >
          <div className="flex flex-col gap-2">
            <div className="text-sm-1 text-cream">
              {t('User.Delete.Modal.Title')}
            </div>

            <div className="flex items-center justify-end gap-[15px]">
              <button
                onClick={() => {
                  void handleDeleteUser()
                }}
                className="rounded-sm bg-danger-dark-xl px-2 pb-[1px] text-white transition-colors  duration-200 hover:bg-danger-dark"
              >
                {t('Generic.Button.Delete')}
              </button>

              <button
                onClick={() => {
                  setIsDeletePopupOpen(false)
                }}
              >
                {t('Generic.Button.Close')}
              </button>
            </div>
          </div>
        </TellitTooltip>
      </div>
    </>
  )
}

export default UserForm
