import { useEffect, useState } from 'react'
import moment from 'moment'

import { IPaginationType } from 'components/common/TellitTable'
import { TellitTable, TellitLoader } from 'components/common'
import handleApiCall from 'api/handleApiCall'

import { FilterInputs } from 'modules/call/Left'
// import { handleToastMessage } from 'utilities'
import { getDateRangeForSelectedShortcuts } from 'components/common/DateRangePickers'

type ICdrTableProps = {
  columns: any
  filter?: FilterInputs
  hasPagination?: boolean
}

type IFilterData = {
  startDate: string | null
  endDate: string | null
  callReference: string | null
  callStatus: string
}

type IMediaRecording = string

type ICallValue = {
  id: string
  call_id: string
  caller_id_number: string
  caller_id_name: string
  callee_id_number: string
  callee_id_name: string
  duration_seconds: number
  billing_seconds: number
  timestamp: string
  hangup_cause: string
  disposition: string
  other_leg_call_id: string
  owner_id: string
  to: string
  from: string
  direction: 'inbound' | 'outbound'
  authorizing_id: string
  dialed_number: string
  calling_from: string
  datetime: string
  unix_timestamp: string
  rfc_1036: string
  iso_8601: string
  iso_8601_combined: string
  call_type: string
  bridge_id: string
  recording_url: string
  media_recordings: IMediaRecording[]
  call_priority: string
  interaction_id: string
  inception_direction: 'origination'
  handled_by: null
  note: null
  answered: boolean
  reference: string
  [key: string]: unknown
}

type ICallData = Record<string, ICallValue>

type ICallHistory = {
  id: string
  answered: boolean
  callDirection: 'inbound' | 'outbound'
  status: 'missed' | 'in' | 'out' | ''
  datetime: string
  callDate: string
  number: string
  callRef: string
  audio: IMediaRecording | undefined
}

const CdrTable = ({
  columns,
  hasPagination = true,
  filter
}: ICdrTableProps): JSX.Element => {
  const [callHistory, setCallHistory] = useState<ICallHistory[]>([])
  const [callerLoader, setCallerLoader] = useState(false)

  const thisWeek = getDateRangeForSelectedShortcuts('thisWeek')

  const getStatus = (value: ICallValue): string => {
    if (value.call_direction === 'inbound') {
      if (!value.answered) {
        return 'missed'
      }
      if (value.answered) {
        return 'in'
      }
    } else if (value.call_direction === 'outbound') {
      return 'out'
    }
    return ''
  }

  const getCallData = (
    dataPassed: ICallData,
    callParamsPassed: string
  ): ICallHistory[] => {
    if (dataPassed?.data !== undefined) {
      // let rowCount = callParamsPassed.includes('start_key') ? 11 : 0
      const modifiedArray: any[] = []
      for (const key in dataPassed?.data) {
        // rowCount++
        const value: ICallValue = dataPassed?.data[key] as ICallValue
        modifiedArray.push({
          id: value.call_id,
          answered: value.answered,
          callDirection: value.call_direction,
          status: getStatus(value),
          datetime: value.datetime,
          callDate: moment(value.datetime).format('DD MMM HH:mm'),
          number:
            value.call_direction === 'outbound'
              ? value.dialed_number.replace('+46', '0')
              : value.caller_id_number.replace('+46', '0'),
          callRef: value.reference,
          audio: {
            media: value.media_recordings[0],
            authorizingId: value.authorizing_id
          }
        })
      }

      if (!hasPagination && modifiedArray.length > 6) {
        return modifiedArray.slice(0, 5)
      }
      return modifiedArray
    }
    return []
  }

  const getParams = (paginationPassed?: IPaginationType): string => {
    let params = ''
    if (
      filter?.callReference !== null &&
      filter?.callReference !== 'allContact' &&
      filter?.callReference !== undefined
    ) {
      params = params + `&user_id=${filter?.callReference ?? ''}`
    }

    if (filter?.startDate !== null && filter?.startDate !== undefined) {
      params =
        params +
        `&created_from=${
          moment(filter?.startDate)
            .startOf('day')
            .unix() ?? ''
        }`
    }

    if (
      (filter?.endDate !== null && filter?.endDate !== undefined) ||
      (filter?.startDate !== null &&
        filter?.startDate !== undefined &&
        filter?.endDate === null)
    ) {
      params =
        params +
        `&created_to=${
          moment(filter?.endDate !== null ? filter?.endDate : filter?.startDate)
            .endOf('day')
            .unix() ?? ''
        }`
    }

    if (filter?.endDate === null && filter?.startDate === null) {
      params =
        params +
        `&created_from=${moment(thisWeek[0]).startOf('day').unix() ?? ''}` +
        `&created_to=${moment(thisWeek[1]).endOf('day').unix() ?? ''}`
    }

    if (paginationPassed !== undefined) {
      const nextStartKey = JSON.parse(
        localStorage.getItem('next_start_key') as string
      )

      params =
        params +
        (paginationPassed?.size !== undefined && paginationPassed?.size !== -1
          ? `&page_size=${hasPagination ? paginationPassed?.size : 5}`
          : '')
      if (
        paginationPassed.type === 'next' &&
        nextStartKey !== '' &&
        nextStartKey !== null
      ) {
        params = params + `&start_key=${nextStartKey[nextStartKey.length - 1]}`
      } else if (
        paginationPassed.type === 'previous' &&
        nextStartKey !== '' &&
        nextStartKey !== null
      ) {
        if (nextStartKey[nextStartKey.length - 3] !== undefined) {
          params =
            params + `&start_key=${nextStartKey[nextStartKey.length - 3]}`
        }

        nextStartKey.splice(-2)
        const uniqueArray = nextStartKey.filter(function (
          value: any,
          index: number,
          array: any
        ) {
          return array.indexOf(value) === index
        })
        localStorage.setItem('next_start_key', JSON.stringify(uniqueArray))
      } else if (paginationPassed.type === 'start') {
        localStorage.removeItem('next_start_key')
        return params
      }
    } else {
      const paginationStored = JSON.parse(
        localStorage.getItem('pagination') as string
      )

      if (
        paginationStored !== undefined &&
        paginationStored !== null &&
        paginationStored.pageSize !== undefined
      ) {
        params =
          params +
          (paginationStored?.pageSize !== -1
            ? `&page_size=` + (hasPagination ? paginationStored.pageSize : 5)
            : '')
      } else {
        params = params + `&page_size=` + (hasPagination ? 20 : 5)
      }

      localStorage.removeItem('next_start_key')
    }

    return params
  }

  const getCallHistory = (paginationPassed?: IPaginationType): void => {
    // setIsCallHistoryLoading(true)

    setCallerLoader(true)
    const callParams = getParams(paginationPassed)
    void handleApiCall({
      urlType: 'callHistory',
      auth: true,
      urlParams: callParams,
      cb: (res, state) => {
        if (res?.data !== undefined && state) {
          if (
            res.data.next_start_key !== undefined &&
            res.data.next_start_key !== '' &&
            hasPagination
          ) {
            setTimeout(() => {
              const xxx = localStorage.getItem('next_start_key')
              const keys = xxx !== null ? JSON.parse(xxx) : []
              keys.push(res.data.next_start_key)
              const uniqueKeys = keys.filter(function (
                value: any,
                index: number,
                array: any
              ) {
                return array.indexOf(value) === index
              })
              localStorage.setItem('next_start_key', JSON.stringify(uniqueKeys))
            }, 1000)
          }
          setCallHistory(getCallData(res.data, callParams))
        } else {
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          }
        }
        setTimeout(() => {
          setCallerLoader(false)
        }, 1000)
      }
    })
  }

  const hasFiltered = (dataPassed: any, filterData: IFilterData): boolean => {
    const filteredArray = []
    // if (filterData.startDate !== null && filterData.endDate !== null) {
    //   filteredArray.push(
    //     moment(dataPassed.datetime).isBetween(
    //       moment(filterData.startDate),
    //       moment(filterData.endDate)
    //     )
    //   )
    // }

    // if (
    //   filterData.callReference !== null &&
    //   filterData.callReference !== '' &&
    //   filterData.callReference !== 'allContact' &&
    //   filterData.callReference !== 'ce2a1da13dd655008a33f1d3d89533e5'
    // ) {
    //   filteredArray.push(dataPassed.number !== filterData.callReference)
    // }

    if (filterData.callStatus !== '') {
      if (filterData.callStatus === 'missed') {
        // missed
        filteredArray.push(dataPassed.status === 'missed')
      } else if (filterData.callStatus === '3') {
        // besvarade
        filteredArray.push(dataPassed.status === 'in')
      } else if (filterData.callStatus === '4') {
        // utgående
        filteredArray.push(dataPassed.status === 'out')
      }
    }

    const hasTrue = filteredArray.some((el) => el)
    return filteredArray.length === 0 ? true : hasTrue
  }

  useEffect(() => {
    localStorage.removeItem('next_start_key')
    getCallHistory()
  }, [])

  // useEffect(() => {
  //   if (!(pagination.size === 20 && pagination.type === '')) {
  //     getCallHistory(pagination)
  //   }
  // }, [pagination.size, pagination.type])

  useEffect(() => {
    if (filter?.callReference !== null && filter?.callReference !== undefined) {
      getCallHistory()
    }
  }, [filter?.callReference])
  useEffect(() => {
    if (
      filter?.startDate !== null &&
      filter?.startDate !== undefined &&
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      filter?.endDate !== null &&
      filter?.endDate !== undefined
    ) {
      getCallHistory()
    }
  }, [filter?.startDate, filter?.endDate])

  return callerLoader ? (
    <TellitLoader loading={true} />
  ) : (
    <TellitTable
      columns={columns}
      data={
        filter !== undefined
          ? callHistory.filter((ch) => hasFiltered(ch, filter))
          : callHistory
      }
      className={hasPagination ? 'call-table' : 'dash-table'}
      hasPagination={!!hasPagination}
      onPaginationChange={(alteredpagination) => {
        if (hasPagination) {
          // setPagination(alteredpagination)
          if (alteredpagination.type === 'sizeChanged') {
            localStorage.removeItem('next_start_key')
          }
          getCallHistory(alteredpagination)
        }
      }}
    />
  )
}

export default CdrTable
