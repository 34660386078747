import { TellitLoader, TellitCard } from '../common'

export type ICardTypes = 'default' | 'gradient'
export type ICardColors = 'primary' | 'green' | 'purple'

type IColorCardProps = {
  children: React.ReactNode
  loading?: boolean
  color?: ICardColors | string
  type?: ICardTypes
}

const ColorCard = ({
  children,
  loading = false,
  color = 'primary',
  type = 'default'
}: IColorCardProps): JSX.Element => {
  return (
    <TellitLoader
      loading={loading}
      customClass={
        type === 'default' ? '' : 'h-1/2 !w-full 2xl:min-w-[369px] display-card'
      }
    >
      <TellitCard
        customClass={
          type === 'default'
            ? `h-full md:min-w-[378px] !bg-primary bg-card-primary-fade flex flex-col items-center justify-start progress-card`
            : `h-1/2 w-full !pt-[50px] 2xl:pl-[36px] ${
                color !== 'purple' ? 'bg-card-gradient-2' : 'bg-card-gradient-1'
              } display-card`
        }
      >
        {children}
      </TellitCard>
    </TellitLoader>
  )
}

export default ColorCard
