import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { TooltipRefProps } from 'react-tooltip'

import { FaChevronRight, FaChevronDown } from 'react-icons/fa6'
import { FiMenu, FiX } from 'react-icons/fi'
import { HiOutlineLogout, HiOutlineUserCircle } from 'react-icons/hi'
// import { RxDividerVertical } from 'react-icons/rx'
// import { TbBell } from 'react-icons/tb'

import { RootState } from 'store'

import { TellitTooltip } from 'components/common'

import useOutsideClick from 'helpers/getOutsideClick'

import { setIsUpdateLogUserStore } from 'store/dashboard/action'

type INavbarProps = {
  setIsMenuOpen: (value: boolean) => void
  isMenuOpen: boolean
}

function Navbar({ setIsMenuOpen, isMenuOpen }: INavbarProps): JSX.Element {
  const location = useLocation()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { t } = useTranslation()

  const loggedUserData = useSelector(
    (state: RootState) => state.dashboard.userProfileObject
  )

  const isUpdateLogUserData = useSelector(
    (state: RootState) => state.dashboard.isUpdateLogUserData
  )

  const [menuOpen, setMenuOpen] = useState(false)
  const [userLogged, setUserLogged] = useState<null | any>(null)
  // const [notificationOpen, setNotificationOpen] = useState(false)

  const [userJson, setUserJson] = useState(
    localStorage.getItem('logged_in_user')
  )

  const menuRef = useRef<TooltipRefProps>(null)
  const notificationRef = useRef<TooltipRefProps>(null)
  const menuWrapperRef = useRef(null)

  const handleMenu = (): void => {
    setMenuOpen(!menuOpen)
    menuRef.current?.open({
      anchorSelect: '#menu'
    })
  }

  // const handleNotification = (): void => {
  //   setNotificationOpen(!notificationOpen)
  //   notificationRef.current?.open({
  //     anchorSelect: '#notification'
  //   })
  // }

  const getCurrentRoute =
    location.pathname === '/dashboard'
      ? `${t('Dashboard.Title')}`
      : location.pathname === '/users' || location.pathname === '/users/new'
        ? `${t('User.Title')}`
        : location.pathname === '/profile'
          ? `${t('Profile.Title')}`
          : location.pathname === '/ringschema'
            ? `${t('RingSchema.Title')}`
            : location.pathname === '/opening-hours'
              ? `${t('OpeningHours.Title')}`
              : location.pathname === '/call'
                ? `${t('Call.Right.Title')}`
                : location.pathname === '/contact'
                  ? `${t('Contact.Left.Title')}`
                  : ''

  const notifications = [
    {
      title: 'Viktigt meddelande!',
      date: '2023-12-12',
      description:
        'Just nu bjuder vi på 2 veckor Premium. Kontakt oss så hjälper vi dig!'
    },
    {
      title: 'Viktigt meddelande!',
      date: '2023-12-12',
      description:
        'Just nu bjuder vi på 2 veckor Premium. Kontakt oss så hjälper vi dig!'
    },
    {
      title: 'Viktigt meddelande!',
      date: '2023-12-12',
      description:
        'Just nu bjuder vi på 2 veckor Premium. Kontakt oss så hjälper vi dig!'
    }
  ]

  useEffect(() => {
    if ((isUpdateLogUserData as boolean) && isUpdateLogUserData !== undefined) {
      dispatch(setIsUpdateLogUserStore({ value: false }))
      setUserJson(localStorage.getItem('logged_in_user'))
    }
  }, [isUpdateLogUserData])

  useEffect(() => {
    if (userJson !== null && userJson !== '') {
      setUserLogged(JSON.parse(userJson))
    } else {
      if (loggedUserData !== null && typeof loggedUserData === 'object') {
        setUserLogged(loggedUserData)
      }
    }
  }, [userJson, loggedUserData])

  useOutsideClick({
    ref: menuWrapperRef,
    callback: () => {
      setMenuOpen(false)
    }
  })

  return (
    <nav className="h-[75px] w-full bg-inactive">
      <div className="flex w-full items-center justify-between bg-inactive pb-[20px] pl-[19px] pr-[19px] pt-[20px] lg:items-start lg:pl-[26px] lg:pr-[26px] 2xl:pl-8">
        <div
          className="mobile-nav mr-6 cursor-pointer text-sm font-bold text-primary/60 transition-all duration-300 ease-in-out 2xl:hidden"
          onClick={() => {
            setIsMenuOpen(!isMenuOpen)
          }}
        >
          {!isMenuOpen ? (
            <FiMenu className="text-2xl" />
          ) : (
            <FiX className="text-2xl" />
          )}
        </div>
        <div className="mr-auto text-sm font-bold text-primary/60">
          {getCurrentRoute}
        </div>
        <div
          onClick={() => {
            handleMenu()
          }}
          className="flex cursor-pointer items-center justify-end"
          id="menu"
        >
          {/* <div
            className="relative"
            onClick={() => {
              handleNotification()
            }}
            id="notification"
          >
            <TbBell className="text-[23px] font-bold transition-all delay-150 duration-150 hover:rotate-12" />
            {notifications?.length > 0 && (
              <div className="border-1 absolute left-[13px] top-[0px] h-[10px] w-[10px] rounded-full border-white bg-danger" />
            )}
          </div>
          <RxDividerVertical className="ml-[3px] text-ash" /> */}
          <div className="ml-[9.8px] max-w-[200px] truncate text-right text-xxs font-bold capitalize text-primary md:text-base">
            {`${
              userLogged?.givenName !== undefined ? userLogged?.givenName : ''
            } ${
              userLogged?.familyName !== undefined ? userLogged?.familyName : ''
            }`}
          </div>
          <img
            src={
              userLogged !== undefined && userLogged?.familyName !== undefined
                ? require('../assets/images/faces/empty-avatar.png')
                : require('../assets/images/faces/empty-avatar.png')
            }
            alt="user"
            className="ml-[13px] h-[30px] w-[30px] rounded-full object-cover"
          />
          <div className="ml-[6.88px]">
            <div>
              {menuOpen ? (
                <FaChevronDown className="mr-[-2px] cursor-pointer text-accent" />
              ) : (
                <FaChevronRight className="mr-[-2px] cursor-pointer text-accent transition-all duration-200 hover:translate-y-[-1px] hover:text-secondary" />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* menu */}
      <div ref={menuWrapperRef}>
        <TellitTooltip
          toolTipRef={menuRef}
          isOpen={menuOpen}
          type="menu"
          id="menu"
          customClass="shadow-menu nav-menu !px-[13px] !py-[10px]  min-w-[146px] max-h-[78px] min-h-[78px] z-[9999]"
        >
          <div
            className="flex h-[29px] items-center justify-between py-[9px] !pt-[15px] text-red-400"
            onClick={() => {
              navigate('/profile')
              handleMenu()
            }}
          >
            <span className="text-sm font-light text-white">
              {t('Profile.Title')}
            </span>

            <HiOutlineUserCircle className="text-lg text-accent" />
          </div>

          <div
            className="flex h-[29px] items-center justify-between border-b-[1px] border-fadeBerry/50 py-[9px] text-red-400"
            onClick={() => {
              localStorage.clear()
              window.location.reload()
              handleMenu()
            }}
          >
            <span className="pb-[5px] text-sm font-light text-white">
              {t('NavBar.Label.LoggaUt')}
            </span>
            <HiOutlineLogout className="text-lg text-danger" />
          </div>
        </TellitTooltip>
      </div>

      {/* notification */}
      <TellitTooltip
        toolTipRef={notificationRef}
        // isOpen={notificationOpen}
        type="menu"
        id="notification"
        customClass="notification-tooltip py-[20px] pb-[11.5px] px-[20px] min-w-[325px] max-w-[325px] !h-auto shadow-notification"
        offset={12}
      >
        <div className="flex w-full flex-col">
          {notifications.map((notification, index) => (
            <div
              className="flex h-[73px] w-full flex-col justify-center border-b-[1px] border-fadeBerry/50 first:justify-start last:border-none"
              key={`${index}_notification`}
            >
              <div className="flex items-center justify-between pb-[2px]">
                <span className="text-sm font-normal !text-secondary opacity-100">
                  {notification.title}
                </span>
                <span className="text-normal text-xxxs text-accent">
                  {notification.date}
                </span>
              </div>
              <div className="text-left text-xs font-normal text-cream">
                {notification.description}
              </div>
            </div>
          ))}
        </div>
      </TellitTooltip>
    </nav>
  )
}

export default Navbar
