import { FaArrowRightLong } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

import { getUserType } from 'helpers'

import { TellitCard, TellitAvatar } from 'components/common'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

type IProfileOverviewCardProps = {
  isContact?: boolean
  // btnName?: string
  // onClick?: () => void
  role: string
  className?: string
  // btnClass?: string
  formData?: any
}

const ProfileOverviewCard = ({
  isContact = false,
  // btnName,
  // onClick,
  role,
  className,
  // btnClass,
  formData
}: IProfileOverviewCardProps): JSX.Element => {
  const userFormData = useSelector(
    (state: RootState) => state.dashboard.userProfileObject
  )
  const navigate = useNavigate()
  const { t } = useTranslation()

  const userName = (formData as boolean)
    ? ` ${(formData.givenName as boolean) ? formData.givenName : ''} ${
        (formData.familyName as boolean) ? formData.familyName : ''
      }`
    : (userFormData as unknown as boolean)
      ? `${
          (userFormData?.givenName as unknown as boolean)
            ? userFormData?.givenName
            : ''
        } ${
          (userFormData?.familyName as unknown as boolean)
            ? userFormData?.familyName
            : ''
        }`
      : ''

  const userRole =
    isContact && formData.userType !== 'super-admin'
      ? (formData as boolean) && formData.userType !== undefined
        ? getUserType(formData.userType)
        : (userFormData as unknown as boolean) &&
            userFormData?.userType !== undefined
          ? getUserType(userFormData.userType)
          : role
      : role

  return (
    <TellitCard
      customClass={`mr-[28px] mt-[50px] flex items-center !p-[24px] ${className}`}
    >
      <div className="flex min-h-fit w-full flex-col flex-wrap justify-center gap-3 sm:flex-row sm:content-center sm:justify-between">
        <div className="grid content-center justify-center justify-items-center gap-[12px] text-center sm:flex sm:justify-start sm:justify-items-start sm:text-left xl:gap-[8px] 2xl:items-center 2xl:gap-[18px]">
          <TellitAvatar
            // src={require('../../assets/images/faces/face10.jpg')}
            className="min-w-[50px] justify-center"
            name={userName}
          />

          <div>
            <div className="text-md font-normal tracking-normal text-primary">
              {userRole}
            </div>

            <div className=" mt-[-3px] text-lg font-bold tracking-normal text-primary xl:text-xl  2xl:text-2xl-2 ">
              {userName}
            </div>
          </div>
        </div>

        <div
          className={`text-semibold flex cursor-pointer flex-nowrap items-center justify-center gap-[7px] p-2 text-md text-primary/60 sm:justify-end sm:p-0 ${'!text-sm hover:text-secondary'} ${
            isContact
              ? 'font-normal'
              : 'font-bold !text-primary hover:!text-secondary'
          }`}
          onClick={() => {
            navigate('/users')
          }}
        >
          {isContact ? t('Contact.Profile.Sender') : t('Profile.Label.SeUsers')}
          {!isContact && <FaArrowRightLong className="h-4" />}
        </div>
      </div>
    </TellitCard>
  )
}

export default ProfileOverviewCard
