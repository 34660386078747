import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TellitCard } from 'components/common'
import { columnHelper, getCallIcon } from 'components/common/TellitTable'
import CdrTable from 'modules/shared/CdrTable'

const CallHistoryTable = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const columns = [
    columnHelper.accessor('number', {
      id: 'number',
      cell: (info) => (
        <div className="flex items-center justify-start gap-3 2xl:max-w-[300px] 2xl:gap-[12.5px]">
          {getCallIcon(info.row.original.status)} {info.getValue()}
        </div>
      ),
      header: () => t('Table.Header.Number')
    }),
    columnHelper.accessor('callDate', {
      id: 'callDate',
      cell: (info) => <div>{info.getValue()}</div>,
      header: () => t('Table.Header.CallDate')
    }),
    columnHelper.accessor('callRef', {
      id: 'callRef',
      cell: (info) => <div>{info.renderValue()}</div>,
      header: () => t('Table.Header.CallRef')
    })
  ]

  return (
    <TellitCard
      customClass="2xl:max-h-[755px] 2xl:max-w-[772px] overflow-y-auto hide-scrollbar dash-table-card"
      header={t('Dashboard.Right.Table.Header')}
      actionBtnName={t('Dashboard.Right.Table.ActionBtn')}
      icon
      actionOnClick={() => {
        navigate('/call', { replace: true })
      }}
      type="medium"
    >
      <CdrTable columns={columns} hasPagination={false} />
    </TellitCard>
  )
}

export default CallHistoryTable
