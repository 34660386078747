import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import handleApiCall from 'api/handleApiCall'

import {
  type ProfileFormTypes,
  type HandleValueChangeTypes,
  type ISuperAdminDetails,
  type IUserProfileObject,
  type IProfileData
} from 'store/dashboard/types'

import { ActionButton, TellitButton, Divider } from 'components/common'
import { TellitInput } from 'components/form'
// import { handleToastMessage } from 'utilities'

import {
  useValidateForm,
  checkFormValid,
  type IErrorObj
} from '../../helpers/validationFunctions'

type ProfileFormProps = {
  isContact?: boolean
  setProfileData: (pre: IProfileData) => void
}

// Remove hyphen from string
export const removeHyphen = (str: string | undefined): string => {
  if (str === undefined) {
    return ''
  }
  return str?.replace(/-/g, '')
}

function ProfileForm({
  isContact = false,
  setProfileData
}: ProfileFormProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [form, setForm] = useState<ProfileFormTypes>({
    loginUserName: '',
    organizationNumber: '',
    address: '',
    city: '',
    phoneNumber: '',
    did: '',
    emailAddress: '',
    userType: null
  })

  const [isError, setIsError] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [disabledUntilSubmit, setDisabledUntilSubmit] = useState<boolean>(false)
  const [errorArray, setErrorArray] = useState<IErrorObj[]>([])

  //  DataArray Add type - we remove this code after code review ❌
  //  const getSuperAdminDetail = (dataArray: []): void => {
  //   const contactData = JSON.parse(localStorage.getItem('user_data') as string)

  //   const loginUserData = JSON.parse(
  //     localStorage.getItem('logged_in_user') as string
  //   )

  //   // Refactor item any
  //   // Move data array map to upwards
  //   // unwanted check for contact with contact filter
  //   // User admin need to be super admin
  //   const filteredPersonNumData = contactData.filter((item: any) => {
  //     const itemPersonNumCheck = item.personalNumber.replace(/-/g, '')

  //     const dataArrayPersonNumCheck =
  //       dataArray.length > 0
  //         ? dataArray.map((number: string) => number.replace(/-/g, ''))
  //         : ''

  //     return dataArrayPersonNumCheck.includes(itemPersonNumCheck)
  //   })

  //   const dataSuperAdminObj: any = isContact
  //     ? loginUserData
  //     : (filteredPersonNumData?.[0] as boolean)
  //       ? filteredPersonNumData[0]
  //       : null

  //   if (typeof setSuperAdminData === 'function' && dataSuperAdminObj !== null) {
  //     setSuperAdminData({
  //       familyName: dataSuperAdminObj.familyName,
  //       givenName: dataSuperAdminObj.givenName,
  //       userType: dataSuperAdminObj.userType
  //     })
  //   }
  // }

  const getSuperAdminDetail = (personNumbersArray: string[]): void => {
    const contactsData = JSON.parse(localStorage.getItem('user_data') as string)
    const loginUserData = JSON.parse(
      localStorage.getItem('logged_in_user') as string
    )

    // Filter contactsData based on personalNumber
    const filteredPersonNumData = contactsData.filter(
      (contact: IUserProfileObject) => {
        const formattedPersonNumber = removeHyphen(contact?.personalNumber)
        const formattedPersonNumbersInArray = personNumbersArray?.map(
          (number: string) => removeHyphen(number)
        )
        return (
          formattedPersonNumber?.length > 0 &&
          formattedPersonNumbersInArray.includes(formattedPersonNumber)
        )
      }
    )

    // Determine super admin data
    const dataSuperAdminObj =
      filteredPersonNumData.length > 0
        ? filteredPersonNumData[0]
        : loginUserData

    // Set super admin data if the function and data are available
    if (dataSuperAdminObj as boolean) {
      const { familyName, givenName, userType, personalNumber } =
        dataSuperAdminObj

      const superAdminData: ISuperAdminDetails = {
        familyName,
        givenName,
        userType,
        personalNumber
      }

      if (isContact) {
        setProfileData((pre: IProfileData) => ({
          ...pre,
          superAdminData
        }))
      } else {
        setProfileData(superAdminData)
      }
    }
  }

  // we remove this code after code review ❌
  // const handleValueChange = ({ e }: HandleValueChangeTypes): void => {
  //   setIsSubmitted(false)

  //   setForm((prevState) => {
  //     const updatedForm = { ...prevState, [e.target.name]: e.target.value }

  //     if (typeof moduleCallBack === 'function') {
  //       moduleCallBack(updatedForm)
  //     }

  //     return updatedForm
  //   })
  // }

  // const setContactData = (updatedForm: ProfileFormTypes): void => {
  //   if (isContact) {
  //     setProfileData((prevState: IContactFormType) => ({
  //       ...prevState,
  //       contactData: updatedForm
  //     }))
  //   }
  // }

  const setContactData = (updatedForm: ProfileFormTypes): void => {
    if (isContact) {
      setProfileData((pre: IProfileData) => ({
        ...pre,
        contactData: updatedForm
      }))
    }
  }

  const handleValueChange = ({ e }: HandleValueChangeTypes): void => {
    setIsSubmitted(false)
    const formCopy = { ...form }
    const updatedForm = { ...formCopy, [e.target.name]: e.target.value }
    setForm(updatedForm)
    setContactData(updatedForm)
    // if (moduleCallBack !== undefined) {
    //   moduleCallBack(updatedForm)
    // }
  }

  function isObjectEmpty(obj: any): boolean {
    for (const key in obj) {
      if (obj[key] === null) {
        return true
      } else if (obj[key] === undefined) {
        return true
      } else if (key === 'city' || key === 'address') {
        return false
      } else if (obj[key] === '') {
        return true
      }
    }
    return false
  }

  const handleCreateAndUpdateUser = async (): Promise<void> => {
    const isEmpty = isObjectEmpty(form)

    const isFormValid = checkFormValid(errorArray, '')

    setDisabledUntilSubmit(true)

    if (isError || isEmpty || isFormValid) {
      setIsSubmitted(false)
      setDisabledUntilSubmit(false)
    } else {
      const postData = {
        name: form.loginUserName,
        address: form.address,
        email: form.emailAddress,
        ort: form.city
      }

      void handleApiCall({
        urlType: 'updateAccount',
        auth: true,
        urlParams: `&data=${encodeURIComponent(JSON.stringify(postData))}`,
        cb: (res, state) => {
          setDisabledUntilSubmit(false)

          if (state) {
            setIsSubmitted(true)
            console.log('success')
          } else {
            if (res?.messge !== '') {
              // handleToastMessage(res.messge)
            }
          }
        }
      })
    }
  }

  const addButtonProps = {
    customClass:
      'h-[58px] bg-primary hover:bg-primary rounded-full shadow-buttonSecondary text-secondary hover:text-secondary ',
    labelClass: 'font-bold !text-md tellit-update-user',
    label: t('Profile.Form.UppgraderaKonto'),
    onClick: () => {
      localStorage.setItem('page_url', window.location.href)
      navigate('/contact', { state: { updateProVersion: true } })
    }
  }

  const forms = [
    {
      label: t('Profile.Form.Label.Företagsnamn'),
      value: form?.loginUserName,
      name: 'loginUserName'
    },
    {
      label: t('Profile.Form.Label.Organisationsnummer'),
      value: form?.organizationNumber,
      name: 'organizationNumber'
    },
    {
      label: t('Profile.Form.Label.Adress'),
      value: form?.address,
      name: 'address'
    },
    {
      label: t('Profile.Form.Label.Ort'),
      value: form?.city,
      name: 'city'
    },
    {
      label: t('Profile.Form.Label.EPost'),
      value: form?.emailAddress,
      name: 'emailAddress'
    },
    {
      label: t('Profile.Form.Label.Telefonnummer'),
      value: form?.phoneNumber,
      name: 'phoneNumber'
    }
  ]

  const handleValidateForm = (val: boolean, name: string): void => {
    const isFormError = useValidateForm({
      fieldError: val,
      name,
      errorArray,
      setErrorArray,
      id: ''
    })

    setIsError(isFormError)
  }

  useEffect(() => {
    setDisabledUntilSubmit(true)

    void handleApiCall({
      urlType: 'getAccount',
      auth: true,
      cb: (res, state) => {
        setDisabledUntilSubmit(false)

        if ((res?.data?.data as boolean) && state) {
          const formData = res?.data?.data

          const loggedUserData = localStorage.getItem('logged_in_user')
          const loggedUserObj =
            typeof loggedUserData === 'string'
              ? JSON.parse(loggedUserData)
              : null

          // we remove this code after code review ❌
          // Add empty check
          // const logUser = JSON.parse(
          //   localStorage.getItem('logged_in_user') as string
          // )?.userType

          // When binding formadata fromres add filed undefied/ null check for each fields
          const updatedForm = {
            ...form,
            loginUserName: formData?.name,
            organizationNumber: formData?.contact?.billing?.region,
            address: formData?.contact?.billing?.street_address,
            city:
              formData?.contact?.billing?.postal_code +
              ((formData?.contact?.billing?.postal_code as boolean)
                ? ' '
                : '') +
              formData?.contact?.billing?.locality,
            phoneNumber: formData?.caller_id?.external?.number,
            emailAddress: formData?.contact?.billing?.email,
            userType: (loggedUserObj as boolean) ? loggedUserObj : ''
          }

          setForm(updatedForm)
          setContactData(updatedForm)
          // if (moduleCallBack !== undefined) {
          //   moduleCallBack(updatedForm)
          // }

          getSuperAdminDetail(
            formData?.unity_admins.length > 0 ? formData?.unity_admins : []
          )
        } else {
          if (res?.messge !== '') {
            // handleToastMessage(res.messge)
          }
        }
      }
    })
  }, [])

  return (
    <div className="md:grow">
      {!isContact && (
        <div className="!-mt-[16px] pb-[15px] text-sm font-normal text-primary/60">
          {t('Profile.Form.Description')}
        </div>
      )}

      <div>
        <div className="col-cols-1 grid gap-[8px] md:grid-cols-2 md:!gap-x-[12px]">
          {forms.map((formItem, formIndex) => (
            <TellitInput
              key={`formItem_${formIndex}`}
              label={formItem.label}
              value={formItem.value}
              name={formItem.name}
              onChange={(e) => {
                handleValueChange({ e })
              }}
              enableErrorMsg
              setError={(val) => {
                handleValidateForm(val, formItem.name)
              }}
              required
              type={
                formItem.name === 'emailAddress'
                  ? 'email'
                  : formItem.name === 'city'
                    ? 'zipCode'
                    : formItem.name === 'phoneNumber'
                      ? 'mobile'
                      : ''
              }
              validationType={
                formItem.name === 'organizationNumber' ? 'personalNumber' : ''
              }
              disabled={
                isContact ||
                formItem.name === 'phoneNumber' ||
                formItem.name === 'organizationNumber'
              }
            />
          ))}

          {!isContact && (
            <>
              <div className="my-3 grid justify-items-center self-center pt-[16px]  text-sm font-normal text-primary/60 md:my-0 md:justify-items-start">
                {t('Profile.Form.MedPremium')}
              </div>

              <div className="my-3  grid justify-items-center pt-[16px] md:my-0 md:justify-items-end">
                <div>
                  <ActionButton
                    label={
                      isSubmitted
                        ? t('Generic.Button.Sparat')
                        : t('Generic.Button.Submit')
                    }
                    disabled={(!isError && disabledUntilSubmit) || isError}
                    isSubmitted={isSubmitted}
                    onClick={async () => {
                      await handleCreateAndUpdateUser()
                    }}
                  />
                </div>
              </div>

              <div className="grid md:col-span-2">
                <Divider />
              </div>

              <div className="mb-2 mr-0   justify-items-center  md:mb-[16px] md:mr-[16px] md:justify-items-start ">
                <TellitButton {...addButtonProps} />
              </div>

              <div className="my-2   grid justify-items-center self-center  text-sm font-normal text-primary md:my-0 md:justify-items-start">
                {t('Profile.Form.UppgraderaFör')}

                <br className="hidden 2xl:block" />

                {t('Profile.Form.HeltEfter')}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProfileForm
