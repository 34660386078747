import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from './../assets/images/logo.png'
import logoMini from './../assets/images/logo-mini.png'
import { IconCustom, TellitButton } from 'components/common'

type ISideBarProps = {
  isMenuOpen: boolean
  mobile: boolean
  isMobileMenuOpen?: boolean
  setIsMobileMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

function Sidebar({
  isMenuOpen,
  mobile,
  setIsMobileMenuOpen
}: ISideBarProps): JSX.Element {
  const location = useLocation()
  const navigate = useNavigate()
  const isPathActive = (path: string): boolean => {
    if (path === location.pathname) {
      return true
    }
    return false
  }

  const menuItems = [
    {
      name: 'Dashboard',
      path: '/dashboard',
      icon: (className: string) => (
        <IconCustom
          type="bs"
          size="small"
          name="speedo-meter"
          customClass={className}
        />
      )
    },
    {
      name: 'Användare',
      path: '/users',
      icon: (className: string) => (
        <IconCustom
          type="fi"
          size="small"
          name="users"
          customClass={className}
        />
      )
    },
    {
      name: 'Ringschema',
      path: '/ringschema',
      icon: (className: string) => (
        <IconCustom
          type="fi"
          size="small"
          name="phone-call"
          customClass={className}
        />
      )
    },
    {
      name: 'Öppettider',
      path: '/opening-hours',
      icon: (className: string) => (
        <IconCustom
          type="fa"
          size="small"
          name="clock"
          customClass={className}
        />
      )
    },
    {
      name: 'Samtal',
      path: '/call',
      icon: (className: string) => (
        <IconCustom
          type="bi"
          size="small"
          name="phone-incoming"
          customClass={className}
        />
      )
    }
  ]

  return (
    <div className={`${!mobile && 'sidebar'} flex-col" flex`}>
      <div
        className={`${
          isMenuOpen ? 'w-[250px] max-w-[250px]' : 'w-[74px] max-w-[74px]'
        } ${
          !mobile ? 'h-screen transition-all duration-300 ease-out' : 'h-screen'
        } bg-primary shadow-sideMenu`}
      >
        <div
          className={`${
            isMenuOpen ? 'ml-[42px] mt-[32px]' : 'ml-[11px] mt-[27px]'
          }`}
        >
          {/* logo */}
          <div className="mb-[76px]">
            <img
              src={isMenuOpen ? logo : logoMini}
              alt="logo"
              className={
                isMenuOpen ? 'h-[34px] w-[95px]' : 'ml-[8px] h-[37px] w-[36px]'
              }
            />
          </div>
          {/* header */}
          <span
            className={`text-left font-normal text-white/70 ${
              isMenuOpen ? 'text-sm' : 'text-xxs'
            }`}
          >
            Tellit Free
          </span>
        </div>
        <div
          className={`mt-[13px] ${
            isMenuOpen && 'ml-[24px]'
          } flex flex-col gap-[5px]`}
        >
          {menuItems.map((menuItem, idx) => (
            <Link
              to={menuItem.path}
              key={`menu_item${idx}`}
              className={`${
                isMenuOpen ? 'w-[174px]' : 'justify-center'
              } z-[99] flex h-[47px] cursor-pointer items-center gap-[23px] rounded-medium px-[17.75px] py-[15px]
              ${
                isPathActive(menuItem.path)
                  ? `${isMenuOpen && 'bg-accent'}`
                  : `bg-transparent ${
                      isMenuOpen ? 'hover:!bg-accent/30' : 'hover:bg-primary'
                    } ${!mobile && 'transition-all duration-300 ease-out'}`
              }
              
              `}
              onClick={() => {
                if (mobile) {
                  setIsMobileMenuOpen != null && setIsMobileMenuOpen(false)
                }
              }}
            >
              {menuItem.icon(
                `font-bold ${
                  isPathActive(menuItem.path) && isMenuOpen
                    ? 'text-primary'
                    : 'text-white'
                }`
              )}
              {isMenuOpen && (
                <span
                  className={`mt-[4px] text-sm-1 font-bold ${
                    isPathActive(menuItem.path) ? 'text-primary' : 'text-white'
                  }`}
                >
                  {menuItem.name}
                </span>
              )}
            </Link>
          ))}
        </div>

        {/* gradients */}
        <div className="bg-eclipseTwo absolute left-[-294px] top-[219px] h-[456px] w-[456px]" />
        <div className="bg-eclipseOne absolute bottom-[0px] left-[-237px] h-[431px] w-[456px]" />

        {/* bottom button */}
        <div
          className={`absolute ${
            isMenuOpen ? 'bottom-[30px]' : 'bottom-[42px]'
          }`}
        >
          {isMenuOpen ? (
            <p className="mx-[39px] mb-[18px] text-left text-sm font-normal text-white/80">
              Har du frågor eller <br /> funderingar? Kontakta oss!
            </p>
          ) : (
            <p className="mx-[14px] mb-[11px] text-center text-sm font-normal text-white/80">
              Har du <br /> frågor?
            </p>
          )}
          {isMenuOpen ? (
            <TellitButton
              label="KONTAKT"
              labelClass="!text-md font-bold tracking-[0.96px]"
              customClass="!bg-secondary !rounded-extraLarge !min-h-[50px] w-[203px] max-w-[203px] ml-[23.5px] z-[4] shadow-sideNavBtn"
              onClick={() => {
                localStorage.setItem('page_url', window.location.href)
                if (mobile) {
                  setIsMobileMenuOpen != null && setIsMobileMenuOpen(false)
                }
                navigate('/contact')
              }}
            />
          ) : (
            <div
              className="z-[4] ml-[16px] flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-full !bg-secondary shadow-button"
              onClick={() => {
                localStorage.setItem('page_url', window.location.href)
                navigate('/contact')
              }}
            >
              <IconCustom
                type="fa"
                size="small"
                name="envelope"
                customClass="text-2xl-2 text-primary"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
