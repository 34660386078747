import React, { Ref } from 'react'
import { Tooltip, TooltipRefProps, PlacesType } from 'react-tooltip'

type TellitTooltipProps = {
  children?: React.ReactNode | null | undefined
  customClass?: string
  toolTipRef?: Ref<TooltipRefProps> | undefined
  type?: string
  isOpen?: boolean
  place?: PlacesType
  offset?: number
  id?: string
  openOnClick?: boolean
  imperativeModeOnly?: boolean
}
//* ** note - id is required for tooltip hover
const TellitTooltip = ({
  children,
  id,
  customClass,
  toolTipRef,
  type,
  isOpen,
  place = 'top-end',
  offset = 5,
  openOnClick = true,
  imperativeModeOnly = false
}: TellitTooltipProps): JSX.Element => {
  return (
    <Tooltip
      id={id}
      ref={toolTipRef}
      isOpen={isOpen}
      openOnClick={openOnClick}
      clickable
      imperativeModeOnly={imperativeModeOnly}
      className={`${
        type === 'menu'
          ? ` menu-dropdown !cursor-pointer ${customClass}`
          : `${customClass}`
      } rounded-small !bg-primary`}
      place={place}
      offset={offset}
    >
      {children}
    </Tooltip>
  )
}

export default TellitTooltip
